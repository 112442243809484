import React from 'react';

function Reports() {
  return (
    <div>
      <h2>Reports</h2>
      {/* Reports content */}
    </div>
  );
}

export default Reports;
