import React, { useState, useEffect } from "react";
import {
 Box,
 Typography,
 TextField,
 Grid,
 IconButton,
 Tabs,
 Tab,
 Switch,
 Modal,
 Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import PreviewIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DistributorFormAdmin from "./DistributorFormAdmin";

const ManageDistributors = () => {
 const [distributors, setDistributors] = useState([]);
 const [drivers, setDrivers] = useState([]);
 const [openDistributorModal, setOpenDistributorModal] = useState(false);
 const [openDriverModal, setOpenDriverModal] = useState(false);
 const [selectedDistributor, setSelectedDistributor] = useState(null);

 // Separate filters for distributors and drivers
 const [distributorFilters, setDistributorFilters] = useState({
  searchTerm: "",
  date: "",
 });

 const [driverFilters, setDriverFilters] = useState({
  searchTerm: "",
  date: "",
 });

 useEffect(() => {
  fetchDistributors();
 }, []);

 // Fetch distributors with driver counts
 const fetchDistributors = async () => {
  try {
   const res = await axios.get("https://api.janatarides.com/api/admin/distributors", {
    headers: {
     "x-auth-token": localStorage.getItem("token"),
    },
   });

   const distributorsWithDrivers = await Promise.all(
    res.data.map(async (distributor) => {
     const driverRes = await axios.get(
      `https://api.janatarides.com/api/drivers/distributer?distributorId=${distributor._id}`,
      {
       headers: {
        "x-auth-token": localStorage.getItem("token"),
       },
      }
     );
     return {
      ...distributor,
      drivers: driverRes.data.drivers || [],
      driverCount: driverRes.data.driverCount || 0,
     };
    })
   );

   setDistributors(distributorsWithDrivers);
  } catch (err) {
   console.error("Error fetching distributors:", err);
  }
 };

 // Handle opening the distributor modal
 const handleOpenDistributorModal = () => {
  setOpenDistributorModal(true);
 };

 // Handle closing the distributor modal
 const handleCloseDistributorModal = () => {
  setOpenDistributorModal(false);
 };

 // Handle opening the driver modal
 const handleOpenDriverModal = (distributor) => {
  setSelectedDistributor(distributor);
  setDrivers(distributor.drivers);
  setOpenDriverModal(true);
 };

 // Handle closing the driver modal
 const handleCloseDriverModal = () => {
  setOpenDriverModal(false);
  setSelectedDistributor(null);
  setDrivers([]);
 };

 // Handle distributor added to refresh the list
 const handleDistributorAdded = () => {
  fetchDistributors();
 };

 // Handle switch change for driver approval
 const handleDriverSwitchChange = async (id, newValue) => {
  try {
   await axios.put(
    `https://api.janatarides.com/api/admin/approve-driver/${id}`,
    { approved: newValue },
    {
     headers: {
      "x-auth-token": localStorage.getItem("token"),
     },
    }
   );

   setDrivers((prevDrivers) =>
    prevDrivers.map((driver) =>
     driver._id === id ? { ...driver, approved: newValue } : driver
    )
   );
  } catch (error) {
   console.error("Error updating driver approval status:", error);
  }
 };

 // Filter distributors based on the filters
 const filteredDistributors = distributors.filter((distributor) => {
  const matchesSearchTerm =
   distributor.name
    .toLowerCase()
    .includes(distributorFilters.searchTerm.toLowerCase()) ||
   (distributor.mobile &&
    distributor.mobile.includes(distributorFilters.searchTerm));
  const matchesDate = distributorFilters.date
   ? new Date(distributor.createdAt).toLocaleDateString() ===
     new Date(distributorFilters.date).toLocaleDateString()
   : true;

  return matchesSearchTerm && matchesDate;
 });

 // Filter drivers based on the filters
 const filteredDrivers = drivers.filter((driver) => {
  const searchTerm = driverFilters.searchTerm.toLowerCase();

  const matchesDriverName =
   driver.driverName?.toLowerCase().includes(searchTerm) || false;
  const matchesMobile =
   driver.mobile?.toLowerCase().includes(searchTerm) || false;
  const matchesRegistrationNumber =
   driver.registrationNumber?.toLowerCase().includes(searchTerm) || false;

  const matchesDate = driverFilters.date
   ? new Date(driver.createdAt).toLocaleDateString() ===
     new Date(driverFilters.date).toLocaleDateString()
   : true;

  return (
   (matchesDriverName || matchesMobile || matchesRegistrationNumber) &&
   matchesDate
  );
 });
 // Columns for the distributor DataGrid
 const distributorColumns = [
  { field: "_id", headerName: "ID", width: 150 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "mobile", headerName: "Mobile", width: 150 },
  { field: "address", headerName: "Address", width: 200 },
  { field: "account_holder_name", headerName: "A/c Holder Name", width: 150 },
  { field: "bank_name", headerName: "Bank Name", width: 150 },
  { field: "account_no", headerName: "A/c No.", width: 150 },
  { field: "ifsc_code", headerName: "IFSC", width: 150 },
  {
   field: "chequeUrl",
   headerName: "Cheque",
   width: 200,
   renderCell: (params) => (
    <img
     src={params.value}
     alt="Cheque"
     style={{ width: "50px", height: "50px", cursor: "pointer" }}
     onClick={() => window.open(params.value, "_blank")}
    />
   ),
  },
  {
   field: "passbookUrl",
   headerName: "PassBook",
   width: 200,
   renderCell: (params) => (
    <img
     src={params.value}
     alt="PassBook"
     style={{ width: "50px", height: "50px", cursor: "pointer" }}
     onClick={() => window.open(params.value, "_blank")}
    />
   ),
  },
  {
   field: "aadhar_frontendUrl",
   headerName: "Aadhar Frontend",
   width: 200,
   renderCell: (params) => (
    <img
     src={params.value}
     alt="Aadhar Frontend"
     style={{ width: "50px", height: "50px", cursor: "pointer" }}
     onClick={() => window.open(params.value, "_blank")}
    />
   ),
  },
  {
   field: "aadhar_backendUrl",
   headerName: "Aadhar Backend",
   width: 200,
   renderCell: (params) => (
    <img
     src={params.value}
     alt="Aadhar Backend"
     style={{ width: "50px", height: "50px", cursor: "pointer" }}
     onClick={() => window.open(params.value, "_blank")}
    />
   ),
  },
  {
   field: "profile",
   headerName: "profile",
   width: 200,
   renderCell: (params) => (
    <img
     src={params.value}
     alt="profile"
     style={{ width: "50px", height: "50px", cursor: "pointer" }}
     onClick={() => window.open(params.value, "_blank")}
    />
   ),
  },
  {
   field: "driverCount",
   headerName: "Number of Cars",
   width: 150,
   valueGetter: (params) => params.row.driverCount || 0,
  },
  {
   field: "createdAt",
   headerName: "Created Date",
   width: 180,
   valueGetter: (params) => new Date(params.row.createdAt).toLocaleDateString(),
  },
  {
   field: "actions",
   headerName: "Actions",
   width: 100,
   renderCell: (params) => (
    <>
     <IconButton
      onClick={() => handleOpenDriverModal(params.row)}
      color="primary"
     >
      <PreviewIcon />
     </IconButton>
    </>
   ),
  },
 ];

 // Columns for the driver DataGrid inside the modal
 const driverColumns = [
  { field: "_id", headerName: "ID", width: 150 },
  { field: "driverName", headerName: "Driver Name", width: 150 },
  { field: "mobile", headerName: "Mobile", width: 150 },
  { field: "accountNumber", headerName: "Account Number", width: 150 },
  { field: "carName", headerName: "Car Name", width: 150 },
  {
   field: "registrationNumber",
   headerName: "Registration Number",
   width: 150,
  },
  {
   field: "approved",
   headerName: "Approval",
   width: 150,
   renderCell: (params) => (
    <Switch
     checked={params.value}
     onChange={(event) =>
      handleDriverSwitchChange(params.row._id, event.target.checked)
     }
    />
   ),
  },
  {
   field: "isOnline",
   headerName: "Available",
   width: 150,
   renderCell: (params) => (
    <Box
     sx={{
      color: params.value ? "green" : "red",
      fontWeight: "bold",
     }}
    >
     {params.value ? "Online" : "Offline"}
    </Box>
   ),
  },
 ];

 // Function to download distributor data as Excel
 const handleDownloadDistributors = () => {
  const exportData = distributors.map((distributor) => ({
   ID: distributor._id,
   Name: distributor.name,
   Email: distributor.email,
   Mobile: distributor.mobile,
   Address: distributor.address,
   "Number of Cars": distributor.driverCount,
   "Created At": new Date(distributor.createdAt).toLocaleString(),
  }));

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Distributors");

  const excelBuffer = XLSX.write(workbook, {
   bookType: "xlsx",
   type: "array",
  });

  const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(data, "Distributors.xlsx");
 };

 // Function to download driver data for a specific distributor as Excel
 const handleDownloadDrivers = () => {
  const exportData = drivers.map((driver) => ({
   ID: driver._id,
   "Driver Name": driver.driverName,
   Mobile: driver.mobile,
   "Account Number": driver.accountNumber,
   "Car Name": driver.carName,
   "Registration Number": driver.registrationNumber,
   Approved: driver.approved ? "Yes" : "No",
   "Created At": new Date(driver.createdAt).toLocaleString(),
   "Updated At": new Date(driver.updatedAt).toLocaleString(),
  }));

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
   workbook,
   worksheet,
   `Drivers_${selectedDistributor?.name}`
  );

  const excelBuffer = XLSX.write(workbook, {
   bookType: "xlsx",
   type: "array",
  });

  const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(data, `Drivers_${selectedDistributor?.name}.xlsx`);
 };

 return (
  <Box sx={{ p: 3 }}>
   <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
    <IconButton onClick={handleOpenDistributorModal} color="primary">
     <AddIcon />
     <Typography>Add Distributor</Typography>
    </IconButton>
    <Button
     variant="contained"
     color="primary"
     startIcon={<DownloadIcon />}
     onClick={handleDownloadDistributors}
    >
     Download Distributors
    </Button>
   </Box>
   <Tabs value={0} onChange={() => {}} aria-label="manage distributors">
    <Tab label="Manage Distributors" />
   </Tabs>

   {/* Distributor Filters */}
   <Grid container spacing={2} sx={{ mb: 2 }}>
    <Grid item xs={12} sm={8}>
     <TextField
      label="Search by Name or Mobile"
      variant="outlined"
      fullWidth
      value={distributorFilters.searchTerm}
      onChange={(e) =>
       setDistributorFilters({
        ...distributorFilters,
        searchTerm: e.target.value,
       })
      }
     />
    </Grid>
    <Grid item xs={12} sm={4}>
     <TextField
      label="Date"
      type="date"
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
      value={distributorFilters.date}
      onChange={(e) =>
       setDistributorFilters({
        ...distributorFilters,
        date: e.target.value,
       })
      }
     />
    </Grid>
   </Grid>

   <Box sx={{ mb: 5 }}>
    <div style={{ height: 400, width: "100%" }}>
     <DataGrid
      rows={filteredDistributors}
      columns={distributorColumns}
      getRowId={(row) => row._id}
      pageSize={10}
     />
    </div>
   </Box>

   {/* Distributor Modal */}
   <DistributorFormAdmin
    open={openDistributorModal}
    onClose={handleCloseDistributorModal}
    onDistributorAdded={handleDistributorAdded}
   />

   {/* Driver Modal */}
   <Modal open={openDriverModal} onClose={handleCloseDriverModal}>
    <Box
     sx={{
      p: 3,
      maxWidth: "90%",
      margin: "auto",
      mt: "10vh",
      bgcolor: "background.paper",
     }}
    >
     <Typography variant="h6" gutterBottom>
      Cars Managed by {selectedDistributor?.name}
     </Typography>

     {/* Driver Filters */}
     <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} sm={8}>
       <TextField
        label="Search by Name, Mobile or Registration Number"
        variant="outlined"
        fullWidth
        value={driverFilters.searchTerm}
        onChange={(e) =>
         setDriverFilters({
          ...driverFilters,
          searchTerm: e.target.value,
         })
        }
       />
      </Grid>
      <Grid item xs={12} sm={4}>
       <TextField
        label="Date"
        type="date"
        variant="outlined"
        fullWidth
        InputLabelProps={{ shrink: true }}
        value={driverFilters.date}
        onChange={(e) =>
         setDriverFilters({
          ...driverFilters,
          date: e.target.value,
         })
        }
       />
      </Grid>
     </Grid>

     <div style={{ height: 400, width: "100%" }}>
      <DataGrid
       rows={filteredDrivers}
       columns={driverColumns}
       getRowId={(row) => row._id}
       pageSize={10}
      />
     </div>

     <Box sx={{ mt: 2, textAlign: "center" }}>
      <Button
       variant="contained"
       color="primary"
       startIcon={<DownloadIcon />}
       onClick={handleDownloadDrivers}
       sx={{ mr: 2 }}
      >
       Download Drivers
      </Button>
      <Button
       variant="contained"
       color="secondary"
       onClick={handleCloseDriverModal}
      >
       Close
      </Button>
     </Box>
    </Box>
   </Modal>
  </Box>
 );
};

export default ManageDistributors;

// import React, { useState, useEffect } from "react";
// import {
//  Box,
//  Typography,
//  TextField,
//  Grid,
//  IconButton,
//  Tabs,
//  Tab,
//  Switch,
//  Modal,
//  Button,
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import axios from "axios";
// import PreviewIcon from "@mui/icons-material/Visibility";
// import AddIcon from "@mui/icons-material/Add";
// import DownloadIcon from "@mui/icons-material/Download";
// import * as XLSX from "xlsx";
// import { saveAs } from "file-saver";
// import DistributorFormAdmin from "./DistributorFormAdmin"; // Import the form component

// const ManageDistributors = () => {
//  const [distributors, setDistributors] = useState([]);
//  const [drivers, setDrivers] = useState([]);
//  const [openDistributorModal, setOpenDistributorModal] = useState(false);
//  const [openDriverModal, setOpenDriverModal] = useState(false);
//  const [selectedDistributor, setSelectedDistributor] = useState(null);

//  const [distributorFilters, setDistributorFilters] = useState({
//   searchTerm: "",
//   date: "",
//  });

//  useEffect(() => {
//   fetchDistributors();
//  }, []);

//  // Fetch distributors with driver counts
//  const fetchDistributors = async () => {
//   try {
//    const res = await axios.get("https://api.janatarides.com/api/admin/distributors", {
//     headers: {
//      "x-auth-token": localStorage.getItem("token"),
//     },
//    });

//    const distributorsWithDrivers = await Promise.all(
//     res.data.map(async (distributor) => {
//      const driverRes = await axios.get(
//       `https://api.janatarides.com/api/drivers/distributer?distributorId=${distributor._id}`,
//       {
//        headers: {
//         "x-auth-token": localStorage.getItem("token"),
//        },
//       }
//      );
//      return {
//       ...distributor,
//       drivers: driverRes.data.drivers || [],
//       driverCount: driverRes.data.driverCount || 0,
//      };
//     })
//    );

//    setDistributors(distributorsWithDrivers);
//   } catch (err) {
//    console.error("Error fetching distributors:", err);
//   }
//  };

//  // Handle opening the distributor modal
//  const handleOpenDistributorModal = () => {
//   setOpenDistributorModal(true);
//  };

//  // Handle closing the distributor modal
//  const handleCloseDistributorModal = () => {
//   setOpenDistributorModal(false);
//  };

//  // Handle opening the driver modal
//  const handleOpenDriverModal = (distributor) => {
//   setSelectedDistributor(distributor);
//   setDrivers(distributor.drivers);
//   setOpenDriverModal(true);
//  };

//  // Handle closing the driver modal
//  const handleCloseDriverModal = () => {
//   setOpenDriverModal(false);
//   setSelectedDistributor(null);
//   setDrivers([]);
//  };

//  // Handle distributor added to refresh the list
//  const handleDistributorAdded = () => {
//   fetchDistributors();
//  };

//  // Handle switch change for approval
//  const handleSwitchChange = async (id, newValue) => {
//   try {
//    await axios.put(
//     `https://api.janatarides.com/api/admin/approve-driver/${id}`,
//     { approved: newValue },
//     {
//      headers: {
//       "x-auth-token": localStorage.getItem("token"),
//      },
//     }
//    );

//    setDistributors((prevDistributors) =>
//     prevDistributors.map((distributor) =>
//      distributor._id === id
//       ? { ...distributor, approved: newValue }
//       : distributor
//     )
//    );
//   } catch (error) {
//    console.error("Error updating approval status:", error);
//   }
//  };

//  // Filter distributors based on the filters
//  const filteredDistributors = distributors.filter((distributor) => {
//   const matchesSearchTerm =
//    distributor.name
//     .toLowerCase()
//     .includes(distributorFilters.searchTerm.toLowerCase()) ||
//    (distributor.mobile &&
//     distributor.mobile.includes(distributorFilters.searchTerm));
//   const matchesDate = distributorFilters.date
//    ? new Date(distributor.createdAt).toLocaleDateString() ===
//      new Date(distributorFilters.date).toLocaleDateString()
//    : true;

//   return matchesSearchTerm && matchesDate;
//  });

//  // Columns for the distributor DataGrid
//  const distributorColumns = [
//   { field: "_id", headerName: "ID", width: 150 },
//   { field: "name", headerName: "Name", width: 150 },
//   { field: "email", headerName: "Email", width: 200 },
//   { field: "mobile", headerName: "Mobile", width: 150 },
//   { field: "address", headerName: "Address", width: 200 },
//   {
//    field: "driverCount",
//    headerName: "Number of Cars",
//    width: 150,
//    valueGetter: (params) => params.row.driverCount || 0,
//   },
//   {
//    field: "createdAt",
//    headerName: "Created Date",
//    width: 180,
//    valueGetter: (params) => new Date(params.row.createdAt).toLocaleDateString(),
//   },
//   // {
//   //  field: "approved",
//   //  headerName: "Approval",
//   //  width: 150,
//   //  renderCell: (params) => (
//   //   <Switch
//   //    checked={params.value}
//   //    onChange={(event) =>
//   //     handleSwitchChange(params.row._id, event.target.checked)
//   //    }
//   //   />
//   //  ),
//   // },
//   {
//    field: "actions",
//    headerName: "Actions",
//    width: 100,
//    renderCell: (params) => (
//     <>
//      <IconButton
//       onClick={() => handleOpenDriverModal(params.row)}
//       color="primary"
//      >
//       <PreviewIcon />
//      </IconButton>
//     </>
//    ),
//   },
//  ];

//  // Columns for the driver DataGrid inside the modal
//  const driverColumns = [
//   { field: "_id", headerName: "ID", width: 150 },
//   { field: "driverName", headerName: "Driver Name", width: 150 },
//   { field: "mobile", headerName: "Mobile", width: 150 },
//   { field: "accountNumber", headerName: "Account Number", width: 150 },
//   {
//    field: "approved",
//    headerName: "Approval",
//    width: 150,
//    renderCell: (params) => (
//     <Switch
//      checked={params.value}
//      onChange={(event) =>
//       handleSwitchChange(params.row._id, event.target.checked)
//      }
//     />
//    ),
//   },
//   {
//    field: "isOnline",
//    headerName: "Available",
//    width: 150,
//    renderCell: (params) => (
//     <Box
//      sx={{
//       color: params.value ? "green" : "red",
//       fontWeight: "bold",
//      }}
//     >
//      {params.value ? "Online" : "Offline"}
//     </Box>
//    ),
//   },
//  ];

//  // Function to download distributor data as Excel
//  const handleDownloadDistributors = () => {
//   const exportData = distributors.map((distributor) => ({
//    ID: distributor._id,
//    Name: distributor.name,
//    Email: distributor.email,
//    Mobile: distributor.mobile,
//    Address: distributor.address,
//    "Number of Cars": distributor.driverCount,
//    "Created At": new Date(distributor.createdAt).toLocaleString(),
//    Approved: distributor.approved ? "Yes" : "No",
//   }));

//   const worksheet = XLSX.utils.json_to_sheet(exportData);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Distributors");

//   const excelBuffer = XLSX.write(workbook, {
//    bookType: "xlsx",
//    type: "array",
//   });

//   const data = new Blob([excelBuffer], { type: "application/octet-stream" });
//   saveAs(data, "Distributors.xlsx");
//  };

//  // Function to download driver data for a specific distributor as Excel
//  const handleDownloadDrivers = () => {
//   const exportData = drivers.map((driver) => ({
//    ID: driver._id,
//    "Driver Name": driver.driverName,
//    Mobile: driver.mobile,
//    "Account Number": driver.accountNumber,
//    Approved: driver.approved ? "Yes" : "No",
//    "Created At": new Date(driver.createdAt).toLocaleString(),
//    "Updated At": new Date(driver.updatedAt).toLocaleString(),
//   }));

//   const worksheet = XLSX.utils.json_to_sheet(exportData);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(
//    workbook,
//    worksheet,
//    `Drivers_${selectedDistributor?.name}`
//   );

//   const excelBuffer = XLSX.write(workbook, {
//    bookType: "xlsx",
//    type: "array",
//   });

//   const data = new Blob([excelBuffer], { type: "application/octet-stream" });
//   saveAs(data, `Drivers_${selectedDistributor?.name}.xlsx`);
//  };

//  return (
//   <Box sx={{ p: 3 }}>
//    <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
//     <IconButton onClick={handleOpenDistributorModal} color="primary">
//      <AddIcon />
//      <Typography>Add Distributor</Typography>
//     </IconButton>
//     <Button
//      variant="contained"
//      color="primary"
//      startIcon={<DownloadIcon />}
//      onClick={handleDownloadDistributors}
//     >
//      Download Distributors
//     </Button>
//    </Box>
//    <Tabs value={0} onChange={() => {}} aria-label="manage distributors">
//     <Tab label="Manage Distributors" />
//    </Tabs>

//    {/* Distributor Filters */}
//    <Grid container spacing={2} sx={{ mb: 2 }}>
//     <Grid item xs={12} sm={8}>
//      <TextField
//       label="Search by Name or Mobile"
//       variant="outlined"
//       fullWidth
//       value={distributorFilters.searchTerm}
//       onChange={(e) =>
//        setDistributorFilters({
//         ...distributorFilters,
//         searchTerm: e.target.value,
//        })
//       }
//      />
//     </Grid>
//     <Grid item xs={12} sm={4}>
//      <TextField
//       label="Date"
//       type="date"
//       variant="outlined"
//       fullWidth
//       InputLabelProps={{ shrink: true }}
//       value={distributorFilters.date}
//       onChange={(e) =>
//        setDistributorFilters({
//         ...distributorFilters,
//         date: e.target.value,
//        })
//       }
//      />
//     </Grid>
//    </Grid>

//    <Box sx={{ mb: 5 }}>
//     <div style={{ height: 400, width: "100%" }}>
//      <DataGrid
//       rows={filteredDistributors}
//       columns={distributorColumns}
//       getRowId={(row) => row._id}
//       pageSize={10}
//      />
//     </div>
//    </Box>

//    {/* Distributor Modal */}
//    <DistributorFormAdmin
//     open={openDistributorModal}
//     onClose={handleCloseDistributorModal}
//     onDistributorAdded={handleDistributorAdded}
//    />

//    {/* Driver Modal */}
//    <Modal open={openDriverModal} onClose={handleCloseDriverModal}>
//     <Box
//      sx={{
//       p: 3,
//       maxWidth: "90%",
//       margin: "auto",
//       mt: "10vh",
//       bgcolor: "background.paper",
//      }}
//     >
//      <Typography variant="h6" gutterBottom>
//       Cars Managed by {selectedDistributor?.name}
//      </Typography>

//      <div style={{ height: 400, width: "100%" }}>
//       <DataGrid
//        rows={drivers}
//        columns={driverColumns}
//        getRowId={(row) => row._id}
//        pageSize={10}
//       />
//      </div>

//      <Box sx={{ mt: 2, textAlign: "center" }}>
//       <Button
//        variant="contained"
//        color="primary"
//        startIcon={<DownloadIcon />}
//        onClick={handleDownloadDrivers}
//        sx={{ mr: 2 }}
//       >
//        Download Drivers
//       </Button>
//       <Button
//        variant="contained"
//        color="secondary"
//        onClick={handleCloseDriverModal}
//       >
//        Close
//       </Button>
//      </Box>
//     </Box>
//    </Modal>
//   </Box>
//  );
// };

// export default ManageDistributors;

// // import React, { useState, useEffect } from "react";
// // import {
// //  Box,
// //  Button,
// //  Typography,
// //  Modal,
// //  TextField,
// //  Grid,
// //  IconButton,
// //  Switch,
// // } from "@mui/material";
// // import { DataGrid } from "@mui/x-data-grid";
// // import axios from "axios";
// // import Tabs from "@mui/material/Tabs";
// // import Tab from "@mui/material/Tab";
// // import PreviewIcon from "@mui/icons-material/Visibility";
// // import AddIcon from "@mui/icons-material/Add";
// // import EditIcon from "@mui/icons-material/Edit";
// // import * as XLSX from "xlsx";
// // import { saveAs } from "file-saver";

// // const ManageDistributors = () => {
// //  const [distributors, setDistributors] = useState([]);
// //  const [drivers, setDrivers] = useState([]);
// //  const [selectedDistributor, setSelectedDistributor] = useState(null);
// //  const [openDriverModal, setOpenDriverModal] = useState(false);
// //  const [openDistributorModal, setOpenDistributorModal] = useState(false);

// //  const [distributorFilters, setDistributorFilters] = useState({
// //   name: "",
// //   mobile: "",
// //   date: "",
// //  });
// //  const [driverFilters, setDriverFilters] = useState({
// //   name: "",
// //   mobile: "",
// //   date: "",
// //  });

// //  useEffect(() => {
// //   fetchDistributors();
// //  }, []);

// //  // Fetch distributors with driver counts
// //  const fetchDistributors = async () => {
// //   try {
// //    const res = await axios.get("https://api.janatarides.com/api/admin/distributors", {
// //     headers: {
// //      "x-auth-token": localStorage.getItem("token"),
// //     },
// //    });

// //    const distributorsWithDrivers = await Promise.all(
// //     res.data.map(async (distributor) => {
// //      const driverRes = await axios.get(
// //       `https://api.janatarides.com/api/drivers/distributer?distributorId=${distributor._id}`,
// //       {
// //        headers: {
// //         "x-auth-token": localStorage.getItem("token"),
// //        },
// //       }
// //      );
// //      return {
// //       ...distributor,
// //       drivers: driverRes.data.drivers || [],
// //       driverCount: driverRes.data.driverCount || 0,
// //      };
// //     })
// //    );

// //    setDistributors(distributorsWithDrivers);
// //   } catch (err) {
// //    console.error("Error fetching distributors:", err);
// //   }
// //  };

// //  // Filter distributors based on the filters
// //  const filteredDistributors = distributors.filter((distributor) => {
// //   const matchesName = distributor.name
// //    .toLowerCase()
// //    .includes(distributorFilters.name.toLowerCase());
// //   const matchesMobile = distributor.mobile
// //    ? distributor.mobile.includes(distributorFilters.mobile)
// //    : true;
// //   const matchesDate = distributorFilters.date
// //    ? new Date(distributor.updatedAt).toLocaleDateString() ===
// //      new Date(distributorFilters.date).toLocaleDateString()
// //    : true;

// //   return matchesName && matchesMobile && matchesDate;
// //  });

// //  // Filter drivers based on the filters
// //  const filteredDrivers = drivers.filter((driver) => {
// //   const matchesName = driver.driverName
// //    .toLowerCase()
// //    .includes(driverFilters.name.toLowerCase());
// //   const matchesMobile = driver.mobile?.includes(driverFilters.mobile);
// //   const matchesDate = driverFilters.date
// //    ? new Date(driver.createdAt).toLocaleDateString() ===
// //      new Date(driverFilters.date).toLocaleDateString()
// //    : true;

// //   return matchesName && matchesMobile && matchesDate;
// //  });

// //  // Handle opening the driver modal
// //  const handleOpenDriverModal = (distributor) => {
// //   setSelectedDistributor(distributor);
// //   setDrivers(distributor.drivers);
// //   setOpenDriverModal(true);
// //  };

// //  // Handle closing the driver modal
// //  const handleCloseDriverModal = () => {
// //   setOpenDriverModal(false);
// //   setSelectedDistributor(null);
// //   setDrivers([]);
// //  };

// //  // Handle switch change for driver approval
// //  const handleSwitchChange = async (id, newValue) => {
// //   try {
// //    await axios.put(
// //     `https://api.janatarides.com/api/drivers/${id}`,
// //     { approved: newValue },
// //     {
// //      headers: {
// //       "x-auth-token": localStorage.getItem("token"),
// //      },
// //     }
// //    );
// //    const updatedDrivers = drivers.map((driver) =>
// //     driver._id === id ? { ...driver, approved: newValue } : driver
// //    );
// //    setDrivers(updatedDrivers);
// //   } catch (error) {
// //    console.error("Error updating approval status:", error);
// //   }
// //  };

// //  // Export drivers to Excel
// //  const handleExportDriversToExcel = () => {
// //   const exportData = filteredDrivers.map((driver) => ({
// //    ID: driver._id,
// //    "Driver Name": driver.driverName,
// //    Mobile: driver.mobile,
// //    "Account Number": driver.accountNumber,
// //    Approved: driver.approved ? "Yes" : "No",
// //    "Created At": new Date(driver.createdAt).toLocaleString(),
// //    "Updated At": new Date(driver.updatedAt).toLocaleString(),
// //   }));

// //   const worksheet = XLSX.utils.json_to_sheet(exportData);
// //   const workbook = XLSX.utils.book_new();
// //   XLSX.utils.book_append_sheet(workbook, worksheet, "Drivers");

// //   const excelBuffer = XLSX.write(workbook, {
// //    bookType: "xlsx",
// //    type: "array",
// //   });

// //   const data = new Blob([excelBuffer], { type: "application/octet-stream" });
// //   saveAs(data, `Drivers_${selectedDistributor.name}.xlsx`);
// //  };

// //  // Columns for the distributor DataGrid
// //  const distributorColumns = [
// //   { field: "_id", headerName: "ID", width: 150 },
// //   { field: "name", headerName: "Name", width: 150 },
// //   { field: "email", headerName: "Email", width: 200 },
// //   { field: "mobile", headerName: "Mobile", width: 150 },
// //   { field: "address", headerName: "Address", width: 200 },
// //   {
// //    field: "driverCount",
// //    headerName: "Number of Cars",
// //    width: 150,
// //    valueGetter: (params) => params.row.driverCount || 0,
// //   },
// //   {
// //    field: "document1Url",
// //    headerName: "Document 1",
// //    width: 150,
// //    renderCell: (params) =>
// //     params.value ? (
// //      <a href={params.value} target="_blank" rel="noopener noreferrer">
// //       View Document 1
// //      </a>
// //     ) : (
// //      <Typography variant="body2" color="textSecondary">
// //       Not Uploaded
// //      </Typography>
// //     ),
// //   },
// //   // Add other document columns as needed
// //   {
// //    field: "actions",
// //    headerName: "Actions",
// //    width: 100,
// //    renderCell: (params) => (
// //     <IconButton
// //      onClick={() => handleOpenDriverModal(params.row)}
// //      color="primary"
// //     >
// //      <PreviewIcon />
// //     </IconButton>
// //    ),
// //   },
// //  ];

// //  // Columns for the driver DataGrid inside the modal
// //  const driverColumns = [
// //   { field: "_id", headerName: "ID", width: 150 },
// //   { field: "driverName", headerName: "Driver Name", width: 150 },
// //   { field: "mobile", headerName: "Mobile", width: 150 },
// //   { field: "accountNumber", headerName: "Account Number", width: 150 },
// //   {
// //    field: "approved",
// //    headerName: "Approval",
// //    width: 150,
// //    renderCell: (params) => (
// //     <Switch
// //      checked={params.value}
// //      onChange={(event) =>
// //       handleSwitchChange(params.row._id, event.target.checked)
// //      }
// //     />
// //    ),
// //   },
// //   {
// //    field: "isOnline",
// //    headerName: "Available",
// //    width: 150,
// //    renderCell: (params) => (
// //     <Box
// //      sx={{
// //       color: params.value ? "green" : "red",
// //       fontWeight: "bold",
// //      }}
// //     >
// //      {params.value ? "Online" : "Offline"}
// //     </Box>
// //    ),
// //   },
// //  ];

// //  return (
// //   <Box sx={{ p: 3 }}>
// //   <Box sx={{ mb: 2 }}>
// //     <IconButton onClick={() => setOpenDistributorModal(true)} color="primary">
// //      <AddIcon />
// //      <Typography>Distributor</Typography>
// //     </IconButton>
// //     <IconButton
// //      onClick={() => setOpenDriverModal(true)}
// //      color="primary"
// //      sx={{ ml: 2 }}
// //     >
// //      <AddIcon />
// //      <Typography> Driver</Typography>
// //     </IconButton>
// //    </Box>
// //    <Tabs value={0} onChange={() => {}} aria-label="manage distributors">
// //     <Tab label="Manage Distributors" />
// //    </Tabs>

// //    {/* Distributor Filters */}
// //    <Grid container spacing={2} sx={{ mb: 2 }}>
// //     <Grid item xs={12} sm={4}>
// //      <TextField
// //       label="Name"
// //       variant="outlined"
// //       fullWidth
// //       value={distributorFilters.name}
// //       onChange={(e) =>
// //        setDistributorFilters({
// //         ...distributorFilters,
// //         name: e.target.value,
// //        })
// //       }
// //      />
// //     </Grid>
// //     <Grid item xs={12} sm={4}>
// //      <TextField
// //       label="Mobile"
// //       variant="outlined"
// //       fullWidth
// //       value={distributorFilters.mobile}
// //       onChange={(e) =>
// //        setDistributorFilters({
// //         ...distributorFilters,
// //         mobile: e.target.value,
// //        })
// //       }
// //      />
// //     </Grid>
// //     <Grid item xs={12} sm={4}>
// //      <TextField
// //       label="Date"
// //       type="date"
// //       variant="outlined"
// //       fullWidth
// //       InputLabelProps={{ shrink: true }}
// //       value={distributorFilters.date}
// //       onChange={(e) =>
// //        setDistributorFilters({
// //         ...distributorFilters,
// //         date: e.target.value,
// //        })
// //       }
// //      />
// //     </Grid>
// //    </Grid>

// //    <Box sx={{ mb: 5 }}>
// //     <div style={{ height: 400, width: "100%" }}>
// //      <DataGrid
// //       rows={filteredDistributors}
// //       columns={distributorColumns}
// //       getRowId={(row) => row._id}
// //       pageSize={10}
// //      />
// //     </div>
// //    </Box>

// //    {/* Driver Modal */}
// //    <Modal open={openDriverModal} onClose={handleCloseDriverModal}>
// //     <Box
// //      sx={{
// //       p: 3,
// //       maxWidth: "90%",
// //       margin: "auto",
// //       mt: "10vh",
// //       bgcolor: "background.paper",
// //      }}
// //     >
// //      <Typography variant="h6" gutterBottom>
// //       Drivers Managed by {selectedDistributor?.name}
// //      </Typography>

// //      {/* Driver Filters */}
// //      <Grid container spacing={2} sx={{ mb: 2 }}>
// //       <Grid item xs={12} sm={4}>
// //        <TextField
// //         label="Driver Name"
// //         variant="outlined"
// //         fullWidth
// //         value={driverFilters.name}
// //         onChange={(e) =>
// //          setDriverFilters({ ...driverFilters, name: e.target.value })
// //         }
// //        />
// //       </Grid>
// //       <Grid item xs={12} sm={4}>
// //        <TextField
// //         label="Mobile"
// //         variant="outlined"
// //         fullWidth
// //         value={driverFilters.mobile}
// //         onChange={(e) =>
// //          setDriverFilters({
// //           ...driverFilters,
// //           mobile: e.target.value,
// //          })
// //         }
// //        />
// //       </Grid>
// //       <Grid item xs={12} sm={4}>
// //        <TextField
// //         label="Date"
// //         type="date"
// //         variant="outlined"
// //         fullWidth
// //         InputLabelProps={{ shrink: true }}
// //         value={driverFilters.date}
// //         onChange={(e) =>
// //          setDriverFilters({ ...driverFilters, date: e.target.value })
// //         }
// //        />
// //       </Grid>
// //      </Grid>

// //      <div style={{ height: 400, width: "100%" }}>
// //       <DataGrid
// //        rows={filteredDrivers}
// //        columns={driverColumns}
// //        getRowId={(row) => row._id}
// //        pageSize={10}
// //       />
// //      </div>

// //      <Box sx={{ mt: 2, textAlign: "center" }}>
// //       <Button
// //        variant="contained"
// //        color="primary"
// //        onClick={handleExportDriversToExcel}
// //       >
// //        Export Drivers to Excel
// //       </Button>
// //       <Button
// //        variant="contained"
// //        color="secondary"
// //        onClick={handleCloseDriverModal}
// //        sx={{ ml: 2 }}
// //       >
// //        Close
// //       </Button>
// //      </Box>
// //     </Box>
// //    </Modal>
// //   </Box>
// //  );
// // };

// // export default ManageDistributors;

// // // import React, { useState, useEffect } from "react";
// // // import {
// // //  Box,
// // //  Button,
// // //  Typography,
// // //  Modal,
// // //  IconButton,
// // //  Switch,
// // // } from "@mui/material";
// // // import { DataGrid } from "@mui/x-data-grid";
// // // import axios from "axios";
// // // import Tabs from "@mui/material/Tabs";
// // // import Tab from "@mui/material/Tab";
// // // import AddIcon from "@mui/icons-material/Add";
// // // import PreviewIcon from "@mui/icons-material/Visibility";
// // // import { useDispatch, useSelector } from "react-redux";

// // // const ManageDistributors = () => {
// // //  const dispatch = useDispatch();
// // //  const { user, isAuthenticated } = useSelector((state) => state.auth);
// // //  const [distributors, setDistributors] = useState([]);
// // //  const [drivers, setDrivers] = useState([]);
// // //  const [selectedDistributor, setSelectedDistributor] = useState(null);
// // //  const [openDriverModal, setOpenDriverModal] = useState(false);

// // //  useEffect(() => {
// // //   fetchDistributors();
// // //  }, []);

// // //  // Fetch distributors with driver counts
// // //  const fetchDistributors = async () => {
// // //   try {
// // //    const res = await axios.get("https://api.janatarides.com/api/admin/distributors", {
// // //     headers: {
// // //      "x-auth-token": localStorage.getItem("token"),
// // //     },
// // //    });

// // //    const distributorsWithDrivers = await Promise.all(
// // //     res.data.map(async (distributor) => {
// // //      const driverRes = await axios.get(
// // //       `https://api.janatarides.com/api/drivers/distributer?distributorId=${distributor._id}`,
// // //       {
// // //        headers: {
// // //         "x-auth-token": localStorage.getItem("token"),
// // //        },
// // //       }
// // //      );
// // //      return {
// // //       ...distributor,
// // //       drivers: driverRes.data.drivers,
// // //       driverCount: driverRes.data.driverCount,
// // //      };
// // //     })
// // //    );

// // //    setDistributors(distributorsWithDrivers);
// // //   } catch (err) {
// // //    console.error("Error fetching distributors:", err);
// // //   }
// // //  };

// // //  // Handle opening the driver modal
// // //  const handleOpenDriverModal = (distributor) => {
// // //   setSelectedDistributor(distributor);
// // //   setDrivers(distributor.drivers); // Use the pre-fetched drivers data
// // //   setOpenDriverModal(true);
// // //  };

// // //  // Handle closing the driver modal
// // //  const handleCloseDriverModal = () => {
// // //   setOpenDriverModal(false);
// // //   setSelectedDistributor(null);
// // //   setDrivers([]);
// // //  };

// // //  // Handle switch change for driver approval
// // //  const handleSwitchChange = async (id, newValue) => {
// // //   try {
// // //    await axios.put(
// // //     `https://api.janatarides.com/api/drivers/${id}`,
// // //     { approved: newValue },
// // //     {
// // //      headers: {
// // //       "x-auth-token": localStorage.getItem("token"),
// // //      },
// // //     }
// // //    );
// // //    // Update the drivers list in the selected distributor
// // //    const updatedDrivers = drivers.map((driver) =>
// // //     driver._id === id ? { ...driver, approved: newValue } : driver
// // //    );
// // //    setDrivers(updatedDrivers);
// // //   } catch (error) {
// // //    console.error("Error updating approval status:", error);
// // //   }
// // //  };

// // //  // Handle driver approval
// // //  const handleApproveDriver = async (id) => {
// // //   try {
// // //    await axios.put(
// // //     `https://api.janatarides.com/api/admin/approve-driver/${id}`,
// // //     {},
// // //     {
// // //      headers: {
// // //       "x-auth-token": localStorage.getItem("token"),
// // //      },
// // //     }
// // //    );
// // //    // Update the drivers list in the selected distributor
// // //    const updatedDrivers = drivers.map((driver) =>
// // //     driver._id === id ? { ...driver, approved: true } : driver
// // //    );
// // //    setDrivers(updatedDrivers);
// // //   } catch (err) {
// // //    console.error("Error approving driver:", err);
// // //   }
// // //  };

// // //  // Columns for the distributor DataGrid
// // //  const distributorColumns = [
// // //   { field: "id", headerName: "ID", width: 100 },
// // //   { field: "name", headerName: "Name", width: 150 },
// // //   { field: "email", headerName: "Email", width: 200 },
// // //   { field: "mobile", headerName: "Mobile", width: 150 },
// // //   { field: "address", headerName: "Address", width: 200 },
// // //   {
// // //    field: "driverCount",
// // //    headerName: "Number of Cars",
// // //    width: 150,
// // //    valueGetter: (params) => params.row.driverCount || 0,
// // //   },
// // //   {
// // //    field: "document1Url",
// // //    headerName: "Document 1",
// // //    width: 150,
// // //    renderCell: (params) =>
// // //     params.value ? (
// // //      <a href={params.value} target="_blank" rel="noopener noreferrer">
// // //       View Document 1
// // //      </a>
// // //     ) : (
// // //      <Typography variant="body2" color="textSecondary">
// // //       Not Uploaded
// // //      </Typography>
// // //     ),
// // //   },
// // //   {
// // //    field: "document2Url",
// // //    headerName: "Document 2",
// // //    width: 150,
// // //    renderCell: (params) =>
// // //     params.value ? (
// // //      <a href={params.value} target="_blank" rel="noopener noreferrer">
// // //       View Document 2
// // //      </a>
// // //     ) : (
// // //      <Typography variant="body2" color="textSecondary">
// // //       Not Uploaded
// // //      </Typography>
// // //     ),
// // //   },
// // //   {
// // //    field: "document3Url",
// // //    headerName: "Document 3",
// // //    width: 150,
// // //    renderCell: (params) =>
// // //     params.value ? (
// // //      <a href={params.value} target="_blank" rel="noopener noreferrer">
// // //       View Document 3
// // //      </a>
// // //     ) : (
// // //      <Typography variant="body2" color="textSecondary">
// // //       Not Uploaded
// // //      </Typography>
// // //     ),
// // //   },
// // //   {
// // //    field: "document4Url",
// // //    headerName: "Document 4",
// // //    width: 150,
// // //    renderCell: (params) =>
// // //     params.value ? (
// // //      <a href={params.value} target="_blank" rel="noopener noreferrer">
// // //       View Document 4
// // //      </a>
// // //     ) : (
// // //      <Typography variant="body2" color="textSecondary">
// // //       Not Uploaded
// // //      </Typography>
// // //     ),
// // //   },
// // //   {
// // //    field: "document5Url",
// // //    headerName: "Profile Picture",
// // //    width: 150,
// // //    renderCell: (params) =>
// // //     params.value ? (
// // //      <img
// // //       src={params.value}
// // //       alt="Profile"
// // //       style={{ width: "50px", height: "50px", borderRadius: "50%" }}
// // //      />
// // //     ) : (
// // //      <Typography variant="body2" color="textSecondary">
// // //       Not Uploaded
// // //      </Typography>
// // //     ),
// // //   },
// // //   {
// // //    field: "actions",
// // //    headerName: "Actions",
// // //    width: 100,
// // //    renderCell: (params) => (
// // //     <IconButton
// // //      onClick={() => handleOpenDriverModal(params.row)}
// // //      color="primary"
// // //     >
// // //      <PreviewIcon />
// // //     </IconButton>
// // //    ),
// // //   },
// // //  ];

// // //  // Columns for the driver DataGrid inside the modal
// // //  const driverColumns = [
// // //   { field: "id", headerName: "ID", width: 100 },
// // //   { field: "driverName", headerName: "Driver Name", width: 150 },
// // //   { field: "mobile", headerName: "Mobile", width: 200 },
// // //   { field: "accountNumber", headerName: "Account Number", width: 200 },
// // //   {
// // //    field: "approved",
// // //    headerName: "Approval",
// // //    width: 150,
// // //    renderCell: (params) =>
// // //     user && user.role === "superadmin" ? (
// // //      <Switch
// // //       checked={params.value}
// // //       onChange={(event) =>
// // //        handleSwitchChange(params.row._id, event.target.checked)
// // //       }
// // //      />
// // //     ) : (
// // //      <Typography>{params.value ? "Yes" : "No"}</Typography>
// // //     ),
// // //   },
// // //   {
// // //    field: "isOnline",
// // //    headerName: "Available",
// // //    width: 150,
// // //    renderCell: (params) => (
// // //     <Box
// // //      sx={{
// // //       color: params.value ? "green" : "red",
// // //       fontWeight: "bold",
// // //      }}
// // //     >
// // //      {params.value ? "Online" : "Offline"}
// // //     </Box>
// // //    ),
// // //   },
// // //   {
// // //    field: "actions",
// // //    headerName: "Actions",
// // //    width: 150,
// // //    renderCell: (params) => (
// // //     <Box>
// // //      <Button
// // //       variant="contained"
// // //       color="primary"
// // //       onClick={() => handleApproveDriver(params.row._id)}
// // //       disabled={params.row.approved}
// // //      >
// // //       {params.row.approved ? "Approved" : "Approve"}
// // //      </Button>{" "}
// // //     </Box>
// // //    ),
// // //   },
// // //  ];

// // //  return (
// // //   <Box sx={{ p: 3 }}>
// // //    <Tabs value={0} onChange={() => {}} aria-label="manage distributors">
// // //     <Tab label="Manage Distributors" />
// // //    </Tabs>

// // //    <Box sx={{ mb: 5 }}>
// // //     <div style={{ height: 400, width: "100%" }}>
// // //      <Box
// // //       sx={{ border: "1px solid #cccc", width: "100%", height: "10vh" }}
// // //      ></Box>
// // //      <DataGrid
// // //       rows={distributors.map((distributor, index) => ({
// // //        id: index + 1,
// // //        ...distributor,
// // //       }))}
// // //       columns={distributorColumns}
// // //       pageSize={10}
// // //      />
// // //     </div>
// // //    </Box>

// // //    {/* Driver Modal */}
// // //    <Modal open={openDriverModal} onClose={handleCloseDriverModal}>
// // //     <Box
// // //      sx={{
// // //       p: 3,
// // //       maxWidth: "90%",
// // //       margin: "auto",
// // //       mt: "10vh",
// // //       bgcolor: "background.paper",
// // //      }}
// // //     >
// // //      <Typography variant="h6" gutterBottom>
// // //       Drivers Managed by {selectedDistributor?.name}
// // //      </Typography>
// // //      <div style={{ height: 400, width: "100%" }}>
// // //       <DataGrid
// // //        rows={drivers.map((driver, index) => ({
// // //         id: index + 1,
// // //         ...driver,
// // //        }))}
// // //        columns={driverColumns}
// // //        pageSize={10}
// // //       />
// // //      </div>
// // //      <Box sx={{ mt: 2, textAlign: "center" }}>
// // //       <Button
// // //        variant="contained"
// // //        color="secondary"
// // //        onClick={handleCloseDriverModal}
// // //       >
// // //        Close
// // //       </Button>
// // //      </Box>
// // //     </Box>
// // //    </Modal>
// // //   </Box>
// // //  );
// // // };

// // // export default ManageDistributors;

// // // // import React, { useState, useEffect } from "react";
// // // // import {
// // // //  Box,
// // // //  Button,
// // // //  TextField,
// // // //  Typography,
// // // //  Modal,
// // // //  IconButton,
// // // //  Switch,
// // // // } from "@mui/material";
// // // // import { DataGrid } from "@mui/x-data-grid";
// // // // import axios from "axios";
// // // // import Tabs from "@mui/material/Tabs";
// // // // import Tab from "@mui/material/Tab";
// // // // import AddIcon from "@mui/icons-material/Add";
// // // // import PreviewIcon from "@mui/icons-material/Visibility";
// // // // import { useDispatch, useSelector } from "react-redux";

// // // // // ManageDistributors Component
// // // // const ManageDistributors = () => {
// // // //  const dispatch = useDispatch();
// // // //  const { user, isAuthenticated } = useSelector((state) => state.auth);
// // // //  const [distributors, setDistributors] = useState([]);
// // // //  const [drivers, setDrivers] = useState([]);
// // // //  const [selectedDistributor, setSelectedDistributor] = useState(null);
// // // //  const [openDriverModal, setOpenDriverModal] = useState(false);

// // // //  useEffect(() => {
// // // //   fetchDistributors();
// // // //  }, []);

// // // //  // Fetch distributors
// // // //  const fetchDistributors = async () => {
// // // //   try {
// // // //    const res = await axios.get("https://api.janatarides.com/api/admin/distributors", {
// // // //     headers: {
// // // //      "x-auth-token": localStorage.getItem("token"),
// // // //     },
// // // //    });
// // // //    setDistributors(res.data);
// // // //   } catch (err) {
// // // //    console.error("Error fetching distributors:", err);
// // // //   }
// // // //  };

// // // //  // Fetch drivers added by a distributor
// // // //  const fetchDrivers = async (distributorId) => {
// // // //   try {
// // // //    const res = await axios.get(
// // // //     `https://api.janatarides.com/api/drivers/distributer?distributorId=${distributorId}`,
// // // //     {
// // // //      headers: {
// // // //       "x-auth-token": localStorage.getItem("token"),
// // // //      },
// // // //     }
// // // //    );
// // // //    setDrivers(res.data);
// // // //   } catch (err) {
// // // //    console.error("Error fetching drivers:", err);
// // // //   }
// // // //  };

// // // //  // Handle opening the driver modal
// // // //  const handleOpenDriverModal = (distributor) => {
// // // //   setSelectedDistributor(distributor);
// // // //   fetchDrivers(distributor._id); // Fetch drivers for the selected distributor
// // // //   setOpenDriverModal(true);
// // // //  };

// // // //  // Handle closing the driver modal
// // // //  const handleCloseDriverModal = () => {
// // // //   setOpenDriverModal(false);
// // // //   setSelectedDistributor(null);
// // // //   setDrivers([]);
// // // //  };

// // // //  // Handle switch change for driver approval
// // // //  const handleSwitchChange = async (id, newValue) => {
// // // //   try {
// // // //    await axios.put(
// // // //     `https://api.janatarides.com/api/drivers/${id}`,
// // // //     { approved: newValue },
// // // //     {
// // // //      headers: {
// // // //       "x-auth-token": localStorage.getItem("token"),
// // // //      },
// // // //     }
// // // //    );
// // // //    fetchDrivers(selectedDistributor._id); // Refresh the drivers list
// // // //   } catch (error) {
// // // //    console.error("Error updating approval status:", error);
// // // //   }
// // // //  };

// // // //  // Handle driver approval
// // // //  const handleApproveDriver = async (id) => {
// // // //   try {
// // // //    await axios.put(
// // // //     `https://api.janatarides.com/api/admin/approve-driver/${id}`,
// // // //     {},
// // // //     {
// // // //      headers: {
// // // //       "x-auth-token": localStorage.getItem("token"),
// // // //      },
// // // //     }
// // // //    );
// // // //    fetchDrivers(selectedDistributor._id); // Refresh the driver list after approval
// // // //   } catch (err) {
// // // //    console.error("Error approving driver:", err);
// // // //   }
// // // //  };

// // // //  // Columns for the distributor DataGrid
// // // //  const distributorColumns = [
// // // //   { field: "id", headerName: "ID", width: 100 },
// // // //   { field: "name", headerName: "Name", width: 150 },
// // // //   { field: "email", headerName: "Email", width: 200 },
// // // //   { field: "mobile", headerName: "Mobile", width: 150 },
// // // //   { field: "address", headerName: "Address", width: 200 },
// // // //   {
// // // //    field: "document1Url",
// // // //    headerName: "Document 1",
// // // //    width: 150,
// // // //    renderCell: (params) =>
// // // //     params.value ? (
// // // //      <a href={params.value} target="_blank" rel="noopener noreferrer">
// // // //       View Document 1
// // // //      </a>
// // // //     ) : (
// // // //      <Typography variant="body2" color="textSecondary">
// // // //       Not Uploaded
// // // //      </Typography>
// // // //     ),
// // // //   },
// // // //   {
// // // //    field: "document2Url",
// // // //    headerName: "Document 2",
// // // //    width: 150,
// // // //    renderCell: (params) =>
// // // //     params.value ? (
// // // //      <a href={params.value} target="_blank" rel="noopener noreferrer">
// // // //       View Document 2
// // // //      </a>
// // // //     ) : (
// // // //      <Typography variant="body2" color="textSecondary">
// // // //       Not Uploaded
// // // //      </Typography>
// // // //     ),
// // // //   },
// // // //   {
// // // //    field: "document3Url",
// // // //    headerName: "Document 3",
// // // //    width: 150,
// // // //    renderCell: (params) =>
// // // //     params.value ? (
// // // //      <a href={params.value} target="_blank" rel="noopener noreferrer">
// // // //       View Document 3
// // // //      </a>
// // // //     ) : (
// // // //      <Typography variant="body2" color="textSecondary">
// // // //       Not Uploaded
// // // //      </Typography>
// // // //     ),
// // // //   },
// // // //   {
// // // //    field: "document4Url",
// // // //    headerName: "Document 4",
// // // //    width: 150,
// // // //    renderCell: (params) =>
// // // //     params.value ? (
// // // //      <a href={params.value} target="_blank" rel="noopener noreferrer">
// // // //       View Document 4
// // // //      </a>
// // // //     ) : (
// // // //      <Typography variant="body2" color="textSecondary">
// // // //       Not Uploaded
// // // //      </Typography>
// // // //     ),
// // // //   },
// // // //   {
// // // //    field: "document5Url",
// // // //    headerName: "Profile Picture",
// // // //    width: 150,
// // // //    renderCell: (params) =>
// // // //     params.value ? (
// // // //      <img
// // // //       src={params.value}
// // // //       alt="Profile"
// // // //       style={{ width: "50px", height: "50px", borderRadius: "50%" }}
// // // //      />
// // // //     ) : (
// // // //      <Typography variant="body2" color="textSecondary">
// // // //       Not Uploaded
// // // //      </Typography>
// // // //     ),
// // // //   },
// // // //   {
// // // //    field: "actions",
// // // //    headerName: "Actions",
// // // //    width: 100,
// // // //    renderCell: (params) => (
// // // //     <IconButton
// // // //      onClick={() => handleOpenDriverModal(params.row)}
// // // //      color="primary"
// // // //     >
// // // //      <PreviewIcon />
// // // //     </IconButton>
// // // //    ),
// // // //   },
// // // //  ];

// // // //  // Columns for the driver DataGrid inside the modal
// // // //  const driverColumns = [
// // // //   { field: "id", headerName: "ID", width: 100 },
// // // //   { field: "driverName", headerName: "Driver Name", width: 150 },
// // // //   { field: "mobile", headerName: "Mobile", width: 200 },
// // // //   { field: "accountNumber", headerName: "Account Number", width: 200 },
// // // //   {
// // // //    field: "approved",
// // // //    headerName: "Approval",
// // // //    width: 150,
// // // //    renderCell: (params) =>
// // // //     user && user.role === "superadmin" ? (
// // // //      <Switch
// // // //       checked={params.value}
// // // //       onChange={(event) =>
// // // //        handleSwitchChange(params.row._id, event.target.checked)
// // // //       }
// // // //      />
// // // //     ) : (
// // // //      <Typography>{params.value ? "Yes" : "No"}</Typography>
// // // //     ),
// // // //   },
// // // //   {
// // // //    field: "isOnline",
// // // //    headerName: "Available",
// // // //    width: 150,
// // // //    renderCell: (params) => (
// // // //     <Box
// // // //      sx={{
// // // //       color: params.value ? "green" : "red",
// // // //       fontWeight: "bold",
// // // //      }}
// // // //     >
// // // //      {params.value ? "Online" : "Offline"}
// // // //     </Box>
// // // //    ),
// // // //   },
// // // //   {
// // // //    field: "actions",
// // // //    headerName: "Actions",
// // // //    width: 150,
// // // //    renderCell: (params) => (
// // // //     <Button
// // // //      variant="contained"
// // // //      color="primary"
// // // //      onClick={() => handleApproveDriver(params.row._id)}
// // // //      disabled={params.row.approved}
// // // //     >
// // // //      {params.row.approved ? "Approved" : "Approve"}
// // // //     </Button>
// // // //    ),
// // // //   },
// // // //  ];

// // // //  return (
// // // //   <Box sx={{ p: 3 }}>
// // // //    <Tabs value={0} onChange={() => {}} aria-label="manage distributors">
// // // //     <Tab label="Manage Distributors" />
// // // //    </Tabs>

// // // //    <Box sx={{ mb: 5 }}>
// // // //     <div style={{ height: 400, width: "100%" }}>
// // // //      <DataGrid
// // // //       rows={distributors.map((distributor, index) => ({
// // // //        id: index + 1,
// // // //        ...distributor,
// // // //       }))}
// // // //       columns={distributorColumns}
// // // //       pageSize={10}
// // // //      />
// // // //     </div>
// // // //    </Box>

// // // //    {/* Driver Modal */}
// // // //    <Modal open={openDriverModal} onClose={handleCloseDriverModal}>
// // // //     <Box
// // // //      sx={{
// // // //       p: 3,
// // // //       maxWidth: 800,
// // // //       margin: "auto",
// // // //       mt: "10vh",
// // // //       bgcolor: "background.paper",
// // // //      }}
// // // //     >
// // // //      <Typography variant="h6" gutterBottom>
// // // //       Drivers Managed by {selectedDistributor?.name}
// // // //      </Typography>
// // // //      <div style={{ height: 400, width: "100%" }}>
// // // //       <DataGrid
// // // //        rows={drivers.map((driver, index) => ({
// // // //         id: index + 1,
// // // //         ...driver,
// // // //        }))}
// // // //        columns={driverColumns}
// // // //        pageSize={10}
// // // //       />
// // // //      </div>
// // // //      <Box sx={{ mt: 2, textAlign: "center" }}>
// // // //       <Button
// // // //        variant="contained"
// // // //        color="secondary"
// // // //        onClick={handleCloseDriverModal}
// // // //       >
// // // //        Close
// // // //       </Button>
// // // //      </Box>
// // // //     </Box>
// // // //    </Modal>
// // // //   </Box>
// // // //  );
// // // // };

// // // // export default ManageDistributors;

// // // // // import React, { useState, useEffect } from "react";
// // // // // import {
// // // // //  Box,
// // // // //  Button,
// // // // //  TextField,
// // // // //  Typography,
// // // // //  Modal,
// // // // //  IconButton,
// // // // //  Switch,
// // // // // } from "@mui/material";
// // // // // import { DataGrid } from "@mui/x-data-grid";
// // // // // import axios from "axios";
// // // // // import Tabs from "@mui/material/Tabs";
// // // // // import Tab from "@mui/material/Tab";
// // // // // import AddIcon from "@mui/icons-material/Add";
// // // // // import EditIcon from "@mui/icons-material/Edit";
// // // // // import { useDispatch, useSelector } from "react-redux";

// // // // // // ManageDistributors Component
// // // // // const ManageDistributors = () => {
// // // // //  // State for managing distributors
// // // // //  const dispatch = useDispatch();
// // // // //  const { user, isAuthenticated } = useSelector((state) => state.auth);
// // // // //  const [distributors, setDistributors] = useState([]);
// // // // //  const [distributorFormData, setDistributorFormData] = useState({
// // // // //   name: "",
// // // // //   mobile: "",
// // // // //   email: "",
// // // // //   password: "",
// // // // //  });
// // // // //  const [distributorLoading, setDistributorLoading] = useState(false);

// // // // //  // State for managing drivers
// // // // //  const [drivers, setDrivers] = useState([]);
// // // // //  const [driverFormData, setDriverFormData] = useState({
// // // // //   driverName: "",
// // // // //   mobile: "",
// // // // //   accountNumber: "",
// // // // //  });
// // // // //  const [driverLoading, setDriverLoading] = useState(false);

// // // // //  // State for tab control
// // // // //  const [tabIndex, setTabIndex] = useState(0);

// // // // //  // State for modals
// // // // //  const [openDistributorModal, setOpenDistributorModal] = useState(false);
// // // // //  const [openDriverModal, setOpenDriverModal] = useState(false);

// // // // //  useEffect(() => {
// // // // //   fetchDistributors();
// // // // //   fetchDrivers();
// // // // //  }, []);

// // // // //  // Fetch distributors
// // // // //  const fetchDistributors = async () => {
// // // // //   try {
// // // // //    const res = await axios.get("https://api.janatarides.com/api/admin/distributors", {
// // // // //     headers: {
// // // // //      "x-auth-token": localStorage.getItem("token"),
// // // // //     },
// // // // //    });
// // // // //    setDistributors(res.data);
// // // // //   } catch (err) {
// // // // //    console.error("Error fetching distributors:", err);
// // // // //   }
// // // // //  };

// // // // //  // Fetch drivers
// // // // //  const fetchDrivers = async () => {
// // // // //   try {
// // // // //    const res = await axios.get("https://api.janatarides.com/api/drivers", {
// // // // //     headers: {
// // // // //      "x-auth-token": localStorage.getItem("token"),
// // // // //     },
// // // // //    });
// // // // //    setDrivers(res.data);
// // // // //   } catch (err) {
// // // // //    console.error("Error fetching drivers:", err);
// // // // //   }
// // // // //  };

// // // // //  // Handle input change for distributor form
// // // // //  const handleDistributorInputChange = (e) => {
// // // // //   const { name, value } = e.target;
// // // // //   setDistributorFormData({ ...distributorFormData, [name]: value });
// // // // //  };

// // // // //  // Handle input change for driver form
// // // // //  const handleDriverInputChange = (e) => {
// // // // //   const { name, value } = e.target;
// // // // //   setDriverFormData({ ...driverFormData, [name]: value });
// // // // //  };

// // // // //  // Handle form submission for distributor
// // // // //  const handleDistributorSubmit = async (e) => {
// // // // //   e.preventDefault();
// // // // //   setDistributorLoading(true);
// // // // //   try {
// // // // //    const res = await axios.post(
// // // // //     "https://api.janatarides.com/api/admin/create-distributor",
// // // // //     distributorFormData,
// // // // //     {
// // // // //      headers: {
// // // // //       "x-auth-token": localStorage.getItem("token"),
// // // // //      },
// // // // //     }
// // // // //    );
// // // // //    setDistributors([...distributors, res.data.user]);
// // // // //    setDistributorFormData({ name: "", email: "", password: "" });
// // // // //    setOpenDistributorModal(false); // Close modal after submission
// // // // //   } catch (err) {
// // // // //    console.error("Error creating distributor:", err);
// // // // //   }
// // // // //   setDistributorLoading(false);
// // // // //  };

// // // // //  // Handle form submission for driver
// // // // //  const handleDriverSubmit = async (e) => {
// // // // //   e.preventDefault();
// // // // //   setDriverLoading(true);
// // // // //   try {
// // // // //    const res = await axios.post(
// // // // //     "https://api.janatarides.com/api/drivers/add",
// // // // //     driverFormData,
// // // // //     {
// // // // //      headers: {
// // // // //       "x-auth-token": localStorage.getItem("token"),
// // // // //      },
// // // // //     }
// // // // //    );
// // // // //    setDrivers([...drivers, res.data.driver]);
// // // // //    setDriverFormData({ driverName: "", mobile: "", accountNumber: "" });
// // // // //    setOpenDriverModal(false); // Close modal after submission
// // // // //   } catch (err) {
// // // // //    console.error("Error creating driver:", err);
// // // // //   }
// // // // //   setDriverLoading(false);
// // // // //  };

// // // // //  // Handle driver approval
// // // // //  const handleApproveDriver = async (id) => {
// // // // //   try {
// // // // //    await axios.put(
// // // // //     `https://api.janatarides.com/api/admin/approve-driver/${id}`,
// // // // //     {},
// // // // //     {
// // // // //      headers: {
// // // // //       "x-auth-token": localStorage.getItem("token"),
// // // // //      },
// // // // //     }
// // // // //    );
// // // // //    fetchDrivers(); // Refresh the driver list after approval
// // // // //   } catch (err) {
// // // // //    console.error("Error approving driver:", err);
// // // // //   }
// // // // //  };
// // // // //  const handleSwitchChange = async (id, newValue) => {
// // // // //   try {
// // // // //    // Call your backend to update the 'approved' status
// // // // //    await axios.put(`/api/drivers/${id}`, { approved: newValue });
// // // // //    dispatch(fetchDrivers()); // Refresh the drivers list
// // // // //   } catch (error) {
// // // // //    console.error("Error updating approval status:", error);
// // // // //   }
// // // // //  };
// // // // //  // Columns for the distributor DataGrid
// // // // //  const distributorColumns = [
// // // // //   { field: "id", headerName: "ID", width: 100 },
// // // // //   { field: "name", headerName: "Name", width: 150 },
// // // // //   { field: "email", headerName: "Email", width: 200 },
// // // // //  ];

// // // // //  // Columns for the driver DataGrid
// // // // //  const driverColumns = [
// // // // //   { field: "id", headerName: "ID", width: 100 },
// // // // //   { field: "driverName", headerName: "Driver Name", width: 150 },
// // // // //   { field: "mobile", headerName: "Mobile", width: 200 },
// // // // //   { field: "accountNumber", headerName: "Account Number", width: 200 },

// // // // //   {
// // // // //    field: "approved",
// // // // //    headerName: "Approval",
// // // // //    width: 200,
// // // // //    renderCell: (params) =>
// // // // //     user && user.role === "superadmin" ? (
// // // // //      <Switch
// // // // //       checked={params.value}
// // // // //       onChange={(event) =>
// // // // //        handleSwitchChange(params.row.id, event.target.checked)
// // // // //       }
// // // // //      />
// // // // //     ) : (
// // // // //      <Typography>{params.value ? "Yes" : "No"}</Typography>
// // // // //     ),
// // // // //   },
// // // // //   {
// // // // //    field: "actions",
// // // // //    headerName: "Actions",
// // // // //    width: 200,
// // // // //    renderCell: (params) => (
// // // // //     <Button
// // // // //      variant="contained"
// // // // //      color="primary"
// // // // //      onClick={() => handleApproveDriver(params.row._id)}
// // // // //      disabled={params.row.approved}
// // // // //     >
// // // // //      {params.row.approved ? "Approved" : "Approve"}
// // // // //     </Button>
// // // // //    ),
// // // // //   },
// // // // //  ];

// // // // //  return (
// // // // //   <Box sx={{ p: 3 }}>
// // // // //    <Box sx={{ mb: 2 }}>
// // // // //     <IconButton onClick={() => setOpenDistributorModal(true)} color="primary">
// // // // //      <AddIcon />
// // // // //      <Typography>Distributor</Typography>
// // // // //     </IconButton>
// // // // //     <IconButton
// // // // //      onClick={() => setOpenDriverModal(true)}
// // // // //      color="primary"
// // // // //      sx={{ ml: 2 }}
// // // // //     >
// // // // //      <AddIcon />
// // // // //      <Typography> Driver</Typography>
// // // // //     </IconButton>
// // // // //    </Box>

// // // // //    <Tabs
// // // // //     value={tabIndex}
// // // // //     onChange={(e, newValue) => setTabIndex(newValue)}
// // // // //     aria-label="manage tabs"
// // // // //    >
// // // // //     <Tab label="Manage Distributors" />
// // // // //    </Tabs>

// // // // //    {tabIndex === 0 && (
// // // // //     <Box sx={{ mb: 5 }}>
// // // // //      <div style={{ height: 400, width: "100%" }}>
// // // // //       <DataGrid
// // // // //        rows={distributors.map((distributor, index) => ({
// // // // //         id: index + 1,
// // // // //         ...distributor,
// // // // //        }))}
// // // // //        columns={distributorColumns}
// // // // //        pageSize={10}
// // // // //       />
// // // // //      </div>
// // // // //     </Box>
// // // // //    )}

// // // // //    {/* Distributor Modal */}
// // // // //    <Modal
// // // // //     open={openDistributorModal}
// // // // //     onClose={() => setOpenDistributorModal(false)}
// // // // //    >
// // // // //     <Box
// // // // //      sx={{
// // // // //       p: 3,
// // // // //       maxWidth: 400,
// // // // //       margin: "auto",
// // // // //       mt: "20vh",
// // // // //       bgcolor: "background.paper",
// // // // //      }}
// // // // //     >
// // // // //      <Typography variant="h6" gutterBottom>
// // // // //       Register Distributor
// // // // //      </Typography>
// // // // //      <Box component="form" onSubmit={handleDistributorSubmit}>
// // // // //       <TextField
// // // // //        label="Name"
// // // // //        name="name"
// // // // //        value={distributorFormData.name}
// // // // //        onChange={handleDistributorInputChange}
// // // // //        required
// // // // //        fullWidth
// // // // //        sx={{ mb: 2 }}
// // // // //       />
// // // // //       <TextField
// // // // //        label="Email"
// // // // //        name="email"
// // // // //        value={distributorFormData.email}
// // // // //        onChange={handleDistributorInputChange}
// // // // //        required
// // // // //        fullWidth
// // // // //        sx={{ mb: 2 }}
// // // // //       />
// // // // //       <TextField
// // // // //        label="Password"
// // // // //        name="password"
// // // // //        type="password"
// // // // //        value={distributorFormData.password}
// // // // //        onChange={handleDistributorInputChange}
// // // // //        required
// // // // //        fullWidth
// // // // //        sx={{ mb: 2 }}
// // // // //       />
// // // // //       <Button
// // // // //        type="submit"
// // // // //        variant="contained"
// // // // //        color="primary"
// // // // //        fullWidth
// // // // //        disabled={distributorLoading}
// // // // //       >
// // // // //        {distributorLoading ? "Creating..." : "Create Distributor"}
// // // // //       </Button>
// // // // //      </Box>
// // // // //     </Box>
// // // // //    </Modal>

// // // // //    {/* Driver Modal */}
// // // // //    <Modal open={openDriverModal} onClose={() => setOpenDriverModal(false)}>
// // // // //     <Box
// // // // //      sx={{
// // // // //       p: 3,
// // // // //       maxWidth: 400,
// // // // //       margin: "auto",
// // // // //       mt: "20vh",
// // // // //       bgcolor: "background.paper",
// // // // //      }}
// // // // //     >
// // // // //      <Typography variant="h6" gutterBottom>
// // // // //       Register Driver
// // // // //      </Typography>
// // // // //      <Box component="form" onSubmit={handleDriverSubmit}>
// // // // //       <TextField
// // // // //        label="Driver Name"
// // // // //        name="driverName"
// // // // //        value={driverFormData.driverName}
// // // // //        onChange={handleDriverInputChange}
// // // // //        required
// // // // //        fullWidth
// // // // //        sx={{ mb: 2 }}
// // // // //       />
// // // // //       <TextField
// // // // //        label="Mobile"
// // // // //        name="mobile"
// // // // //        value={driverFormData.mobile}
// // // // //        onChange={handleDriverInputChange}
// // // // //        required
// // // // //        fullWidth
// // // // //        sx={{ mb: 2 }}
// // // // //       />
// // // // //       <TextField
// // // // //        label="Account Number"
// // // // //        name="accountNumber"
// // // // //        value={driverFormData.accountNumber}
// // // // //        onChange={handleDriverInputChange}
// // // // //        required
// // // // //        fullWidth
// // // // //        sx={{ mb: 2 }}
// // // // //       />
// // // // //       <Button
// // // // //        type="submit"
// // // // //        variant="contained"
// // // // //        color="primary"
// // // // //        fullWidth
// // // // //        disabled={driverLoading}
// // // // //       >
// // // // //        {driverLoading ? "Adding..." : "Add Driver"}
// // // // //       </Button>
// // // // //      </Box>
// // // // //     </Box>
// // // // //    </Modal>
// // // // //   </Box>
// // // // //  );
// // // // // };

// // // // // export default ManageDistributors;

// // // // // // import React, { useState, useEffect } from 'react';
// // // // // // import { Box, Button, TextField, Typography } from '@mui/material';
// // // // // // import { DataGrid } from '@mui/x-data-grid';
// // // // // // import axios from 'axios';

// // // // // // const ManageDistributors = () => {
// // // // // //   // State for managing distributors
// // // // // //   const [distributors, setDistributors] = useState([]);
// // // // // //   const [distributorFormData, setDistributorFormData] = useState({ name: '', email: '', password: '' });
// // // // // //   const [distributorLoading, setDistributorLoading] = useState(false);

// // // // // //   // State for managing drivers
// // // // // //   const [drivers, setDrivers] = useState([]);
// // // // // //   const [driverFormData, setDriverFormData] = useState({ driverName: '', mobile: '', accountNumber: '' });
// // // // // //   const [driverLoading, setDriverLoading] = useState(false);

// // // // // //   useEffect(() => {
// // // // // //     fetchDistributors();
// // // // // //     fetchDrivers();
// // // // // //   }, []);

// // // // // //   // Fetch distributors
// // // // // //   const fetchDistributors = async () => {
// // // // // //     try {
// // // // // //       const res = await axios.get('https://api.janatarides.com/api/admin/distributors', {
// // // // // //         headers: {
// // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // //         },
// // // // // //       });
// // // // // //       setDistributors(res.data);
// // // // // //     } catch (err) {
// // // // // //       console.error('Error fetching distributors:', err);
// // // // // //     }
// // // // // //   };

// // // // // //   // Fetch drivers
// // // // // //   const fetchDrivers = async () => {
// // // // // //     try {
// // // // // //       const res = await axios.get('https://api.janatarides.com/api/drivers', {
// // // // // //         headers: {
// // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // //         },
// // // // // //       });
// // // // // //       setDrivers(res.data);
// // // // // //     } catch (err) {
// // // // // //       console.error('Error fetching drivers:', err);
// // // // // //     }
// // // // // //   };

// // // // // //   // Handle input change for distributor form
// // // // // //   const handleDistributorInputChange = (e) => {
// // // // // //     const { name, value } = e.target;
// // // // // //     setDistributorFormData({ ...distributorFormData, [name]: value });
// // // // // //   };

// // // // // //   // Handle input change for driver form
// // // // // //   const handleDriverInputChange = (e) => {
// // // // // //     const { name, value } = e.target;
// // // // // //     setDriverFormData({ ...driverFormData, [name]: value });
// // // // // //   };

// // // // // //   // Handle form submission for distributor
// // // // // //   const handleDistributorSubmit = async (e) => {
// // // // // //     e.preventDefault();
// // // // // //     setDistributorLoading(true);
// // // // // //     try {
// // // // // //       const res = await axios.post('https://api.janatarides.com/api/admin/create-distributor', distributorFormData, {
// // // // // //         headers: {
// // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // //         },
// // // // // //       });
// // // // // //       setDistributors([...distributors, res.data.user]);
// // // // // //       setDistributorFormData({ name: '', email: '', password: '' });
// // // // // //     } catch (err) {
// // // // // //       console.error('Error creating distributor:', err);
// // // // // //     }
// // // // // //     setDistributorLoading(false);
// // // // // //   };

// // // // // //   // Handle form submission for driver
// // // // // //   const handleDriverSubmit = async (e) => {
// // // // // //     e.preventDefault();
// // // // // //     setDriverLoading(true);
// // // // // //     try {
// // // // // //       const res = await axios.post('https://api.janatarides.com/api/drivers/add', driverFormData, {
// // // // // //         headers: {
// // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // //         },
// // // // // //       });
// // // // // //       setDrivers([...drivers, res.data.driver]);
// // // // // //       setDriverFormData({ driverName: '', mobile: '', accountNumber: '' });
// // // // // //     } catch (err) {
// // // // // //       console.error('Error creating driver:', err);
// // // // // //     }
// // // // // //     setDriverLoading(false);
// // // // // //   };

// // // // // //   // Handle driver approval
// // // // // //   const handleApproveDriver = async (id) => {
// // // // // //     try {
// // // // // //       await axios.put(`https://api.janatarides.com/api/admin/approve-driver/${id}`, {}, {
// // // // // //         headers: {
// // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // //         },
// // // // // //       });
// // // // // //       fetchDrivers(); // Refresh the driver list after approval
// // // // // //     } catch (err) {
// // // // // //       console.error('Error approving driver:', err);
// // // // // //     }
// // // // // //   };

// // // // // //   // Columns for the distributor DataGrid
// // // // // //   const distributorColumns = [
// // // // // //     { field: 'id', headerName: 'ID', width: 100 },
// // // // // //     { field: 'name', headerName: 'Name', width: 150 },
// // // // // //     { field: 'email', headerName: 'Email', width: 200 },
// // // // // //   ];

// // // // // //   // Columns for the driver DataGrid
// // // // // //   const driverColumns = [
// // // // // //     { field: 'id', headerName: 'ID', width: 100 },
// // // // // //     { field: 'driverName', headerName: 'Driver Name', width: 150 },
// // // // // //     { field: 'mobile', headerName: 'Mobile', width: 200 },
// // // // // //     { field: 'accountNumber', headerName: 'Account Number', width: 200 },
// // // // // //     { field: 'approved', headerName: 'Approved', width: 150, renderCell: (params) => (params.value ? 'Yes' : 'No') },
// // // // // //     {
// // // // // //       field: 'actions',
// // // // // //       headerName: 'Actions',
// // // // // //       width: 200,
// // // // // //       renderCell: (params) => (
// // // // // //         <Button
// // // // // //           variant="contained"
// // // // // //           color="primary"
// // // // // //           onClick={() => handleApproveDriver(params.row._id)}
// // // // // //           disabled={params.row.approved}
// // // // // //         >
// // // // // //           {params.row.approved ? 'Approved' : 'Approve'}
// // // // // //         </Button>
// // // // // //       ),
// // // // // //     },
// // // // // //   ];

// // // // // //   return (
// // // // // //     <Box sx={{ p: 3 }}>

// // // // // //       {/* Section for Managing Distributors */}
// // // // // //       <Box sx={{ mb: 5 }}>
// // // // // //         <Typography variant="h5" gutterBottom>
// // // // // //           Manage Distributors
// // // // // //         </Typography>
// // // // // //         <Box component="form" onSubmit={handleDistributorSubmit} sx={{ mb: 3 }}>
// // // // // //           <TextField
// // // // // //             label="Name"
// // // // // //             name="name"
// // // // // //             value={distributorFormData.name}
// // // // // //             onChange={handleDistributorInputChange}
// // // // // //             required
// // // // // //             fullWidth
// // // // // //             sx={{ mb: 2 }}
// // // // // //           />
// // // // // //           <TextField
// // // // // //             label="Email"
// // // // // //             name="email"
// // // // // //             value={distributorFormData.email}
// // // // // //             onChange={handleDistributorInputChange}
// // // // // //             required
// // // // // //             fullWidth
// // // // // //             sx={{ mb: 2 }}
// // // // // //           />
// // // // // //           <TextField
// // // // // //             label="Password"
// // // // // //             name="password"
// // // // // //             type="password"
// // // // // //             value={distributorFormData.password}
// // // // // //             onChange={handleDistributorInputChange}
// // // // // //             required
// // // // // //             fullWidth
// // // // // //             sx={{ mb: 2 }}
// // // // // //           />
// // // // // //           <Button type="submit" variant="contained" color="primary" fullWidth disabled={distributorLoading}>
// // // // // //             {distributorLoading ? 'Creating...' : 'Create Distributor'}
// // // // // //           </Button>
// // // // // //         </Box>

// // // // // //         <div style={{ height: 400, width: '100%' }}>
// // // // // //           <DataGrid
// // // // // //             rows={distributors.map((distributor, index) => ({ id: index + 1, ...distributor }))}
// // // // // //             columns={distributorColumns}
// // // // // //             pageSize={5}
// // // // // //           />
// // // // // //         </div>
// // // // // //       </Box>

// // // // // //       {/* Section for Managing Drivers */}
// // // // // //       <Box>
// // // // // //         <Typography variant="h5" gutterBottom>
// // // // // //           Manage Drivers
// // // // // //         </Typography>
// // // // // //         <Box component="form" onSubmit={handleDriverSubmit} sx={{ mb: 3 }}>
// // // // // //           <TextField
// // // // // //             label="Driver Name"
// // // // // //             name="driverName"
// // // // // //             value={driverFormData.driverName}
// // // // // //             onChange={handleDriverInputChange}
// // // // // //             required
// // // // // //             fullWidth
// // // // // //             sx={{ mb: 2 }}
// // // // // //           />
// // // // // //           <TextField
// // // // // //             label="Mobile"
// // // // // //             name="mobile"
// // // // // //             value={driverFormData.mobile}
// // // // // //             onChange={handleDriverInputChange}
// // // // // //             required
// // // // // //             fullWidth
// // // // // //             sx={{ mb: 2 }}
// // // // // //           />
// // // // // //           <TextField
// // // // // //             label="Account Number"
// // // // // //             name="accountNumber"
// // // // // //             value={driverFormData.accountNumber}
// // // // // //             onChange={handleDriverInputChange}
// // // // // //             required
// // // // // //             fullWidth
// // // // // //             sx={{ mb: 2 }}
// // // // // //           />
// // // // // //           <Button type="submit" variant="contained" color="primary" fullWidth disabled={driverLoading}>
// // // // // //             {driverLoading ? 'Adding...' : 'Add Driver'}
// // // // // //           </Button>
// // // // // //         </Box>

// // // // // //         <div style={{ height: 400, width: '100%' }}>
// // // // // //           <DataGrid
// // // // // //             rows={drivers.map((driver, index) => ({ id: index + 1, ...driver }))}
// // // // // //             columns={driverColumns}
// // // // // //             pageSize={5}
// // // // // //           />
// // // // // //         </div>
// // // // // //       </Box>
// // // // // //     </Box>
// // // // // //   );
// // // // // // };

// // // // // // export default ManageDistributors;

// // // // // // // import React, { useState, useEffect } from 'react';
// // // // // // // import { Box, Button, TextField, Typography } from '@mui/material';
// // // // // // // import { DataGrid } from '@mui/x-data-grid';
// // // // // // // import axios from 'axios';

// // // // // // // const ManageDrivers = () => {
// // // // // // //   const [drivers, setDrivers] = useState([]);
// // // // // // //   const [formData, setFormData] = useState({ driverName: '', mobile: '', accountNumber: '' });
// // // // // // //   const [loading, setLoading] = useState(false);

// // // // // // //   useEffect(() => {
// // // // // // //     fetchDrivers();
// // // // // // //   }, []);

// // // // // // //   const fetchDrivers = async () => {
// // // // // // //     try {
// // // // // // //       const res = await axios.get('https://api.janatarides.com/api/drivers', {
// // // // // // //         headers: {
// // // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // // //         },
// // // // // // //       });
// // // // // // //       setDrivers(res.data);
// // // // // // //     } catch (err) {
// // // // // // //       console.error('Error fetching drivers:', err);
// // // // // // //     }
// // // // // // //   };

// // // // // // //   const handleInputChange = (e) => {
// // // // // // //     const { name, value } = e.target;
// // // // // // //     setFormData({ ...formData, [name]: value });
// // // // // // //   };

// // // // // // //   const handleSubmit = async (e) => {
// // // // // // //     e.preventDefault();
// // // // // // //     setLoading(true);
// // // // // // //     try {
// // // // // // //       const res = await axios.post('https://api.janatarides.com/api/drivers/add', formData, {
// // // // // // //         headers: {
// // // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // // //         },
// // // // // // //       });
// // // // // // //       setDrivers([...drivers, res.data.driver]);
// // // // // // //       setFormData({ driverName: '', mobile: '', accountNumber: '' });
// // // // // // //     } catch (err) {
// // // // // // //       console.error('Error creating driver:', err);
// // // // // // //     }
// // // // // // //     setLoading(false);
// // // // // // //   };

// // // // // // //   const handleApprove = async (id) => {
// // // // // // //     try {
// // // // // // //       await axios.put(`https://api.janatarides.com/api/admin/approve-driver/${id}`, {}, {
// // // // // // //         headers: {
// // // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // // //         },
// // // // // // //       });
// // // // // // //       fetchDrivers(); // Refresh the driver list
// // // // // // //     } catch (err) {
// // // // // // //       console.error('Error approving driver:', err);
// // // // // // //     }
// // // // // // //   };

// // // // // // //   const columns = [
// // // // // // //     { field: 'id', headerName: 'ID', width: 100 },
// // // // // // //     { field: 'driverName', headerName: 'Driver Name', width: 150 },
// // // // // // //     { field: 'mobile', headerName: 'Mobile', width: 200 },
// // // // // // //     { field: 'accountNumber', headerName: 'Account Number', width: 200 },
// // // // // // //     { field: 'approved', headerName: 'Approved', width: 150, renderCell: (params) => (params.value ? 'Yes' : 'No') },
// // // // // // //     {
// // // // // // //       field: 'actions',
// // // // // // //       headerName: 'Actions',
// // // // // // //       width: 200,
// // // // // // //       renderCell: (params) => (
// // // // // // //         <Button
// // // // // // //           variant="contained"
// // // // // // //           color="primary"
// // // // // // //           onClick={() => handleApprove(params.row._id)}
// // // // // // //           disabled={params.row.approved}
// // // // // // //         >
// // // // // // //           {params.row.approved ? 'Approved' : 'Approve'}
// // // // // // //         </Button>
// // // // // // //       ),
// // // // // // //     },
// // // // // // //   ];

// // // // // // //   return (
// // // // // // //     <Box sx={{ p: 3 }}>
// // // // // // //       <Typography variant="h4" gutterBottom>
// // // // // // //         Manage Drivers
// // // // // // //       </Typography>

// // // // // // //       <Box component="form" onSubmit={handleSubmit} sx={{ mb: 3 }}>
// // // // // // //         <TextField
// // // // // // //           label="Driver Name"
// // // // // // //           name="driverName"
// // // // // // //           value={formData.driverName}
// // // // // // //           onChange={handleInputChange}
// // // // // // //           required
// // // // // // //           fullWidth
// // // // // // //           sx={{ mb: 2 }}
// // // // // // //         />
// // // // // // //         <TextField
// // // // // // //           label="Mobile"
// // // // // // //           name="mobile"
// // // // // // //           value={formData.mobile}
// // // // // // //           onChange={handleInputChange}
// // // // // // //           required
// // // // // // //           fullWidth
// // // // // // //           sx={{ mb: 2 }}
// // // // // // //         />
// // // // // // //         <TextField
// // // // // // //           label="Account Number"
// // // // // // //           name="accountNumber"
// // // // // // //           value={formData.accountNumber}
// // // // // // //           onChange={handleInputChange}
// // // // // // //           required
// // // // // // //           fullWidth
// // // // // // //           sx={{ mb: 2 }}
// // // // // // //         />
// // // // // // //         <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
// // // // // // //           {loading ? 'Adding...' : 'Add Driver'}
// // // // // // //         </Button>
// // // // // // //       </Box>

// // // // // // //       <div style={{ height: 400, width: '100%' }}>
// // // // // // //         <DataGrid rows={drivers.map((driver, index) => ({ id: index + 1, ...driver }))} columns={columns} pageSize={5} />
// // // // // // //       </div>
// // // // // // //     </Box>
// // // // // // //   );
// // // // // // // };

// // // // // // // export default ManageDrivers;

// // // // // // // // import React, { useState, useEffect } from 'react';
// // // // // // // // import { Box, Button, TextField, Typography } from '@mui/material';
// // // // // // // // import { DataGrid } from '@mui/x-data-grid';
// // // // // // // // import axios from 'axios';

// // // // // // // // const ManageDistributors = () => {
// // // // // // // //   const [distributors, setDistributors] = useState([]);
// // // // // // // //   const [formData, setFormData] = useState({ name: '', email: '', password: '' });
// // // // // // // //   const [loading, setLoading] = useState(false);

// // // // // // // //   useEffect(() => {
// // // // // // // //     fetchDistributors();
// // // // // // // //   }, []);

// // // // // // // //   const fetchDistributors = async () => {
// // // // // // // //     try {
// // // // // // // //       const res = await axios.get('https://api.janatarides.com/api/admin/distributors', {
// // // // // // // //         headers: {
// // // // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // // // //         },
// // // // // // // //       });
// // // // // // // //       setDistributors(res.data);
// // // // // // // //     } catch (err) {
// // // // // // // //       console.error('Error fetching distributors:', err);
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const handleInputChange = (e) => {
// // // // // // // //     const { name, value } = e.target;
// // // // // // // //     setFormData({ ...formData, [name]: value });
// // // // // // // //   };

// // // // // // // //   const handleSubmit = async (e) => {
// // // // // // // //     e.preventDefault();
// // // // // // // //     setLoading(true);
// // // // // // // //     try {
// // // // // // // //       const res = await axios.post('https://api.janatarides.com/api/admin/create-distributor', formData, {
// // // // // // // //         headers: {
// // // // // // // //           'x-auth-token': localStorage.getItem('token'),
// // // // // // // //         },
// // // // // // // //       });
// // // // // // // //       setDistributors([...distributors, res.data.user]);
// // // // // // // //       setFormData({ name: '', email: '', password: '' });
// // // // // // // //     } catch (err) {
// // // // // // // //       console.error('Error creating distributor:', err);
// // // // // // // //     }
// // // // // // // //     setLoading(false);
// // // // // // // //   };

// // // // // // // //   const columns = [
// // // // // // // //     { field: 'id', headerName: 'ID', width: 100 },
// // // // // // // //     { field: 'name', headerName: 'Name', width: 150 },
// // // // // // // //     { field: 'email', headerName: 'Email', width: 200 },
// // // // // // // //   ];

// // // // // // // //   return (
// // // // // // // //     <Box sx={{ p: 3 }}>
// // // // // // // //       <Typography variant="h4" gutterBottom>
// // // // // // // //         Manage Distributors
// // // // // // // //       </Typography>

// // // // // // // //       <Box component="form" onSubmit={handleSubmit} sx={{ mb: 3 }}>
// // // // // // // //         <TextField
// // // // // // // //           label="Name"
// // // // // // // //           name="name"
// // // // // // // //           value={formData.name}
// // // // // // // //           onChange={handleInputChange}
// // // // // // // //           required
// // // // // // // //           fullWidth
// // // // // // // //           sx={{ mb: 2 }}
// // // // // // // //         />
// // // // // // // //         <TextField
// // // // // // // //           label="Email"
// // // // // // // //           name="email"
// // // // // // // //           value={formData.email}
// // // // // // // //           onChange={handleInputChange}
// // // // // // // //           required
// // // // // // // //           fullWidth
// // // // // // // //           sx={{ mb: 2 }}
// // // // // // // //         />
// // // // // // // //         <TextField
// // // // // // // //           label="Password"
// // // // // // // //           name="password"
// // // // // // // //           type="password"
// // // // // // // //           value={formData.password}
// // // // // // // //           onChange={handleInputChange}
// // // // // // // //           required
// // // // // // // //           fullWidth
// // // // // // // //           sx={{ mb: 2 }}
// // // // // // // //         />
// // // // // // // //         <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
// // // // // // // //           {loading ? 'Creating...' : 'Create Distributor'}
// // // // // // // //         </Button>
// // // // // // // //       </Box>

// // // // // // // //       <div style={{ height: 400, width: '100%' }}>
// // // // // // // //         <DataGrid rows={distributors.map((distributor, index) => ({ id: index + 1, ...distributor }))} columns={columns} pageSize={5} />
// // // // // // // //       </div>
// // // // // // // //     </Box>
// // // // // // // //   );
// // // // // // // // };

// // // // // // // // export default ManageDistributors;
