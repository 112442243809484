// src/components/DistributorForm.js

import React, { useState } from "react";
import { Box, Button, Typography, Modal, TextField, Grid } from "@mui/material";
import axios from "axios";

const DistributorFormAdmin = ({ open, onClose, onDistributorAdded }) => {
 const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
  password: "",
 });

 const [error, setError] = useState("");

 const handleChange = (e) => {
  setFormData({
   ...formData,
   [e.target.name]: e.target.value,
  });
 };

 const handleSubmit = async (e) => {
  e.preventDefault();
  try {
   const res = await axios.post(
    "https://api.janatarides.com/api/admin/create-distributor",
    // "http://localhost:9999/api/admin/create-distributor",
    formData,
    {
     headers: {
      "x-auth-token": localStorage.getItem("token"),
     },
    }
   );
   setFormData({
    name: "",
    email: "",
    mobile: "",
    password: "",
   })
   if (res.data) {
    onDistributorAdded(); // To refresh the distributor list
    onClose(); // Close the modal
   }
  } catch (err) {
   setError(err.response.data.msg || "An error occurred");
  }
 };

 return (
  <Modal open={open} onClose={onClose}>
   <Box
    sx={{
     position: "absolute",
     top: "50%",
     left: "50%",
     transform: "translate(-50%, -50%)",
     width: "400px",
     bgcolor: "background.paper",
     p: 4,
     boxShadow: 24,
     borderRadius: 2,
    }}
   >
    <Typography variant="h6" component="h2" gutterBottom>
     Add Distributor
    </Typography>
    {error && <Typography color="error">{error}</Typography>}
    <form onSubmit={handleSubmit}>
     <Grid container spacing={2}>
      <Grid item xs={12}>
       <TextField
        label="Name"
        name="name"
        variant="outlined"
        fullWidth
        required
        value={formData.name}
        onChange={handleChange}
       />
      </Grid>
      <Grid item xs={12}>
       <TextField
        label="Email"
        name="email"
        variant="outlined"
        type="email"
        fullWidth
        required
        value={formData.email}
        onChange={handleChange}
       />
      </Grid>
      <Grid item xs={12}>
       <TextField
        label="Mobile"
        name="mobile"
        variant="outlined"
        fullWidth
        required
        value={formData.mobile}
        onChange={handleChange}
       />
      </Grid>
      <Grid item xs={12}>
       <TextField
        label="Password"
        name="password"
        variant="outlined"
        type="password"
        fullWidth
        required
        value={formData.password}
        onChange={handleChange}
       />
      </Grid>
      <Grid item xs={12} textAlign="center">
       <Button type="submit" variant="contained" color="primary">
        Save
       </Button>
      </Grid>
     </Grid>
    </form>
   </Box>
  </Modal>
 );
};

export default DistributorFormAdmin;
