import React, { useState } from "react";
import {
 Box,
 Fab,
 Container,
 Modal,
 IconButton,
 Typography,
 Divider,
 useMediaQuery,
 useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataGridComponent from "../data/DataGridComponent";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DistributorForm from "./DistributorForm";

const Cars = () => {
 const [openModal, setOpenModal] = useState(false);
 const [selectedDriver, setSelectedDriver] = useState(null);

 const theme = useTheme();
 const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

 const handleOpenModal = () => {
  setSelectedDriver(null); // Clear selected driver when adding a new one
  setOpenModal(true);
 };

 const handleCloseModal = () => setOpenModal(false);

 return (
  <Box
   sx={{
    width: "100%",
    padding: 2,
    // border: "1px solid red",
   }}
  >
   <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    mb={3}
   >
    <Box display="flex" alignItems="center">
     <DirectionsCarIcon sx={{ fontSize: 40, color: "#1976d2", mr: 1 }} />
     <Typography variant={isMobile ? "h6" : "h4"} color="text.primary">
      Distributor Total Cars
     </Typography>
    </Box>
    <Fab
     color="primary"
     aria-label="add"
     onClick={handleOpenModal}
     sx={{
      boxShadow: 3,
      "&:hover": {
       backgroundColor: "#1565c0",
      },
     }}
    >
     <AddIcon />
    </Fab>
   </Box>
   <Divider sx={{ mb: 2 }} />
   <Box display="flex" justifyContent="center" p={2}>
    <DataGridComponent />
   </Box>

   <Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
   >
    <Box
     sx={{
      width: isMobile ? "100%" : "85%",
      bgcolor: "background.paper",
      borderRadius: 1,
      boxShadow: 24,
      p: 2,
      m: "50px auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      maxHeight: "90vh",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
       width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
       backgroundColor: "#aaa",
       borderRadius: "10px",
      },
      "-ms-overflow-style": "none", // IE and Edge
      "scrollbar-width": "none", // Firefox
     }}
    >
     <IconButton
      edge="end"
      color="inherit"
      onClick={handleCloseModal}
      aria-label="close"
      sx={{
       position: "absolute",
       top: 8,
       right: 8,
       color: "#d32f2f",
      }}
     >
      <CloseIcon />
     </IconButton>
     <DistributorForm onClose={handleCloseModal} />
    </Box>
   </Modal>
  </Box>
 );
};

export default Cars;

// import React, { useState } from "react";
// import {
//  Box,
//  Fab,
//  Container,
//  Modal,
//  IconButton,
//  Typography,
//  Divider,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import DataGridComponent from "../data/DataGridComponent";
// import CloseIcon from "@mui/icons-material/Close";
// import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
// import DistributorForm from "./DistributorForm";
// const modalStyle = {
//  width: "85%",
//  bgcolor: "background.paper",
//  borderRadius: 1,
//  boxShadow: 24,
//  p: 4,
//  margin: "50px auto",
//  display: "flex",
//  justifyContent: "center",
//  alignItems: "center",
//  position: "relative",
// };
// const Cars = () => {
//  const [openModal, setOpenModal] = useState(false);
//  const [selectedDriver, setSelectedDriver] = useState(null);

//  const handleOpenModal = () => {
//   setSelectedDriver(null); // Clear selected driver when adding a new one
//   setOpenModal(true);
//  };

//  const handleCloseModal = () => setOpenModal(false);

//  return (
//   <Container maxWidth={false} sx={{ width: "100%", padding: 2 }}>
//    <Box display="flex" alignItems="center" mb={3}>
//     <DirectionsCarIcon sx={{ fontSize: 40, color: "#1976d2", mr: 1 }} />
//     <Typography variant="h4" color="text.primary">
//      Distributor Total Cars
//     </Typography>
//    </Box>
//    <Divider sx={{ mb: 2 }} />
//    <Box display="flex" justifyContent="flex-end" p={2}>
//     <Fab
//      color="primary"
//      aria-label="add"
//      onClick={handleOpenModal}
//      sx={{
//       boxShadow: 3,
//       "&:hover": {
//        backgroundColor: "#1565c0",
//       },
//      }}
//     >
//      <AddIcon />
//     </Fab>
//    </Box>
//    <DataGridComponent />

//    <Modal
//     open={openModal}
//     onClose={handleCloseModal}
//     aria-labelledby="modal-title"
//     aria-describedby="modal-description"
//    >
//     <Box sx={modalStyle}>
//      <IconButton
//       edge="end"
//       color="inherit"
//       onClick={handleCloseModal}
//       aria-label="close"
//       sx={{
//        position: "absolute",
//        top: 8,
//        right: 8,
//        color: "#d32f2f",
//       }}
//      >
//       <CloseIcon />
//      </IconButton>
//      <DistributorForm onClose={handleCloseModal} />
//     </Box>
//    </Modal>
//   </Container>
//  );
// };

// export default Cars;
