// src/firebase.js
import { initializeApp } from "firebase/app";
import {
 getMessaging,
 getToken as getFirebaseToken,
 onMessage as onFirebaseMessage,
} from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
 apiKey: "AIzaSyB8Jf1lqrYove2KimJjbtawJA-fhMLYwBo",
 authDomain: "janata-rides.firebaseapp.com",
 projectId: "janata-rides",
 storageBucket: "janata-rides.appspot.com",
 messagingSenderId: "794769511215",
 appId: "1:794769511215:web:3566a6bda4d3658a0fc7da",
 measurementId: "G-JNM81MGMNC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

// Export messaging and required functions
export {
 messaging,
 getFirebaseToken as getToken,
 onFirebaseMessage as onMessage,
};

// // src/firebase.js
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// // Your web app's Firebase configuration
// const firebaseConfig = {
//  apiKey: "AIzaSyB8Jf1lqrYove2KimJjbtawJA-fhMLYwBo",
//  authDomain: "janata-rides.firebaseapp.com",
//  projectId: "janata-rides",
//  storageBucket: "janata-rides.appspot.com",
//  messagingSenderId: "794769511215",
//  appId: "1:794769511215:web:3566a6bda4d3658a0fc7da",
//  measurementId: "G-JNM81MGMNC",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// // Initialize Firebase Messaging
// const messaging = getMessaging(app);
// getToken(messaging, { vapidKey: "YOUR_PUBLIC_VAPID_KEY" })
//  .then((currentToken) => {
//   if (currentToken) {
//    console.log("FCM Token:", currentToken);
//    // Send the token to your server to store it and use it later to send notifications
//   } else {
//    console.log(
//     "No registration token available. Request permission to generate one."
//    );
//   }
//  })
//  .catch((err) => {
//   console.log("An error occurred while retrieving token. ", err);
//  });
// export { messaging };
