import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
 Box,
 Grid,
 Typography,
 Avatar,
 Paper,
 TextField,
 Button,
} from "@mui/material";
import AWS from "aws-sdk";
import axios from "axios";
import { loadUser } from "../../redux/auth/action";

const ProfilePage = () => {
 const dispatch = useDispatch();
 const { user, loading, isAuthenticated } = useSelector((state) => state.auth);
 const [isEditing, setIsEditing] = useState(false);
 const [userData, setUserData] = useState(null);
 const [uploadedFileUrl, setUploadedFileUrl] = useState({});
 const [isUploading, setIsUploading] = useState(false);
 const [error, setError] = useState(null);

 useEffect(() => {
  if (isAuthenticated && !user) {
   dispatch(loadUser(localStorage.getItem("token")));
  } else if (user) {
   setUserData(user);
  }
 }, [dispatch, isAuthenticated, user]);

 const handleChange = (e) => {
  setUserData({ ...userData, [e.target.name]: e.target.value });
 };

 const uploadFile = async (file, fieldName) => {
  setIsUploading(true);
  setError(null);

  const S3 = new AWS.S3({
   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
   secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
   region: process.env.REACT_APP_AWS_REGION,
  });

  const params = {
   Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
   Key: `${Date.now()}-${file.name}`,
   Body: file,
   ContentType: file.type,
  };

  try {
   const uploadResult = await S3.upload(params).promise();
   setUploadedFileUrl((prevState) => ({
    ...prevState,
    [fieldName]: uploadResult.Location,
   }));
  } catch (err) {
   setError(err.message);
  } finally {
   setIsUploading(false);
  }
 };

 useEffect(() => {
  if (uploadedFileUrl) {
   setUserData((prevState) => ({
    ...prevState,
    ...uploadedFileUrl,
   }));
  }
 }, [uploadedFileUrl]);

 const handleFileChange = async (e) => {
  const { name, files } = e.target;
  const file = files[0];
  if (file) {
   await uploadFile(file, name);
  }
 };

 const toggleEdit = () => {
  setIsEditing(!isEditing);
 };

 const handleSave = async () => {
  setIsEditing(false);

  try {
   await axios.put(
    "https://api.janatarides.com/api/distributors/update-profile",
    // "http://localhost:9999/api/distributors/update-profile",
    userData,
    {
     headers: {
      "x-auth-token": localStorage.getItem("token"),
     },
    }
   );
   console.log("User data updated successfully");
  } catch (error) {
   console.error("Error updating user data:", error);
  }
 };

 if (loading || !userData) {
  return <Typography>Loading...</Typography>;
 }

 return (
  <Box sx={{ p: 3 }}>
   <Paper sx={{ p: 3 }}>
    <Grid container spacing={2}>
     <Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
      <Avatar
       src={userData.profile || "default_avatar.png"}
       alt={userData.name}
       sx={{
        width: { xs: 100, md: 150 },
        height: { xs: 100, md: 150 },
        margin: "auto",
       }}
      />
      {isEditing && (
       <Box sx={{ mt: 2 }}>
        <Button variant="outlined" component="label" sx={{ mt: 1 }}>
         Change Profile Picture
         <input
          type="file"
          name="profile"
          hidden
          onChange={handleFileChange}
         />
        </Button>
       </Box>
      )}
     </Grid>
     <Grid item xs={12} md={9}>
      <Typography variant="h5">
       {isEditing ? (
        <TextField
         name="name"
         value={userData.name}
         onChange={handleChange}
         label="Name"
         variant="outlined"
         size="small"
         sx={{ width: "100%" }}
        />
       ) : (
        userData.name
       )}
      </Typography>
     </Grid>
    </Grid>
    <Box sx={{ mt: 4 }}>
     <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Email
       </Typography>
       {isEditing ? (
        <TextField
         name="email"
         value={userData.email}
         onChange={handleChange}
         variant="outlined"
         size="small"
         fullWidth
         disabled
        />
       ) : (
        <Typography variant="body1">{userData.email}</Typography>
       )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Mobile
       </Typography>
       {isEditing ? (
        <TextField
         name="mobile"
         value={userData.mobile || ""}
         onChange={handleChange}
         variant="outlined"
         size="small"
         fullWidth
         disabled

        />
       ) : (
        <Typography variant="body1">{userData.mobile}</Typography>
       )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Address
       </Typography>
       {isEditing ? (
        <TextField
         name="address"
         value={userData.address || ""}
         onChange={handleChange}
         variant="outlined"
         size="small"
         fullWidth
        />
       ) : (
        <Typography variant="body1">{userData.address}</Typography>
       )}
      </Grid>



      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Account Holder Name
       </Typography>
       {isEditing ? (
        <TextField
         name="account_holder_name"
         value={userData.account_holder_name || ""}
         onChange={handleChange}
         variant="outlined"
         size="small"
         fullWidth
        />
       ) : (
        <Typography variant="body1">{userData.account_holder_name}</Typography>
       )}
      </Grid>






      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Bank Name
       </Typography>
       {isEditing ? (
        <TextField
         name="bank_name"
         value={userData.bank_name || ""}
         onChange={handleChange}
         variant="outlined"
         size="small"
         fullWidth
        />
       ) : (
        <Typography variant="body1">{userData.bank_name}</Typography>
       )}
      </Grid>






      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Account Number
       </Typography>
       {isEditing ? (
        <TextField
         name="account_no"
         value={userData.account_no || ""}
         onChange={handleChange}
         variant="outlined"
         size="small"
         fullWidth
        />
       ) : (
        <Typography variant="body1">{userData.account_no}</Typography>
       )}
      </Grid>





      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        IFSC Code
       </Typography>
       {isEditing ? (
        <TextField
         name="ifsc_code"
         value={userData.ifsc_code || ""}
         onChange={handleChange}
         variant="outlined"
         size="small"
         fullWidth
        />
       ) : (
        <Typography variant="body1">{userData.ifsc_code}</Typography>
       )}
      </Grid>

      {/* Document 1 */}
      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Cheque Book
       </Typography>
       {isEditing ? (
        <TextField
         name="chequeUrl"
         type="file"
         onChange={handleFileChange}
         variant="outlined"
         size="small"
         fullWidth
        />
       ) : userData.chequeUrl ? (
        <a
         href={userData.chequeUrl}
         target="_blank"
         rel="noopener noreferrer"
        >
         View   Cheque Book
        </a>
       ) : (
        <Typography variant="body2" color="textSecondary">
         Not Uploaded
        </Typography>
       )}
      </Grid>

      {/* Document 2 */}
      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Pass Book
       </Typography>
       {isEditing ? (
        <TextField
         name="passbookUrl"
         type="file"
         onChange={handleFileChange}
         variant="outlined"
         size="small"
         fullWidth
        />
       ) : userData.passbookUrl ? (
        <a
         href={userData.passbookUrl}
         target="_blank"
         rel="noopener noreferrer"
        >
         View Pass Book
        </a>
       ) : (
        <Typography variant="body2" color="textSecondary">
         Not Uploaded
        </Typography>
       )}
      </Grid>

      {/* Document 3 */}
      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Aadhar FrontEnd
       </Typography>
       {isEditing ? (
        <TextField
         name="aadhar_frontendUrl"
         type="file"
         onChange={handleFileChange}
         variant="outlined"
         size="small"
         fullWidth
        />
       ) : userData.aadhar_frontendUrl ? (
        <a
         href={userData.aadhar_frontendUrl}
         target="_blank"
         rel="noopener noreferrer"
        >
         View Aadhar FrontEnd
        </a>
       ) : (
        <Typography variant="body2" color="textSecondary">
         Not Uploaded
        </Typography>
       )}
      </Grid>

      {/* Document 4 */}
      <Grid item xs={12} sm={6} md={4}>
       <Typography variant="body2" color="textSecondary">
        Aadhar BackEnd
       </Typography>
       {isEditing ? (
        <TextField
         name="aadhar_backendUrl"
         type="file"
         onChange={handleFileChange}
         variant="outlined"
         size="small"
         fullWidth
        />
       ) : userData.aadhar_backendUrl ? (
        <a
         href={userData.aadhar_backendUrl}
         target="_blank"
         rel="noopener noreferrer"
        >
         View Aadhar BackEnd
        </a>
       ) : (
        <Typography variant="body2" color="textSecondary">
         Not Uploaded
        </Typography>
       )}
      </Grid>
     </Grid>
    </Box>
    <Box sx={{ mt: 3, textAlign: "center" }}>
     {isEditing ? (
      <>
       <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ mr: 2 }}
       >
        Save
       </Button>
       <Button variant="outlined" onClick={toggleEdit}>
        Cancel
       </Button>
      </>
     ) : (
      <Button variant="contained" onClick={toggleEdit}>
       Edit Profile
      </Button>
     )}
     {isUploading && <Typography>Uploading...</Typography>}
     {error && <Typography color="error">{error}</Typography>}
    </Box>
   </Paper>
  </Box>
 );
};

export default ProfilePage;
