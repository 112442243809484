import React, { useEffect } from "react";
import { Box, Typography, Paper, Grid, Card, CardContent } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import {
 LineChart,
 Line,
 CartesianGrid,
 XAxis,
 YAxis,
 Tooltip,
 Legend,
 ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDrivers } from "../redux/driver/action";

const data = [
 { name: "Jan", thisYear: 4000, lastYear: 2400 },
 { name: "Feb", thisYear: 3000, lastYear: 1398 },
 // Add more data points as needed
];

const Dashboard = () => {
 const dispatch = useDispatch();
 const navigate = useNavigate(); // Use the navigate hook
 const { drivers } = useSelector((state) => state.driver);
 useEffect(() => {
  dispatch(fetchDrivers());
 }, [dispatch]);
 console.log("drivers:", drivers);
 const cardData = [
  {
   title: "Revenue",
   value: "₹123,456",
   change: "+15% increase",
   color: "#1976d2",
   icon: <MonetizationOnIcon sx={{ fontSize: 40 }} />,
   changeColor: "green",
   route: "/distributer/statement", // Route to navigate when this card is clicked
  },
  {
   title: "Total Cars",
   value: drivers?.length,
   change: "+5% increase",
   color: "#388e3c",
   icon: <DirectionsCarIcon sx={{ fontSize: 40 }} />,
   changeColor: "green",
   route: "/distributer/cars", // Route to navigate when this card is clicked
  },
  {
   title: "This Month Added Cars",
   value: "0",
   change: "-5% decrease",
   color: "#d32f2f",
   icon: <TrendingDownIcon sx={{ fontSize: 40 }} />,
   changeColor: "red",
   route: "/distributer/cars", // Route to navigate when this card is clicked
  },
  // Add more cards if needed
 ];

 return (
  <Box>
   <Typography variant="h4" sx={{ padding: "20px" }}>
    Distributor Panel
   </Typography>
   <Grid container spacing={3}>
    {cardData.map((card, index) => (
     <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
      <Card
       sx={{
        mb: 3,
        boxShadow: 3,
        backgroundColor: card.color,
        color: "white",
        textAlign: "center",
        cursor: "pointer", // Add cursor pointer to indicate clickability
       }}
       onClick={() => navigate(card.route)} // Navigate to the route on card click
      >
       <CardContent>
        {card.icon}
        <Typography variant="h5" sx={{ paddingTop: "10px" }}>
         {card.title}
        </Typography>
        <Typography variant="h4" sx={{ paddingTop: "10px" }}>
         {card.value}
        </Typography>
        <Typography
         variant="body2"
         sx={{ paddingTop: "5px", color: card.changeColor }}
        >
         {card.change}
        </Typography>
       </CardContent>
      </Card>
     </Grid>
    ))}
   </Grid>

   {/* Line Chart */}
   <Box mt={4}>
    <Paper elevation={3} sx={{ padding: 2 }}>
     <Typography variant="h6" textAlign="center" mb={2}>
      Yearly Revenue Comparison
     </Typography>
     <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}>
       <CartesianGrid stroke="#e0e0e0" strokeDasharray="5 5" />
       <XAxis dataKey="name" />
       <YAxis />
       <Tooltip />
       <Legend verticalAlign="top" height={36} />
       <Line
        type="monotone"
        dataKey="thisYear"
        stroke="#8884d8"
        strokeWidth={2}
       />
       <Line
        type="monotone"
        dataKey="lastYear"
        stroke="#82ca9d"
        strokeWidth={2}
       />
      </LineChart>
     </ResponsiveContainer>
    </Paper>
   </Box>
  </Box>
 );
};

export default Dashboard;

// import React from "react";
// import { Box, Typography, Paper, Grid, Card, CardContent } from "@mui/material";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
// import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
// import TrendingDownIcon from "@mui/icons-material/TrendingDown";
// import {
//  LineChart,
//  Line,
//  CartesianGrid,
//  XAxis,
//  YAxis,
//  Tooltip,
//  Legend,
//  ResponsiveContainer,
// } from "recharts";
// import { useNavigate } from "react-router-dom";

// const data = [
//  { name: "Jan", thisYear: 4000, lastYear: 2400 },
//  { name: "Feb", thisYear: 3000, lastYear: 1398 },
//  // Add more data points as needed
// ];

// const cardData = [
//  {
//   title: "Revenue",
//   value: "$123,456",
//   change: "+15% increase",
//   color: "#1976d2",
//   icon: <MonetizationOnIcon sx={{ fontSize: 40 }} />,
//   changeColor: "green",
//  },
//  {
//   title: "Total Cars",
//   value: "1,234",
//   change: "+5% increase",
//   color: "#388e3c",
//   icon: <DirectionsCarIcon sx={{ fontSize: 40 }} />,
//   changeColor: "green",
//  },
//  {
//   title: "This Month Added Cars",
//   value: "56",
//   change: "-5% decrease",
//   color: "#d32f2f",
//   icon: <TrendingDownIcon sx={{ fontSize: 40 }} />,
//   changeColor: "red",
//  },
//  // Add more cards if needed
// ];

// const Dashboard = () => {
//     const navigate=useNavigate()
//  return (
//   <Box>
//    <Typography variant="h4" sx={{ padding: "20px" }}>
//     Distributor Panel
//    </Typography>
//    <Grid container spacing={3}>
//     {cardData.map((card, index) => (
//      <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
//       <Card
//        sx={{
//         mb: 3,
//         boxShadow: 3,
//         backgroundColor: card.color,
//         color: "white",
//         textAlign: "center",
//        }}
//       >
//        <CardContent>
//         {card.icon}
//         <Typography variant="h5" sx={{ paddingTop: "10px" }}>
//          {card.title}
//         </Typography>
//         <Typography variant="h4" sx={{ paddingTop: "10px" }}>
//          {card.value}
//         </Typography>
//         <Typography
//          variant="body2"
//          sx={{ paddingTop: "5px", color: card.changeColor }}
//         >
//          {card.change}
//         </Typography>
//        </CardContent>
//       </Card>
//      </Grid>
//     ))}
//    </Grid>

//    {/* Line Chart */}
//    <Box mt={4}>
//     <Paper elevation={3} sx={{ padding: 2 }}>
//      <Typography variant="h6" textAlign="center" mb={2}>
//       Yearly Revenue Comparison
//      </Typography>
//      <ResponsiveContainer width="100%" height={400}>
//       <LineChart data={data}>
//        <CartesianGrid stroke="#e0e0e0" strokeDasharray="5 5" />
//        <XAxis dataKey="name" />
//        <YAxis />
//        <Tooltip />
//        <Legend verticalAlign="top" height={36} />
//        <Line
//         type="monotone"
//         dataKey="thisYear"
//         stroke="#8884d8"
//         strokeWidth={2}
//        />
//        <Line
//         type="monotone"
//         dataKey="lastYear"
//         stroke="#82ca9d"
//         strokeWidth={2}
//        />
//       </LineChart>
//      </ResponsiveContainer>
//     </Paper>
//    </Box>
//   </Box>
//  );
// };

// export default Dashboard;
