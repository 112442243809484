// import React, { useEffect, useState, useRef } from "react";
// import { useForm } from "react-hook-form";
// import {
//  TextField,
//  Button,
//  Grid,
//  Typography,
//  Box,
//  CircularProgress,
//  IconButton,
//  FormControlLabel,
//  Checkbox,
//  Paper,
//  Divider,
//  FormGroup,
//  Dialog,
//  DialogActions,
//  DialogContent,
//  DialogTitle,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import AWS from "aws-sdk";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import { useDispatch } from "react-redux";
// import { addDriver, updateDriver } from "../../redux/driver/action";
// import Webcam from "react-webcam";

// // Service types
// const serviceTypes = [
//  "Astha Rides",
//  "Self Rides",
//  "Sharing Rides",
//  "Intercity Service",
//  "Out of station Rides",
//  "Hills station Rides",
// ];

// const statusOptions = ["Online", "Offline"];

// // Styled checkbox
// const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
//  color: theme.palette.primary.main,
//  "&.Mui-checked": {
//   color: theme.palette.primary.main,
//  },
// }));

// const videoConstraints = {
//  width: 300,
//  height: 300,
//  facingMode: "user",
// };

// const DistributorForm = ({ isEditMode = false, initialData = {}, onClose }) => {
//  const dispatch = useDispatch();
//  const { register, handleSubmit, setValue, watch } = useForm();

//  const [isUploading, setIsUploading] = useState(false);
//  const [error, setError] = useState(null);
//  const [uploadedFileUrl, setUploadedFileUrl] = useState({});
//  const [openCamera, setOpenCamera] = useState(false);
//  const [currentField, setCurrentField] = useState("");
//  const webcamRef = useRef(null);

//  useEffect(() => {
//   if (isEditMode && initialData) {
//    Object.keys(initialData).forEach((key) => {
//     setValue(key, initialData[key]);
//    });
//   }
//  }, [isEditMode, initialData, setValue]);

//  const uploadFile = async (file, fieldName) => {
//   setIsUploading(true);
//   setError(null);

//   const S3 = new AWS.S3({
//    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
//    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
//    region: process.env.REACT_APP_AWS_REGION,
//   });

//   const params = {
//    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
//    Key: `${Date.now()}-${file.name}`,
//    Body: file,
//    ContentType: file.type,
//   };

//   try {
//    const uploadResult = await S3.upload(params).promise();
//    setUploadedFileUrl((prevState) => ({
//     ...prevState,
//     [fieldName]: uploadResult.Location,
//    }));
//    setValue(fieldName, uploadResult.Location); // Set the URL in the text field
//   } catch (err) {
//    setError(err.message);
//   } finally {
//    setIsUploading(false);
//   }
//  };

//  useEffect(() => {
//   Object.keys(uploadedFileUrl).forEach((fieldName) => {
//    setValue(fieldName, uploadedFileUrl[fieldName]);
//   });
//  }, [uploadedFileUrl, setValue]);

//  const handleFileChange = (e, fieldName) => {
//   const file = e.target.files[0];
//   if (file) {
//    uploadFile(file, fieldName);
//   }
//  };

//  const handleCapture = () => {
//   const imageSrc = webcamRef.current.getScreenshot();
//   const file = dataURLtoFile(imageSrc, `${currentField}.jpg`);
//   uploadFile(file, currentField);
//   setOpenCamera(false);
//  };

//  const dataURLtoFile = (dataurl, filename) => {
//   const arr = dataurl.split(",");
//   const mime = arr[0].match(/:(.*?);/)[1];
//   const bstr = atob(arr[1]);
//   let n = bstr.length;
//   const u8arr = new Uint8Array(n);
//   while (n--) {
//    u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new File([u8arr], filename, { type: mime });
//  };

//  const onSubmit = (data) => {
//   if (isEditMode) {
//    dispatch(updateDriver(initialData.id, data));
//   } else {
//    dispatch(addDriver(data));
//   }

//   if (onClose) {
//    onClose();
//   }
//  };

//  const serviceTypeValues = watch("serviceType") || [];
//  const statusValues = watch("status") || [];

//  return (
//   <Box display="flex" justifyContent="center" alignItems="center">
//    <Box
//     sx={{
//      width: "100%",
//      maxWidth: 800,
//      maxHeight: "80vh",
//      overflowY: "auto",
//      bgcolor: "background.paper",
//      borderRadius: 2,
//      p: 3,
//      boxShadow: 4,
//      "&::-webkit-scrollbar": {
//       width: "6px",
//      },
//      "&::-webkit-scrollbar-thumb": {
//       backgroundColor: "#aaa",
//       borderRadius: "10px",
//      },
//      "-ms-overflow-style": "none",
//      "scrollbar-width": "none",
//     }}
//    >
//     <form onSubmit={handleSubmit(onSubmit)}>
//      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>
//       {isEditMode ? "Update Driver" : "Add New Driver"}
//      </Typography>

//      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
//       <Typography variant="h6" gutterBottom>
//        Driver Information
//       </Typography>
//       <Divider sx={{ mb: 2 }} />
//       <Grid container spacing={3}>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Driver Name"
//          {...register("driverName")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Mobile"
//          {...register("mobile")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="IFSC Code"
//          {...register("ifscCode")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Account Number"
//          {...register("accountNumber")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//       </Grid>
//      </Paper>

//      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
//       <Typography variant="h6" gutterBottom>
//        Car Information
//       </Typography>
//       <Divider sx={{ mb: 2 }} />
//       <Grid container spacing={3}>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Car Name"
//          {...register("carName")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Registration Number"
//          {...register("registrationNumber")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//       </Grid>
//      </Paper>

//      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
//       <Typography variant="h6" gutterBottom>
//        Document Uploads
//       </Typography>
//       <Divider sx={{ mb: 2 }} />
//       <Grid container spacing={3}>
//        {[
//         { name: "carRc", label: "Car RC" },
//         { name: "insurance", label: "Insurance" },
//         { name: "pollution", label: "Pollution" },
//         { name: "fitness", label: "Fitness" },
//         { name: "dl", label: "Driver License" },
//         { name: "driverPhoto", label: "Driver Photo" },
//         { name: "carFrontend", label: "Car Frontend" },
//         { name: "aadhaarFront", label: "Aadhaar Front" },
//         { name: "aadhaarBack", label: "Aadhaar Back" },
//         { name: "panCard", label: "PAN Card" },
//         { name: "checkbook", label: "Checkbook" },
//        ].map((field) => (
//         <Grid item xs={12} sm={6} key={field.name}>
//          <Typography variant="subtitle1" gutterBottom>
//           {field.label}
//          </Typography>
//          <TextField
//           type="text"
//           fullWidth
//           value={
//            uploadedFileUrl[field.name + "Url"] ||
//            initialData[field.name + "Url"] ||
//            ""
//           }
//           variant="outlined"
//           size="small"
//           disabled
//          />
//          <input
//           type="file"
//           id={`file-input-${field.name}`}
//           accept="image/*;capture=camera"
//           style={{ display: "none" }}
//           onChange={(e) => handleFileChange(e, `${field.name}Url`)}
//          />
//          <IconButton
//           color="primary"
//           component="span"
//           onClick={() =>
//            document.getElementById(`file-input-${field.name}`).click()
//           }
//          >
//           <Typography
//            display={"flex"}
//            alignItems={"center"}
//            justifyContent={"center"}
//           >
//            <UploadFileIcon fontSize="large" />
//            Upload
//           </Typography>
//          </IconButton>
//          <IconButton
//           color="secondary"
//           component="span"
//           onClick={() => {
//            setCurrentField(`${field.name}Url`);
//            setOpenCamera(true);
//           }}
//          >
//           <Typography
//            display={"flex"}
//            alignItems={"center"}
//            justifyContent={"center"}
//           >
//            <CameraAltIcon fontSize="large" />
//            Capture
//           </Typography>
//          </IconButton>
//          {isUploading && <CircularProgress size={24} />}
//          {error && <Typography color="error">{error}</Typography>}
//         </Grid>
//        ))}
//       </Grid>
//      </Paper>

//      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
//       <Typography variant="h6" gutterBottom>
//        Service and Status
//       </Typography>
//       <Divider sx={{ mb: 2 }} />
//       <Grid container spacing={3}>
//        <Grid item xs={12}>
//         <Typography variant="subtitle1" gutterBottom>
//          Service Type
//         </Typography>
//         <FormGroup row>
//          {serviceTypes.map((service) => (
//           <FormControlLabel
//            key={service}
//            control={
//             <StyledCheckbox
//              {...register("serviceType")}
//              value={service}
//              checked={serviceTypeValues.includes(service)}
//             />
//            }
//            label={service}
//           />
//          ))}
//         </FormGroup>
//        </Grid>
//        <Grid item xs={12}>
//         <Typography variant="subtitle1" gutterBottom>
//          Driver Status
//         </Typography>
//         <FormGroup row>
//          {statusOptions.map((status) => (
//           <FormControlLabel
//            key={status}
//            control={
//             <StyledCheckbox
//              {...register("status")}
//              value={status}
//              checked={statusValues.includes(status)}
//             />
//            }
//            label={status}
//           />
//          ))}
//         </FormGroup>
//        </Grid>
//       </Grid>
//      </Paper>

//      <Grid item xs={12}>
//       <Button
//        type="submit"
//        variant="contained"
//        color="primary"
//        fullWidth
//        sx={{ py: 1.5 }}
//        disabled={isUploading}
//       >
//        {isUploading ? (
//         <CircularProgress size={24} sx={{ color: "white" }} />
//        ) : isEditMode ? (
//         "Update"
//        ) : (
//         "Submit"
//        )}
//       </Button>
//      </Grid>
//     </form>
//     {/* Camera Dialog */}
//     <Dialog open={Boolean(openCamera)} onClose={() => setOpenCamera(false)}>
//      <DialogTitle>Capture Image</DialogTitle>
//      <DialogContent>
//       <Webcam
//        audio={false}
//        height={300}
//        ref={webcamRef}
//        screenshotFormat="image/jpeg"
//        width={300}
//        videoConstraints={videoConstraints}
//       />
//      </DialogContent>
//      <DialogActions>
//       <Button onClick={() => setOpenCamera(false)} color="primary">
//        Cancel
//       </Button>
//       <Button onClick={handleCapture} color="primary" variant="contained">
//        Capture
//       </Button>
//      </DialogActions>
//     </Dialog>
//    </Box>
//   </Box>
//  );
// };

// export default DistributorForm;


//above code push code



//below code sumit code
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Checkbox,
  Paper,
  Divider,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";
import AWS from "aws-sdk";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useDispatch } from "react-redux";
import { addDriver, updateDriver } from "../../redux/driver/action";
import Webcam from "react-webcam";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
// Service types
const serviceTypes = [
  "Astha Rides",
  "Self Rides",
  "Sharing Rides",
  "Intercity Service",
  "Out of station Rides",
  "Hills station Rides",
];

const statusOptions = ["Online", "Offline"];

// Styled checkbox
const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
}));

const DistributorForm = ({ isEditMode = false, initialData = {}, onClose }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, watch } = useForm();

  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState({});
  const [openCamera, setOpenCamera] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState("user");
  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: facingMode, // Use the facingMode state
  };
  useEffect(() => {
    if (isEditMode && initialData) {
      Object.keys(initialData).forEach((key) => {
        setValue(key, initialData[key]);
      });
    }
  }, [isEditMode, initialData, setValue]);

  const uploadFile = async (file, fieldName) => {
    setIsUploading(true);
    setError(null);

    const S3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `${Date.now()}-${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadResult = await S3.upload(params).promise();
      setUploadedFileUrl((prevState) => ({
        ...prevState,
        [fieldName]: uploadResult.Location,
      }));
      setValue(fieldName, uploadResult.Location); // Set the URL in the text field
    } catch (err) {
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    Object.keys(uploadedFileUrl).forEach((fieldName) => {
      setValue(fieldName, uploadedFileUrl[fieldName]);
    });
  }, [uploadedFileUrl, setValue]);

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file, fieldName);
    }
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const file = dataURLtoFile(imageSrc, `${currentField}.jpg`);
    uploadFile(file, currentField);
    setOpenCamera(false);
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const onSubmit = (data) => {
    if (isEditMode) {
      dispatch(updateDriver(initialData.id, data));
    } else {
      dispatch(addDriver(data));
    }

    if (onClose) {
      onClose();
    }
  };

  const serviceTypeValues = watch("serviceType") || [];
  const statusValues = watch("status") || [];

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        sx={{
          width: "100%",
          maxWidth: 800,
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: 2,
          p: 3,
          boxShadow: 4,
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#aaa",
            borderRadius: "10px",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>
            {isEditMode ? "Update Driver" : "Add New Driver"}
          </Typography>

          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Driver Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Driver Name"
                  {...register("driverName")}
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Mobile"
                  {...register("mobile")}
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="IFSC Code"
                  {...register("ifscCode")}
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Account Number"
                  {...register("accountNumber")}
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Car Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Car Name"
                  {...register("carName")}
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Registration Number"
                  {...register("registrationNumber")}
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Document Uploads
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              {[
                { name: "carRc", label: "Car RC" },
                { name: "insurance", label: "Insurance" },
                { name: "pollution", label: "Pollution" },
                { name: "fitness", label: "Fitness" },
                { name: "dl", label: "Driver License" },
                { name: "driverPhoto", label: "Driver Photo" },
                { name: "carFrontend", label: "Car Frontend" },
                { name: "aadhaarFront", label: "Aadhaar Front" },
                { name: "aadhaarBack", label: "Aadhaar Back" },
                { name: "panCard", label: "PAN Card" },
                { name: "checkbook", label: "Checkbook" },
              ].map((field) => (
                <Grid item xs={12} sm={6} key={field.name}>
                  <Typography variant="subtitle1" gutterBottom>
                    {field.label}
                  </Typography>
                  <TextField
                    type="text"
                    fullWidth
                    value={
                      uploadedFileUrl[field.name + "Url"] ||
                      initialData[field.name + "Url"] ||
                      ""
                    }
                    variant="outlined"
                    size="small"
                    disabled
                  />
                  <input
                    type="file"
                    id={`file-input-${field.name}`}
                    accept="image/*;capture=camera"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, `${field.name}Url`)}
                  />
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() =>
                      document
                        .getElementById(`file-input-${field.name}`)
                        .click()
                    }
                  >
                    <Typography
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <UploadFileIcon fontSize="large" />
                      Upload
                    </Typography>
                  </IconButton>
                  <IconButton
                    color="secondary"
                    component="span"
                    onClick={() => {
                      setCurrentField(`${field.name}Url`);
                      setOpenCamera(true);
                    }}
                  >
                    <Typography
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <CameraAltIcon fontSize="large" />
                      Capture
                    </Typography>
                  </IconButton>
                  {isUploading && <CircularProgress size={24} />}
                  {error && <Typography color="error">{error}</Typography>}
                </Grid>
              ))}
            </Grid>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Service and Status
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Service Type
                </Typography>
                <FormGroup row>
                  {serviceTypes.map((service) => (
                    <FormControlLabel
                      key={service}
                      control={
                        <StyledCheckbox
                          {...register("serviceType")}
                          value={service}
                          checked={serviceTypeValues.includes(service)}
                        />
                      }
                      label={service}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Driver Status
                </Typography>
                <FormGroup row>
                  {statusOptions.map((status) => (
                    <FormControlLabel
                      key={status}
                      control={
                        <StyledCheckbox
                          {...register("status")}
                          value={status}
                          checked={statusValues.includes(status)}
                        />
                      }
                      label={status}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ py: 1.5 }}
              disabled={isUploading}
            >
              {isUploading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : isEditMode ? (
                "Update"
              ) : (
                "Submit"
              )}
            </Button>
          </Grid>
        </form>

        <Dialog open={Boolean(openCamera)} onClose={() => setOpenCamera(false)}>
          <DialogTitle>Capture Image</DialogTitle>
          <DialogContent>
            <Webcam
              audio={false}
              height={300}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={300}
              videoConstraints={videoConstraints} // Use the updated videoConstraints
            />
            <IconButton onClick={toggleCamera}>
              <FlipCameraAndroidIcon />
            </IconButton>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCamera(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCapture} color="primary" variant="contained">
              Capture
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default DistributorForm;








// import React, { useEffect, useState } from "react";
// import { useForm, Controller } from "react-hook-form";
// import {
//  TextField,
//  Button,
//  Grid,
//  Typography,
//  Box,
//  CircularProgress,
//  IconButton,
//  FormControlLabel,
//  Checkbox,
//  Paper,
//  Divider,
//  FormGroup,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import AWS from "aws-sdk";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
// import { useDispatch } from "react-redux";
// import { addDriver, updateDriver } from "../../redux/driver/action";

// // Service types
// const serviceTypes = [
//  "Astha Rides",
//  "Self Rides",
//  "Sharing Rides",
//  "Intercity Service",
//  "Out of station Rides",
//  "Hills station Rides",
// ];

// const statusOptions = ["Online", "Offline"];

// // Styled checkbox
// const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
//  color: theme.palette.primary.main,
//  "&.Mui-checked": {
//   color: theme.palette.primary.main,
//  },
// }));

// const DistributorForm = ({ isEditMode = false, initialData = {}, onClose }) => {
//  const dispatch = useDispatch();
//  const { register, handleSubmit, control, setValue, watch } = useForm();

//  const [isUploading, setIsUploading] = useState(false);
//  const [error, setError] = useState(null);
//  const [uploadedFileUrl, setUploadedFileUrl] = useState({});

//  useEffect(() => {
//   if (isEditMode && initialData) {
//    Object.keys(initialData).forEach((key) => {
//     setValue(key, initialData[key]);
//    });
//   }
//  }, [isEditMode, initialData, setValue]);

//  const uploadFile = async (file, fieldName) => {
//   setIsUploading(true);
//   setError(null);

//   const S3 = new AWS.S3({
//    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
//    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
//    region: process.env.REACT_APP_AWS_REGION,
//   });

//   const params = {
//    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
//    Key: `${Date.now()}-${file.name}`,
//    Body: file,
//    ContentType: file.type,
//   };

//   try {
//    const uploadResult = await S3.upload(params).promise();
//    setUploadedFileUrl((prevState) => ({
//     ...prevState,
//     [fieldName]: uploadResult.Location,
//    }));
//   } catch (err) {
//    setError(err.message);
//   } finally {
//    setIsUploading(false);
//   }
//  };

//  useEffect(() => {
//   Object.keys(uploadedFileUrl).forEach((fieldName) => {
//    setValue(fieldName, uploadedFileUrl[fieldName]);
//   });
//  }, [uploadedFileUrl, setValue]);

//  const handleFileChange = (e, fieldName) => {
//   const file = e.target.files[0];
//   if (file) {
//    uploadFile(file, fieldName);
//   }
//  };

//  const onSubmit = (data) => {
//   if (isEditMode) {
//    dispatch(updateDriver(initialData.id, data)); // Update the existing driver
//   } else {
//    dispatch(addDriver(data)); // Add a new driver
//   }

//   if (onClose) {
//    onClose(); // Close the modal after submitting
//   }
//  };

//  const serviceTypeValues = watch("serviceType") || [];
//  const statusValues = watch("status") || [];

//  return (
//   <Box display="flex" justifyContent="center" alignItems="center">
//    <Box
//     sx={{
//      width: "100%",
//      maxWidth: 800,
//      maxHeight: "80vh",
//      overflowY: "auto",
//      bgcolor: "background.paper",
//      borderRadius: 2,
//      p: 3,
//      boxShadow: 4,
//      "&::-webkit-scrollbar": {
//       width: "6px",
//      },
//      "&::-webkit-scrollbar-thumb": {
//       backgroundColor: "#aaa",
//       borderRadius: "10px",
//      },
//      "-ms-overflow-style": "none", // IE and Edge
//      "scrollbar-width": "none", // Firefox
//     }}
//    >
//     <form onSubmit={handleSubmit(onSubmit)}>
//      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>
//       {isEditMode ? "Update Driver" : "Add New Driver"}
//      </Typography>

//      {/* Driver Information Section */}
//      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
//       <Typography variant="h6" gutterBottom>
//        Driver Information
//       </Typography>
//       <Divider sx={{ mb: 2 }} />
//       <Grid container spacing={3}>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Driver Name"
//          {...register("driverName")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Mobile"
//          {...register("mobile")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="IFSC Code"
//          {...register("ifscCode")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Account Number"
//          {...register("accountNumber")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//       </Grid>
//      </Paper>

//      {/* Car Information Section */}
//      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
//       <Typography variant="h6" gutterBottom>
//        Car Information
//       </Typography>
//       <Divider sx={{ mb: 2 }} />
//       <Grid container spacing={3}>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Car Name"
//          {...register("carName")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//        <Grid item xs={12} sm={6}>
//         <TextField
//          fullWidth
//          label="Registration Number"
//          {...register("registrationNumber")}
//          required
//          variant="outlined"
//          size="small"
//         />
//        </Grid>
//       </Grid>
//      </Paper>

//      {/* Document Upload Section */}
//      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
//       <Typography variant="h6" gutterBottom>
//        Document Uploads
//       </Typography>
//       <Divider sx={{ mb: 2 }} />
//       <Grid container spacing={3}>
//        {[
//         { name: "carRc", label: "Car RC" },
//         { name: "insurance", label: "Insurance" },
//         { name: "pollution", label: "Pollution" },
//         { name: "fitness", label: "Fitness" },
//         { name: "dl", label: "Driver License" },
//         { name: "driverPhoto", label: "Driver Photo" },
//         { name: "carFrontend", label: "Car Frontend" },
//         { name: "aadhaarFront", label: "Aadhaar Front" },
//         { name: "aadhaarBack", label: "Aadhaar Back" },
//         { name: "panCard", label: "PAN Card" },
//         { name: "checkbook", label: "Checkbook" },
//        ].map((field) => (
//         <Grid item xs={12} sm={6} key={field.name}>
//          <Typography variant="subtitle1" gutterBottom>
//           {field.label}
//          </Typography>
//          <TextField
//           type="text"
//           fullWidth
//           value={
//            uploadedFileUrl[field.name + "Url"] ||
//            initialData[field.name + "Url"] ||
//            ""
//           }
//           variant="outlined"
//           size="small"
//           disabled
//          />
//          <input
//           type="file"
//           id={`file-input-${field.name}`}
//           style={{ display: "none" }}
//           onChange={(e) => handleFileChange(e, `${field.name}Url`)}
//          />
//          <IconButton
//           color="primary"
//           component="span"
//           onClick={() =>
//            document.getElementById(`file-input-${field.name}`).click()
//           }
//          >
//           <Typography
//            display={"flex"}
//            alignItems={"center"}
//            justifyContent={"center"}
//           >
//            <UploadFileIcon fontSize="large" />
//            Upload
//           </Typography>
//          </IconButton>
//          {isUploading && <CircularProgress size={24} />}
//          {error && <Typography color="error">{error}</Typography>}
//         </Grid>
//        ))}
//       </Grid>
//      </Paper>

//      {/* Service and Status Section */}
//      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
//       <Typography variant="h6" gutterBottom>
//        Service and Status
//       </Typography>
//       <Divider sx={{ mb: 2 }} />
//       <Grid container spacing={3}>
//        <Grid item xs={12}>
//         <Typography variant="subtitle1" gutterBottom>
//          Service Type
//         </Typography>
//         <FormGroup row>
//          {serviceTypes.map((service) => (
//           <FormControlLabel
//            key={service}
//            control={
//             <StyledCheckbox
//              {...register("serviceType")}
//              value={service}
//              checked={serviceTypeValues.includes(service)}
//             />
//            }
//            label={service}
//           />
//          ))}
//         </FormGroup>
//        </Grid>
//        <Grid item xs={12}>
//         <Typography variant="subtitle1" gutterBottom>
//          Driver Status
//         </Typography>
//         <FormGroup row>
//          {statusOptions.map((status) => (
//           <FormControlLabel
//            key={status}
//            control={
//             <StyledCheckbox
//              {...register("status")}
//              value={status}
//              checked={statusValues.includes(status)}
//             />
//            }
//            label={status}
//           />
//          ))}
//         </FormGroup>
//        </Grid>
//       </Grid>
//      </Paper>

//      {/* Submit Button */}
//      <Grid item xs={12}>
//       <Button
//        type="submit"
//        variant="contained"
//        color="primary"
//        fullWidth
//        sx={{ py: 1.5 }}
//        disabled={isUploading}
//       >
//        {isUploading ? (
//         <CircularProgress size={24} sx={{ color: "white" }} />
//        ) : isEditMode ? (
//         "Update"
//        ) : (
//         "Submit"
//        )}
//       </Button>
//      </Grid>
//     </form>
//    </Box>
//   </Box>
//  );
// };

// export default DistributorForm;

// // import React, { useEffect, useState } from "react";
// // import { useForm, Controller } from "react-hook-form";
// // import {
// //  TextField,
// //  Button,
// //  MenuItem,
// //  Grid,
// //  Typography,
// //  Box,
// //  CircularProgress,
// //  IconButton,
// // } from "@mui/material";
// // import AWS from "aws-sdk";
// // import UploadFileIcon from "@mui/icons-material/UploadFile";
// // import { useDispatch } from "react-redux";
// // import { addDriver, updateDriver } from "../../redux/driver/action";

// // // Service types
// // const serviceTypes = [
// //  "Astha Rides",
// //  "Self Rides",
// //  "Sharing Rides",
// //  "Intercity Service",
// //  "Out of station Rides",
// //  "Hills station Rides",
// // ];

// // const statusOptions = ["Online", "Offline"];

// // const DistributorForm = ({ isEditMode = false, initialData = {}, onClose }) => {
// //  const dispatch = useDispatch();
// //  const { register, handleSubmit, control, setValue } = useForm();

// //  const [isUploading, setIsUploading] = useState(false);
// //  const [error, setError] = useState(null);
// //  const [uploadedFileUrl, setUploadedFileUrl] = useState({});

// //  useEffect(() => {
// //   if (isEditMode && initialData) {
// //    // Populate form fields with initial data if in edit mode
// //    Object.keys(initialData).forEach((key) => {
// //     setValue(key, initialData[key]);
// //    });
// //   }
// //  }, [isEditMode, initialData, setValue]);

// //  const uploadFile = async (file, fieldName) => {
// //   setIsUploading(true);
// //   setError(null);

// //   const S3 = new AWS.S3({
// //    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
// //    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
// //    region: process.env.REACT_APP_AWS_REGION,
// //   });

// //   const params = {
// //    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
// //    Key: `${Date.now()}-${file.name}`,
// //    Body: file,
// //    ContentType: file.type,
// //   };

// //   try {
// //    const uploadResult = await S3.upload(params).promise();
// //    setUploadedFileUrl((prevState) => ({
// //     ...prevState,
// //     [fieldName]: uploadResult.Location,
// //    }));
// //   } catch (err) {
// //    setError(err.message);
// //   } finally {
// //    setIsUploading(false);
// //   }
// //  };

// //  useEffect(() => {
// //   Object.keys(uploadedFileUrl).forEach((fieldName) => {
// //    setValue(fieldName, uploadedFileUrl[fieldName]);
// //   });
// //  }, [uploadedFileUrl, setValue]);

// //  const handleFileChange = (e, fieldName) => {
// //   const file = e.target.files[0];
// //   if (file) {
// //    uploadFile(file, fieldName);
// //   }
// //  };

// //  const onSubmit = (data) => {
// //   if (isEditMode) {
// //    dispatch(updateDriver(initialData.id, data)); // Update the existing driver
// //   } else {
// //    dispatch(addDriver(data)); // Add a new driver
// //   }

// //   if (onClose) {
// //    onClose(); // Close the modal after submitting
// //   }
// //  };

// //  return (
// //   <Box display="flex" justifyContent="center" alignItems="center">
// //    <Box
// //     sx={{
// //      width: "100%",
// //      maxWidth: 800,
// //      maxHeight: "80vh",
// //      overflowY: "auto",
// //      bgcolor: "background.paper",
// //      "&::-webkit-scrollbar": {
// //       width: "6px",
// //      },
// //      "&::-webkit-scrollbar-thumb": {
// //       backgroundColor: "#aaa",
// //       borderRadius: "10px",
// //      },
// //      "-ms-overflow-style": "none", // IE and Edge
// //      "scrollbar-width": "none", // Firefox
// //     }}
// //    >
// //     <form onSubmit={handleSubmit(onSubmit)}>
// //      <Typography variant="h4" gutterBottom>
// //       {isEditMode ? "Update Car" : "Add New Car"}
// //      </Typography>
// //      <Grid container spacing={3}>
// //       <Grid item xs={12} sm={6}>
// //        <TextField
// //         fullWidth
// //         label="Driver Name"
// //         {...register("driverName")}
// //         required
// //         variant="outlined"
// //         size="small"
// //        />
// //       </Grid>
// //       <Grid item xs={12} sm={6}>
// //        <TextField
// //         fullWidth
// //         label="Mobile"
// //         {...register("mobile")}
// //         required
// //         variant="outlined"
// //         size="small"
// //        />
// //       </Grid>
// //       <Grid item xs={12} sm={6}>
// //        <TextField
// //         fullWidth
// //         label="IFSC Code"
// //         {...register("ifscCode")}
// //         required
// //         variant="outlined"
// //         size="small"
// //        />
// //       </Grid>
// //       <Grid item xs={12} sm={6}>
// //        <TextField
// //         fullWidth
// //         label="Account Number"
// //         {...register("accountNumber")}
// //         required
// //         variant="outlined"
// //         size="small"
// //        />
// //       </Grid>
// //       <Grid item xs={12} sm={6}>
// //        <TextField
// //         fullWidth
// //         label="Car Name"
// //         {...register("carName")}
// //         required
// //         variant="outlined"
// //         size="small"
// //        />
// //       </Grid>
// //       <Grid item xs={12} sm={6}>
// //        <TextField
// //         fullWidth
// //         label="Registration Number"
// //         {...register("registrationNumber")}
// //         required
// //         variant="outlined"
// //         size="small"
// //        />
// //       </Grid>

// //       {[
// //        { name: "carRc", label: "Car RC" },
// //        { name: "insurance", label: "Insurance" },
// //        { name: "pollution", label: "Pollution" },
// //        { name: "fitness", label: "Fitness" },
// //        { name: "dl", label: "Driver License" },
// //        { name: "driverPhoto", label: "Driver Photo" },
// //        { name: "carFrontend", label: "Car Frontend" },
// //        { name: "aadhaarFront", label: "Aadhaar Front" },
// //        { name: "aadhaarBack", label: "Aadhaar Back" },
// //        { name: "panCard", label: "PAN Card" },
// //        { name: "checkbook", label: "Checkbook" },
// //       ].map((field) => (
// //        <Grid item xs={12} sm={6} key={field.name}>
// //         <Typography variant="subtitle1" gutterBottom>
// //          {field.label}
// //         </Typography>
// //         <TextField
// //          type="text"
// //          fullWidth
// //          value={
// //           uploadedFileUrl[field.name + "Url"] ||
// //           initialData[field.name + "Url"] ||
// //           ""
// //          }
// //          variant="outlined"
// //          size="small"
// //          disabled
// //         />
// //         <input
// //          type="file"
// //          id={`file-input-${field.name}`}
// //          style={{ display: "none" }}
// //          onChange={(e) => handleFileChange(e, `${field.name}Url`)}
// //         />
// //         <IconButton
// //          color="primary"
// //          component="span"
// //          onClick={() =>
// //           document.getElementById(`file-input-${field.name}`).click()
// //          }
// //         >
// //          <Typography
// //           display={"flex"}
// //           alignItems={"center"}
// //           justifyContent={"center"}
// //          >
// //           <UploadFileIcon fontSize="large" />
// //           Upload
// //          </Typography>
// //         </IconButton>
// //         {isUploading && <CircularProgress size={24} />}
// //         {error && <Typography color="error">{error}</Typography>}
// //        </Grid>
// //       ))}

// //       <Grid item xs={12}>
// //        <Controller
// //         name="serviceType"
// //         control={control}
// //         defaultValue={[]}
// //         render={({ field }) => (
// //          <TextField
// //           select
// //           fullWidth
// //           label="Service Type"
// //           {...field}
// //           required
// //           variant="outlined"
// //           size="small"
// //           SelectProps={{
// //            multiple: true,
// //           }}
// //          >
// //           {serviceTypes.map((service) => (
// //            <MenuItem key={service} value={service}>
// //             {service}
// //            </MenuItem>
// //           ))}
// //          </TextField>
// //         )}
// //        />
// //       </Grid>

// //       <Grid item xs={12}>
// //        <Controller
// //         name="status"
// //         control={control}
// //         defaultValue=""
// //         render={({ field }) => (
// //          <TextField
// //           select
// //           fullWidth
// //           label="Driver Status"
// //           {...field}
// //           required
// //           variant="outlined"
// //           size="small"
// //          >
// //           {statusOptions.map((status) => (
// //            <MenuItem key={status} value={status}>
// //             {status}
// //            </MenuItem>
// //           ))}
// //          </TextField>
// //         )}
// //        />
// //       </Grid>

// //       <Grid item xs={12}>
// //        <Button
// //         type="submit"
// //         variant="contained"
// //         color="primary"
// //         fullWidth
// //         sx={{ py: 1.5 }}
// //         disabled={isUploading}
// //        >
// //         {isUploading ? (
// //          <CircularProgress size={24} sx={{ color: "white" }} />
// //         ) : isEditMode ? (
// //          "Update"
// //         ) : (
// //          "Submit"
// //         )}
// //        </Button>
// //       </Grid>
// //      </Grid>
// //     </form>
// //    </Box>
// //   </Box>
// //  );
// // };

// // export default DistributorForm;

// // // import React, { useEffect, useState } from "react";
// // // import { useForm, Controller } from "react-hook-form";
// // // import {
// // //  TextField,
// // //  Button,
// // //  MenuItem,
// // //  Grid,
// // //  Typography,
// // //  Box,
// // //  CircularProgress,
// // //  IconButton,
// // // } from "@mui/material";
// // // import AWS from "aws-sdk";
// // // import UploadFileIcon from "@mui/icons-material/UploadFile";
// // // import { useDispatch } from "react-redux";
// // // import { addDriver, updateDriver } from "../../redux/driver/action";

// // // // Service types
// // // const serviceTypes = [
// // //  "Astha Rides",
// // //  "Self Rides",
// // //  "Sharing Rides",
// // //  "Intercity Service",
// // //  "Out of station Rides",
// // //  "Hills station Rides",
// // // ];

// // // const DistributorForm = ({ isEditMode = false, initialData = {}, onClose }) => {
// // //  const dispatch = useDispatch();
// // //  const { register, handleSubmit, control, setValue } = useForm();

// // //  const [isUploading, setIsUploading] = useState(false);
// // //  const [error, setError] = useState(null);
// // //  const [uploadedFileUrl, setUploadedFileUrl] = useState({});

// // //  useEffect(() => {
// // //   if (isEditMode && initialData) {
// // //    // Populate form fields with initial data if in edit mode
// // //    Object.keys(initialData).forEach((key) => {
// // //     setValue(key, initialData[key]);
// // //    });
// // //   }
// // //  }, [isEditMode, initialData, setValue]);

// // //  const uploadFile = async (file, fieldName) => {
// // //   setIsUploading(true);
// // //   setError(null);

// // //   const S3 = new AWS.S3({
// // //    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
// // //    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
// // //    region: process.env.REACT_APP_AWS_REGION,
// // //   });

// // //   const params = {
// // //    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
// // //    Key: `${Date.now()}-${file.name}`,
// // //    Body: file,
// // //    ContentType: file.type,
// // //   };

// // //   try {
// // //    const uploadResult = await S3.upload(params).promise();
// // //    setUploadedFileUrl((prevState) => ({
// // //     ...prevState,
// // //     [fieldName]: uploadResult.Location,
// // //    }));
// // //   } catch (err) {
// // //    setError(err.message);
// // //   } finally {
// // //    setIsUploading(false);
// // //   }
// // //  };

// // //  useEffect(() => {
// // //   Object.keys(uploadedFileUrl).forEach((fieldName) => {
// // //    setValue(fieldName, uploadedFileUrl[fieldName]);
// // //   });
// // //  }, [uploadedFileUrl, setValue]);

// // //  const handleFileChange = (e, fieldName) => {
// // //   const file = e.target.files[0];
// // //   if (file) {
// // //    uploadFile(file, fieldName);
// // //   }
// // //  };

// // //  const onSubmit = (data) => {
// // //   if (isEditMode) {
// // //    dispatch(updateDriver(initialData.id, data)); // Update the existing driver
// // //   } else {
// // //    dispatch(addDriver(data)); // Add a new driver
// // //   }

// // //   if (onClose) {
// // //    onClose(); // Close the modal after submitting
// // //   }
// // //  };

// // //  return (
// // //   <Box display="flex" justifyContent="center" alignItems="center">
// // //    <Box
// // //     sx={{
// // //      width: "100%",
// // //      maxWidth: 800,
// // //      maxHeight: "80vh",
// // //      overflowY: "auto",
// // //      bgcolor: "background.paper",
// // //      "&::-webkit-scrollbar": {
// // //       width: "6px",
// // //      },
// // //      "&::-webkit-scrollbar-thumb": {
// // //       backgroundColor: "#aaa",
// // //       borderRadius: "10px",
// // //      },
// // //      "-ms-overflow-style": "none", // IE and Edge
// // //      "scrollbar-width": "none", // Firefox
// // //     }}
// // //    >
// // //     <form onSubmit={handleSubmit(onSubmit)}>
// // //      <Typography variant="h4" gutterBottom>
// // //       {isEditMode ? "Update Car" : "Add New Car"}
// // //      </Typography>
// // //      <Grid container spacing={3}>
// // //       <Grid item xs={12} sm={6}>
// // //        <TextField
// // //         fullWidth
// // //         label="Driver Name"
// // //         {...register("driverName")}
// // //         required
// // //         variant="outlined"
// // //         size="small"
// // //        />
// // //       </Grid>
// // //       <Grid item xs={12} sm={6}>
// // //        <TextField
// // //         fullWidth
// // //         label="Mobile"
// // //         {...register("mobile")}
// // //         required
// // //         variant="outlined"
// // //         size="small"
// // //        />
// // //       </Grid>
// // //       <Grid item xs={12} sm={6}>
// // //        <TextField
// // //         fullWidth
// // //         label="IFSC Code"
// // //         {...register("ifscCode")}
// // //         required
// // //         variant="outlined"
// // //         size="small"
// // //        />
// // //       </Grid>
// // //       <Grid item xs={12} sm={6}>
// // //        <TextField
// // //         fullWidth
// // //         label="Account Number"
// // //         {...register("accountNumber")}
// // //         required
// // //         variant="outlined"
// // //         size="small"
// // //        />
// // //       </Grid>

// // //       {[
// // //        { name: "carRc", label: "Car RC" },
// // //        { name: "insurance", label: "Insurance" },
// // //        { name: "pollution", label: "Pollution" },
// // //        { name: "fitness", label: "Fitness" },
// // //        { name: "dl", label: "Driver License" },
// // //        { name: "driverPhoto", label: "Driver Photo" },
// // //        { name: "carFrontend", label: "Car Frontend" },
// // //        { name: "aadhaarFront", label: "Aadhaar Front" },
// // //        { name: "aadhaarBack", label: "Aadhaar Back" },
// // //        { name: "panCard", label: "PAN Card" },
// // //        { name: "checkbook", label: "Checkbook" },
// // //       ].map((field) => (
// // //        <Grid item xs={12} sm={6} key={field.name}>
// // //         <Typography variant="subtitle1" gutterBottom>
// // //          {field.label}
// // //         </Typography>
// // //         <TextField
// // //          type="text"
// // //          fullWidth
// // //          value={
// // //           uploadedFileUrl[field.name + "Url"] ||
// // //           initialData[field.name + "Url"] ||
// // //           ""
// // //          }
// // //          variant="outlined"
// // //          size="small"
// // //          disabled
// // //         />
// // //         <input
// // //          type="file"
// // //          id={`file-input-${field.name}`}
// // //          style={{ display: "none" }}
// // //          onChange={(e) => handleFileChange(e, `${field.name}Url`)}
// // //         />
// // //         <IconButton
// // //          color="primary"
// // //          component="span"
// // //          onClick={() =>
// // //           document.getElementById(`file-input-${field.name}`).click()
// // //          }
// // //         >
// // //          <Typography
// // //           display={"flex"}
// // //           alignItems={"center"}
// // //           justifyContent={"center"}
// // //          >
// // //           <UploadFileIcon fontSize="large" />
// // //           Upload
// // //          </Typography>
// // //         </IconButton>
// // //         {isUploading && <CircularProgress size={24} />}
// // //         {error && <Typography color="error">{error}</Typography>}
// // //        </Grid>
// // //       ))}

// // //       <Grid item xs={12}>
// // //        <Controller
// // //         name="serviceType"
// // //         control={control}
// // //         render={({ field }) => (
// // //          <TextField
// // //           select
// // //           fullWidth
// // //           label="Service Type"
// // //           {...field}
// // //           required
// // //           variant="outlined"
// // //           size="small"
// // //          >
// // //           {serviceTypes.map((service) => (
// // //            <MenuItem key={service} value={service}>
// // //             {service}
// // //            </MenuItem>
// // //           ))}
// // //          </TextField>
// // //         )}
// // //        />
// // //       </Grid>

// // //       <Grid item xs={12}>
// // //        <Button
// // //         type="submit"
// // //         variant="contained"
// // //         color="primary"
// // //         fullWidth
// // //         sx={{ py: 1.5 }}
// // //         disabled={isUploading}
// // //        >
// // //         {isUploading ? (
// // //          <CircularProgress size={24} sx={{ color: "white" }} />
// // //         ) : isEditMode ? (
// // //          "Update"
// // //         ) : (
// // //          "Submit"
// // //         )}
// // //        </Button>
// // //       </Grid>
// // //      </Grid>
// // //     </form>
// // //    </Box>
// // //   </Box>
// // //  );
// // // };

// // // export default DistributorForm;
