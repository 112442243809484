// src/components/distributer/DistributerStatements.js
import React, { useState } from "react";
import {
 Box,
 Typography,
 Paper,
 useTheme,
 Container,
 Stack,
 Button,
 Tabs,
 Tab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataGridComponent from "../data/DataGridComponent";
import PieChartComponent from "../Charts/PieChartComponent";

const DistributerStatements = () => {
 const theme = useTheme();
 const [value, setValue] = useState("one");

 const handleChange = (event, newValue) => {
  setValue(newValue);
 };

 return (
  <Box sx={{ width: "100%" }}>
   <Box
    display="flex"
    flexDirection="column"
    // p={4}
    // gap={4}
    sx={{ backgroundColor: theme.palette.background.default }}
   >
    <Typography variant="h4" component="h1" gutterBottom>
     Dashboard Overview
    </Typography>
    <Box display="flex" justifyContent="space-between" gap={2} flexWrap="wrap">
     <Paper
      elevation={3}
      sx={{
       p: 5,
       borderRadius: "8px",
       flex: 1,
       display: "flex",
       flexDirection: "column",
       gap: 1,
       alignItems: "center",
       textAlign: "center",
      }}
     >
      <Typography variant="h5" component="h2" color="text.primary">
       Total Earnings
      </Typography>
      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={4}>
       <Typography variant="h4" color="primary.main">
        $123,456
       </Typography>
       <Button variant="contained">Detailed</Button>
      </Stack>
     </Paper>
     <Paper
      elevation={2}
      sx={{
       p: 3,
       borderRadius: "8px",
       flex: 1,
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       height: "100%",
      }}
     >
      <PieChartComponent />
     </Paper>
    </Box>
   </Box>

   <Tabs
    value={value}
    onChange={handleChange}
    textColor="secondary"
    indicatorColor="secondary"
    aria-label="tabs example"
    sx={{ marginBottom: 2 }}
   >
    <Tab value="one" label="All Cars" />
    {/* <Tab value="two" label="InAll Cars" /> */}
   </Tabs>

   {value === "one" && (
    <Box p={2}>
     {/* <Typography variant="h6">All Cars</Typography> */}
     <DataGridComponent />
    </Box>
   )}
   {/* {value === "two" && (
        <Box p={2}>
          <Typography variant="h6">Inactive Cars</Typography>
          <DataGridComponent />
        </Box>
      )} */}
  </Box>
 );
};

export default DistributerStatements;

// // src/components/distributer/DistributerStatements.js
// import React from "react";
// import {
//   Box,
//   Typography,
//   Paper,
//   useTheme,
//   Container,
//   Stack,
//   Button,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import DataGridComponent from "../data/DataGridComponent";
// import PieChartComponent from "../Charts/PieChartComponent";

// const DistributerStatements = () => {
//   const theme = useTheme();
//   return (
//     <Container maxWidth={false} sx={{ width: "100%", padding: 2 }}>
//       <Box
//         display="flex"
//         flexDirection="column"
//         p={4}
//         gap={4}
//         sx={{ backgroundColor: theme.palette.background.default }}
//       >
//         <Typography variant="h4" component="h1" gutterBottom>
//           Dashboard Overview
//         </Typography>
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           gap={2}
//           flexWrap="wrap"
//           // alignItems={"center"}
//         >
//           <Paper
//             elevation={3}
//             sx={{
//               p: 3,
//               borderRadius: "8px",
//               flex: 1,
//               display: "flex",
//               flexDirection: "column",
//               gap: 1,
//               alignItems: "center",
//               textAlign: "center",
//             }}
//           >
//             <Typography variant="h5" component="h2" color="text.primary">
//               Total Earnings
//             </Typography>
//             <Stack direction="row" useFlexGap flexWrap="wrap" spacing={4}>
//               <Typography variant="h4" color="primary.main">
//                 $123,456
//               </Typography>
//               <Button variant="contained">Detailed</Button>
//             </Stack>
//           </Paper>
//           <Paper
//             elevation={3}
//             sx={{
//               p: 3,
//               borderRadius: "8px",
//               flex: 1,
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               height: "100%",
//             }}
//           >
//             <PieChartComponent />
//           </Paper>
//         </Box>
//       </Box>

//       <Box p={2}>
//         <Typography variant="h6">Active Cars</Typography>
//         <DataGridComponent />
//       </Box>
//       <Box p={2}>
//         <Typography variant="h6">Inactive Cars</Typography>
//         <DataGridComponent />
//       </Box>
//     </Container>
//   );
// };

// export default DistributerStatements;
