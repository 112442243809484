// src/redux/reducers/notificationReducer.js
import * as types from "./actionTypes";

const initialState = {
 notifications: [],
 loading: false,
 error: null,
};

const notificationReducer = (state = initialState, action) => {
 switch (action.type) {
  case types.FETCH_NOTIFICATIONS_REQUEST:
   return {
    ...state,
    loading: true,
    error: null,
   };
  case types.FETCH_NOTIFICATIONS_SUCCESS:
   return {
    ...state,
    loading: false,
    notifications: action.payload,
   };
  case types.FETCH_NOTIFICATIONS_FAILURE:
   return {
    ...state,
    loading: false,
    error: action.payload,
   };
  case types.MARK_NOTIFICATION_AS_READ:
   return {
    ...state,
    notifications: state.notifications.map((notification) =>
     notification._id === action.payload
      ? { ...notification, read: true }
      : notification
    ),
   };
  case types.MARK_ALL_NOTIFICATIONS_AS_READ:
   return {
    ...state,
    notifications: state.notifications.map((notification) => ({
     ...notification,
     read: true,
    })),
   };
  default:
   return state;
 }
};

export { notificationReducer };
