import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import AWS from "aws-sdk";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

const AdminBannerUpload = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [bannerUrl, setBannerUrl] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(""); // To display the selected file name
  const [imageSrc, setImageSrc] = useState(""); // To display the preview image
  const [bannerGet, setBannerGet] = useState([]);
  const [openDriverModal, setOpenDriverModal] = useState(false);
  const [viewImageModalOpen, setViewImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(""); // To hold the URL of the image to view

  const uploadFile = async () => {
    if (!file) return;

    setIsUploading(true);
    setError(null);

    const S3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `${Date.now()}-${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadResult = await S3.upload(params).promise();
      setBannerUrl(uploadResult.Location); // Set the uploaded file URL
      await axios.post("https://api.janatarides.com/api/banner/post-banner", {
        bannerImg: uploadResult.Location,
      });
      fetchData(); // Refresh the banner list after upload
      handleCloseDriverModal(); // Close the modal after a successful upload
      setBannerUrl("")
    } catch (err) {
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);

      const reader = new FileReader();
      reader.onloadend = () => setImageSrc(reader.result);
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    uploadFile();
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(
        "https://api.janatarides.com/api/banner/get-banner"
      );
      setBannerGet(res.data.banners);
      console.log("check", res.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  const handleOpenDriverModal = () => setOpenDriverModal(true);
  const handleCloseDriverModal = () => setOpenDriverModal(false);

  const handleViewImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setViewImageModalOpen(true);
  };

  const handleCloseViewImageModal = () => setViewImageModalOpen(false);

  const bannerCols = [
    { field: "_id", headerName: "ID", width: 200 },
    {
      field: "bannerImg",
      headerName: "Banner Image",
      width: 250,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Banner"
          style={{ width: "100%", height: "auto", borderRadius: "8px" }}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleViewImage(params.row.bannerImg)}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleDelete(params.row._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleDelete = async(id) => {
    try {
      const res = await axios.delete(`https://api.janatarides.com/api/banner/delete-banner/${id}`)
      console.log("eror",res)
      fetchData()
    } catch (error) {
      console.log("error",error)
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Banner Management</Typography>
        <IconButton
          onClick={handleOpenDriverModal}
          color="primary"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            backgroundColor: "#1976D2",
            borderRadius: "8px",
            padding: "12px 16px",
            '&:hover': {
              backgroundColor: "#e0e0e0",
            },
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <AddIcon sx={{ fontSize: 20 }} />
          <Typography variant="body2" sx={{ ml: 1, fontWeight: 500 }}>
            Add Banner
          </Typography>
        </IconButton>
      </Box>

      {/* Banner List */}
      <Box sx={{ mb: 5 }}>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={bannerGet}
            columns={bannerCols}
            getRowId={(row) => row._id}
            pageSize={10}
            sx={{
              "& .MuiDataGrid-cell": {
                padding: "20px",
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "#f5f5f5",
                "&:nth-of-type(odd)": {
                  backgroundColor: "#e0e0e0",
                },
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#1976d2",
                color: "white",
              },
            }}
          />
        </div>
      </Box>

      {/* Upload Banner Modal */}
      <Modal open={openDriverModal} onClose={handleCloseDriverModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" gutterBottom>
              Upload Banner
            </Typography>
            <IconButton
              onClick={handleCloseDriverModal}
              sx={{ color: "grey.500" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            component="label"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            {fileName ? `File Selected: ${fileName}` : "Upload Image"}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>
          {imageSrc && (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <img
                src={imageSrc}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isUploading || !file}
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
          {isUploading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          )}
          {error && (
            <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
              {error}
            </Typography>
          )}
        </Box>
      </Modal>

      {/* View Banner Modal */}
      <Modal open={viewImageModalOpen} onClose={handleCloseViewImageModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            outline: "none",
            textAlign: "center"
          }}
        >
          <Typography variant="h6" gutterBottom>
            View Banner
          </Typography>
          <IconButton
            onClick={handleCloseViewImageModal}
            sx={{ color: "grey.500", position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Banner"
              style={{
                maxWidth: "100%",
                maxHeight: "400px",
                objectFit: "contain",
                borderRadius: "8px",
                marginTop: 20
              }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminBannerUpload;
