import {
  FETCH_RIDES_SUCCESS,
  FETCH_RIDES_FAILURE,
  PROCESS_PAYMENT_SUCCESS,
  PROCESS_PAYMENT_FAILURE,
} from "./actionTypes";

const initialState = {
  rides: [],
  error: null,
  payment: null,
};

export const rideReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RIDES_SUCCESS:
      return {
        ...state,
        rides: action.payload,
      };
    case FETCH_RIDES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payload,
      };
    case PROCESS_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

 
