// import {

// } from "../actions/reportActions";

import {
  FETCH_WEEKLY_SALES_SUCCESS,
  FETCH_WEEKLY_SALES_FAILURE,
  FETCH_WEEKLY_ORDERS_SUCCESS,
  FETCH_WEEKLY_ORDERS_FAILURE,
  FETCH_VISITORS_ONLINE_SUCCESS,
  FETCH_VISITORS_ONLINE_FAILURE,
} from "./actionTypes";

const initialState = {
  weeklySales: null,
  weeklyOrders: null,
  visitorsOnline: null,
  error: null,
};

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEEKLY_SALES_SUCCESS:
      return {
        ...state,
        weeklySales: action.payload,
      };
    case FETCH_WEEKLY_SALES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_WEEKLY_ORDERS_SUCCESS:
      return {
        ...state,
        weeklyOrders: action.payload,
      };
    case FETCH_WEEKLY_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_VISITORS_ONLINE_SUCCESS:
      return {
        ...state,
        visitorsOnline: action.payload,
      };
    case FETCH_VISITORS_ONLINE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

 
