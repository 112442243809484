import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard";
import Cars from "./Cars";
import DistributerStatements from "./DistributerStatements";
import DistributerWithdrawal from "./DistributerWithdrawal";
import DistributerNotification from "./DistributerNotification";
import DistributorHelp from "./DistributorHelp";
import Distributer from "./Distributer";
import Reports from "../Reports";
import DistributorForm from "./DistributorForm";
import MiniDrawer from "../MiniDrawer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpIcon from "@mui/icons-material/Help";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfilePage from "./ProfilePage";

const distributorMenuItems = [
 { text: "Dashboard", link: "/distributer", icon: <DashboardIcon /> },
 { text: "Cars", link: "/distributer/cars", icon: <DirectionsCarIcon /> },
 { text: "Statements", link: "/distributer/statement", icon: <ReceiptIcon /> },
 {
  text: "Withdrawal",
  link: "/distributer/withdrawal",
  icon: <AccountBalanceWalletIcon />,
 },
 {
  text: "Notification",
  link: "/distributer/notification",
  icon: <NotificationsIcon />,
 },
 { text: "Help", link: "/distributer/help", icon: <HelpIcon /> },
 { text: "Profile", link: "/distributer/profile", icon: <AccountCircleIcon /> },
];

function DistributorPanel() {
 return (
  <MiniDrawer menuItems={distributorMenuItems}>
   <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path="/cars" element={<Cars />} />
    <Route path="/statement" element={<DistributerStatements />} />
    <Route path="/withdrawal" element={<DistributerWithdrawal />} />
    <Route path="/notification" element={<DistributerNotification />} />
    <Route path="/help" element={<DistributorHelp />} />
    <Route path="/distributer" element={<Distributer />} />
    <Route path="/reports" element={<Reports />} />
    <Route path="/distributer-form" element={<DistributorForm />} />
    <Route path="/profile" element={<ProfilePage />} />
   </Routes>
  </MiniDrawer>
 );
}

export default DistributorPanel;
