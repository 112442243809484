// src/components/PrivateRoute.js
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ role }) => {
 const { isAuthenticated, user, loading } = useSelector((state) => state.auth);

 if (loading) {
  return <div>Loading...</div>; // or any loading spinner
 }

 if (!isAuthenticated) {
  return <Navigate to="/login" />;
 }

 if (user.role !== role) {
  // If the user does not have the correct role, redirect them accordingly
  return (
   <Navigate to={user.role === "superadmin" ? "/admin" : "/distributer"} />
  );
 }

 return <Outlet />;
};

export default PrivateRoute;

// // src/components/PrivateRoute.js
// import React from "react";
// import { Route, Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";

// const PrivateRoute = ({ component: Component, ...rest }) => {
//  const { isAuthenticated, loading } = useSelector((state) => state.auth);

//  if (loading) return <div>Loading...</div>;

//  return (
//   <Route
//    {...rest}
//    render={(props) =>
//     isAuthenticated ? <Component {...props} /> : <Navigate to="/login" />
//    }
//   />
//  );
// };

// export default PrivateRoute;
