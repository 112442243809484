import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/action";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./Login.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [bannerGet, setBannerGet] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, error } = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        "https://api.janatarides.com/api/banner/get-banner"
      );
      setBannerGet(res.data.banners);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #1a1a2e, #16213e, #0f3460)",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",  
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        margin: "auto",
        height: "100vh",
      }}
    >
      <Container maxWidth="lg">
        <Grid container sx={{ boxShadow: 10, borderRadius: 2 }}>
          <Grid
            item
            xs={12}
            md={6}
            
          >
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {bannerGet.map((ele) => (
                <SwiperSlide key={ele._id}>
                  <Box
                    component="img"
                    alt={ele._id}
                    src={ele.bannerImg}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                backgroundColor: "transparent",
                padding: 4,
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <Box sx={{ textAlign: "center", mb: 3 }}>
                <img src="/logo.png" alt="Logo" style={{ maxWidth: "100px" }} />
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{ mt: 2, color: "#fff" }}
                >
                  Login
                </Typography>
              </Box>

              {/* Email Field */}
              <Typography sx={{ color: "#fff", mb: 1 }}>Email</Typography>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  style: { color: "#fff" }, // Ensure input text is visible
                  sx: {
                    backgroundColor: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
                    borderRadius: 1,
                  },
                }}
              />

              {/* Password Field */}
              <Typography sx={{ color: "#fff", mt: 2, mb: 1 }}>
                Password
              </Typography>
              <TextField
                type={showPassword ? "text" : "password"}
                variant="outlined"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  style: { color: "#fff" }, // Ensure input text is visible
                  sx: {
                    backgroundColor: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
                    borderRadius: 1,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ color: "#fff" }} // Icon color
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormControlLabel
                control={<Checkbox id="save-user" sx={{ color: "#fff" }} />}
                label={
                  <Typography sx={{ color: "#fff" }}>Save Password</Typography>
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                  mb: 2,
                }}
              >
                <Link
                  href="/forgot-password"
                  variant="body2"
                  sx={{ color: "#fff" }}
                >
                  Forgot Password?
                </Link>
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Login
              </Button>
              {error && (
                <Typography color="error" sx={{ mt: 2 }}>
                  {error}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Login;
