import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
 Button,
 Modal,
 Box,
 Typography,
 IconButton,
 Grid,
 Switch,
 TextField,
 useMediaQuery,
 useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DistributorForm from "../distributer/DistributorForm";
import { fetchDrivers, deleteDriver } from "../../redux/driver/action";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const DataGridComponent = () => {
 const dispatch = useDispatch();
 const { drivers } = useSelector((state) => state.driver);
 const { user } = useSelector((state) => state.auth);

 const [open, setOpen] = useState(false);
 const [selectedDriver, setSelectedDriver] = useState(null);
 const [isEditMode, setIsEditMode] = useState(false);
 const [isViewMode, setIsViewMode] = useState(false);
 const [filteredDrivers, setFilteredDrivers] = useState(drivers);

 const [filters, setFilters] = useState({
  searchTerm: "",
  date: "",
 });

 const theme = useTheme();
 const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

 useEffect(() => {
  dispatch(fetchDrivers());
 }, [dispatch]);

 useEffect(() => {
  applyFilters();
 }, [drivers, filters]);

 const applyFilters = () => {
  let filtered = drivers;

  if (filters.searchTerm) {
   const searchTermLower = filters.searchTerm.toLowerCase();
   filtered = filtered.filter(
    (driver) =>
     driver.driverName?.toLowerCase().includes(searchTermLower) ||
     driver.mobile?.includes(filters.searchTerm)
   );
  }

  if (filters.date) {
   filtered = filtered.filter((driver) => {
    const createdAtDate = new Date(driver.createdAt).toLocaleDateString();
    return createdAtDate === new Date(filters.date).toLocaleDateString();
   });
  }

  setFilteredDrivers(filtered);
 };

 const handleOpen = (driver, mode = "view") => {
  setSelectedDriver(driver);
  setIsEditMode(mode === "edit");
  setIsViewMode(mode === "view");
  setOpen(true);
 };

 const handleClose = () => {
  setOpen(false);
  setSelectedDriver(null);
  setIsEditMode(false);
  setIsViewMode(false);
 };

 const handleDelete = (id) => {
  dispatch(deleteDriver(id));
 };

 const handleSwitchChange = async (id, newValue) => {
  try {
   await axios.put(`/api/drivers/${id}`, { approved: newValue });
   dispatch(fetchDrivers());
  } catch (error) {
   console.error("Error updating approval status:", error);
  }
 };

 const handleExportToExcel = () => {
  const exportData = filteredDrivers.map((driver) => ({
   ID: driver._id,
   "Driver Name": driver.driverName,
   Mobile: driver.mobile,
   "IFSC Code": driver.ifscCode,
   "Account Number": driver.accountNumber,
   "Car Name": driver.carName || "",
   "Registration Number": driver.registrationNumber || "",
   "Service Type": driver.serviceType.join(", "),
   Approved: driver.approved ? "Yes" : "No",
   "Created At": new Date(driver.createdAt).toLocaleString(),
   "Updated At": new Date(driver.updatedAt).toLocaleString(),
  }));

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Drivers");

  const excelBuffer = XLSX.write(workbook, {
   bookType: "xlsx",
   type: "array",
  });

  const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(data, "Drivers.xlsx");
 };

 const columns = [
  { field: "id", headerName: "ID", width: 150 },
  { field: "driverName", headerName: "Driver Name", width: 150 },
  { field: "mobile", headerName: "Mobile", width: 150 },
  { field: "ifscCode", headerName: "IFSC Code", width: 150 },
  { field: "accountNumber", headerName: "Account Number", width: 150 },
  { field: "carName", headerName: "Car Name", width: 150 },
  {
   field: "isOnline",
   headerName: "Available",
   width: 150,
   renderCell: (params) => (
    <Box
     sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      color: params.value ? "green" : "red",
     }}
    >
     {params.value ? "Online" : "Offline"}
    </Box>
   ),
  },
  {
   field: "registrationNumber",
   headerName: "Registration Number",
   width: 180,
  },
  { field: "serviceType", headerName: "Service Type", width: 200 },
  {
   field: "approved",
   headerName: "Approval",
   width: 150,
   renderCell: (params) =>
    user && user.role === "superadmin" ? (
     <Switch
      checked={params.value}
      onChange={(event) =>
       handleSwitchChange(params.row.id, event.target.checked)
      }
     />
    ) : (
     <Typography>{params.value ? "Yes" : "No"}</Typography>
    ),
  },
  {
   field: "driverPhotoUrl",
   headerName: "Driver Photo",
   width: 150,
   renderCell: (params) => (
    <img
     src={params.value}
     alt="Driver"
     style={{ width: "50px", height: "auto", borderRadius: "50%" }}
    />
   ),
  },
  {
   field: "createdAt",
   headerName: "Created Date",
   width: 180,
   valueGetter: (params) => new Date(params.value).toLocaleString(), // Format the date
  },
  {
   field: "action",
   headerName: "Action",
   width: 150,
   renderCell: (params) => (
    <>
     <IconButton color="primary" onClick={() => handleOpen(params.row, "view")}>
      <VisibilityIcon />
     </IconButton>
     <IconButton color="primary" onClick={() => handleOpen(params.row, "edit")}>
      <EditIcon />
     </IconButton>
     <IconButton color="secondary" onClick={() => handleDelete(params.row.id)}>
      <DeleteIcon />
     </IconButton>
    </>
   ),
  },
 ];

 const rows = filteredDrivers.map((driver) => ({
  ...driver,
  id: driver._id,
 }));

 return (
  <Box sx={{ width: "100%", overflowX: "auto" }}>
   <Grid
    container
    spacing={2}
    sx={{ padding: "15px", mb: 2, border: "1px solid teal" }}
   >
    <Grid item xs={12} sm={6}>
     <TextField
      label="Search by Driver Name or Mobile"
      variant="outlined"
      fullWidth
      value={filters.searchTerm}
      onChange={(e) =>
       setFilters((prev) => ({ ...prev, searchTerm: e.target.value }))
      }
     />
    </Grid>
    <Grid item xs={12} sm={3}>
     <TextField
      label="Date"
      type="date"
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
      value={filters.date}
      onChange={(e) =>
       setFilters((prev) => ({ ...prev, date: e.target.value }))
      }
     />
    </Grid>
    <Grid item xs={12} sm={3}>
     <Button
      variant="contained"
      color="primary"
      onClick={handleExportToExcel}
      sx={{ height: "100%" }}
     >
      Download Excel
     </Button>
    </Grid>
   </Grid>

   <div
    style={{
     height: isMobile ? "70vh" : 600,
     width: "100%",
     maxWidth: "100%",
     overflowX: "auto",
    }}
   >
    <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
   </div>

   <Modal open={open} onClose={handleClose}>
    <Box
     sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: isMobile ? "100%" : "600px",
      maxHeight: "90vh",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      overflowY: "auto",
      borderRadius: 2,
     }}
    >
     {selectedDriver && (
      <>
       {isViewMode && (
        <>
         <Typography variant="h6" component="h2" gutterBottom>
          View Driver Details
         </Typography>
         <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
           <Typography variant="body1">
            Driver Name: {selectedDriver.driverName}
           </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
           <Typography variant="body1">
            Mobile: {selectedDriver.mobile}
           </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
           <Typography variant="body1">
            IFSC Code: {selectedDriver.ifscCode}
           </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
           <Typography variant="body1">
            Account Number: {selectedDriver.accountNumber}
           </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
           <Typography variant="body1">
            Car Name: {selectedDriver.carName}
           </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
           <Typography variant="body1">
            Registration Number: {selectedDriver.registrationNumber}
           </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
           <Typography variant="body1">
            Service Type: {selectedDriver.serviceType.join(", ")}
           </Typography>
          </Grid>
          {[
           { name: "carRcUrl", label: "Car RC" },
           { name: "insuranceUrl", label: "Insurance" },
           { name: "pollutionUrl", label: "Pollution" },
           { name: "fitnessUrl", label: "Fitness" },
           { name: "dlUrl", label: "Driver License" },
           { name: "driverPhotoUrl", label: "Driver Photo" },
           { name: "carFrontendUrl", label: "Car Frontend" },
           { name: "aadhaarFrontUrl", label: "Aadhaar Front" },
           { name: "aadhaarBackUrl", label: "Aadhaar Back" },
           { name: "panCardUrl", label: "PAN Card" },
           { name: "checkbookUrl", label: "Checkbook" },
          ].map((field) => (
           <Grid item xs={12} sm={6} key={field.name}>
            <Typography variant="body1">
             {field.label}:{" "}
             <img
              src={selectedDriver[field.name]}
              alt={field.label}
              style={{
               width: "100px",
               height: "auto",
               cursor: "pointer",
              }}
              onClick={() => window.open(selectedDriver[field.name], "_blank")}
             />
            </Typography>
           </Grid>
          ))}
         </Grid>
         <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
          Close
         </Button>
        </>
       )}
       {isEditMode && (
        <DistributorForm
         isEditMode={isEditMode}
         initialData={selectedDriver}
         onClose={handleClose}
        />
       )}
      </>
     )}
    </Box>
   </Modal>
  </Box>
 );
};

export default DataGridComponent;

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { DataGrid } from "@mui/x-data-grid";
// import {
//  Button,
//  Modal,
//  Box,
//  Typography,
//  IconButton,
//  Grid,
//  Divider,
//  Switch,
//  TextField,
//  useMediaQuery,
//  useTheme,
// } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import DistributorForm from "../distributer/DistributorForm";
// import { fetchDrivers, deleteDriver } from "../../redux/driver/action";
// import axios from "axios";
// import * as XLSX from "xlsx";
// import { saveAs } from "file-saver";

// const DataGridComponent = () => {
//  const dispatch = useDispatch();
//  const { drivers } = useSelector((state) => state.driver);
//  const { user } = useSelector((state) => state.auth);

//  const [open, setOpen] = useState(false);
//  const [selectedDriver, setSelectedDriver] = useState(null);
//  const [isEditMode, setIsEditMode] = useState(false);
//  const [isViewMode, setIsViewMode] = useState(false);
//  const [filteredDrivers, setFilteredDrivers] = useState(drivers);

//  const [filters, setFilters] = useState({
//   carName: "",
//   registrationNumber: "",
//   mobile: "",
//   date: "",
//  });

//  const theme = useTheme();
//  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//  useEffect(() => {
//   dispatch(fetchDrivers());
//  }, [dispatch]);

//  useEffect(() => {
//   applyFilters();
//  }, [drivers, filters]);

//  const applyFilters = () => {
//   let filtered = drivers;

//   if (filters.carName) {
//    filtered = filtered.filter((driver) =>
//     driver.carName?.toLowerCase().includes(filters.carName.toLowerCase())
//    );
//   }
//   if (filters.registrationNumber) {
//    filtered = filtered.filter((driver) =>
//     driver.registrationNumber
//      ?.toLowerCase()
//      .includes(filters.registrationNumber.toLowerCase())
//    );
//   }
//   if (filters.mobile) {
//    filtered = filtered.filter((driver) =>
//     driver.mobile?.includes(filters.mobile)
//    );
//   }
//   if (filters.date) {
//    filtered = filtered.filter((driver) => {
//     const createdAtDate = new Date(driver.createdAt).toLocaleDateString();
//     return createdAtDate === new Date(filters.date).toLocaleDateString();
//    });
//   }

//   setFilteredDrivers(filtered);
//  };

//  const handleOpen = (driver, mode = "view") => {
//   setSelectedDriver(driver);
//   setIsEditMode(mode === "edit");
//   setIsViewMode(mode === "view");
//   setOpen(true);
//  };

//  const handleClose = () => {
//   setOpen(false);
//   setSelectedDriver(null);
//   setIsEditMode(false);
//   setIsViewMode(false);
//  };

//  const handleDelete = (id) => {
//   dispatch(deleteDriver(id));
//  };

//  const handleSwitchChange = async (id, newValue) => {
//   try {
//    await axios.put(`/api/drivers/${id}`, { approved: newValue });
//    dispatch(fetchDrivers());
//   } catch (error) {
//    console.error("Error updating approval status:", error);
//   }
//  };

//  const handleExportToExcel = () => {
//   const exportData = filteredDrivers.map((driver) => ({
//    ID: driver._id,
//    "Driver Name": driver.driverName,
//    Mobile: driver.mobile,
//    "IFSC Code": driver.ifscCode,
//    "Account Number": driver.accountNumber,
//    "Car Name": driver.carName || "",
//    "Registration Number": driver.registrationNumber || "",
//    "Service Type": driver.serviceType.join(", "),
//    Approved: driver.approved ? "Yes" : "No",
//    "Created At": new Date(driver.createdAt).toLocaleString(),
//    "Updated At": new Date(driver.updatedAt).toLocaleString(),
//   }));

//   const worksheet = XLSX.utils.json_to_sheet(exportData);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Drivers");

//   const excelBuffer = XLSX.write(workbook, {
//    bookType: "xlsx",
//    type: "array",
//   });

//   const data = new Blob([excelBuffer], { type: "application/octet-stream" });
//   saveAs(data, "Drivers.xlsx");
//  };

//  const columns = [
//   { field: "id", headerName: "ID", width: 150 },
//   { field: "driverName", headerName: "Driver Name", width: 150 },
//   { field: "mobile", headerName: "Mobile", width: 150 },
//   { field: "ifscCode", headerName: "IFSC Code", width: 150 },
//   { field: "accountNumber", headerName: "Account Number", width: 150 },
//   { field: "carName", headerName: "Car Name", width: 150 },
//   {
//    field: "isOnline",
//    headerName: "Available",
//    width: 150,
//    renderCell: (params) => (
//     <Box
//      sx={{
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//       height: "100%",
//       color: params.value ? "green" : "red",
//      }}
//     >
//      {params.value ? "Online" : "Offline"}
//     </Box>
//    ),
//   },
//   {
//    field: "registrationNumber",
//    headerName: "Registration Number",
//    width: 180,
//   },
//   { field: "serviceType", headerName: "Service Type", width: 200 },
//   {
//    field: "approved",
//    headerName: "Approval",
//    width: 150,
//    renderCell: (params) =>
//     user && user.role === "superadmin" ? (
//      <Switch
//       checked={params.value}
//       onChange={(event) =>
//        handleSwitchChange(params.row.id, event.target.checked)
//       }
//      />
//     ) : (
//      <Typography>{params.value ? "Yes" : "No"}</Typography>
//     ),
//   },
//   {
//    field: "driverPhotoUrl",
//    headerName: "Driver Photo",
//    width: 150,
//    renderCell: (params) => (
//     <img
//      src={params.value}
//      alt="Driver"
//      style={{ width: "50px", height: "auto", borderRadius: "50%" }}
//     />
//    ),
//   },
//   {
//    field: "action",
//    headerName: "Action",
//    width: 150,
//    renderCell: (params) => (
//     <>
//      <IconButton color="primary" onClick={() => handleOpen(params.row, "view")}>
//       <VisibilityIcon />
//      </IconButton>
//      <IconButton color="primary" onClick={() => handleOpen(params.row, "edit")}>
//       <EditIcon />
//      </IconButton>
//      <IconButton color="secondary" onClick={() => handleDelete(params.row.id)}>
//       <DeleteIcon />
//      </IconButton>
//     </>
//    ),
//   },
//  ];

//  const rows = filteredDrivers.map((driver) => ({
//   ...driver,
//   id: driver._id,
//  }));

//  return (
//   <Box sx={{ width: "100%", overflowX: "auto" }}>
//    <Grid
//     container
//     spacing={2}
//     sx={{ padding: "15px", mb: 2, border: "1px solid teal" }}
//    >
//     <Grid item xs={12} sm={3}>
//      <TextField
//       label="Car Name"
//       variant="outlined"
//       fullWidth
//       value={filters.carName}
//       onChange={(e) =>
//        setFilters((prev) => ({ ...prev, carName: e.target.value }))
//       }
//      />
//     </Grid>
//     <Grid item xs={12} sm={3}>
//      <TextField
//       label="Registration Number"
//       variant="outlined"
//       fullWidth
//       value={filters.registrationNumber}
//       onChange={(e) =>
//        setFilters((prev) => ({
//         ...prev,
//         registrationNumber: e.target.value,
//        }))
//       }
//      />
//     </Grid>
//     <Grid item xs={12} sm={3}>
//      <TextField
//       label="Mobile"
//       variant="outlined"
//       fullWidth
//       value={filters.mobile}
//       onChange={(e) =>
//        setFilters((prev) => ({ ...prev, mobile: e.target.value }))
//       }
//      />
//     </Grid>
//     <Grid item xs={12} sm={3}>
//      <TextField
//       label="Date"
//       type="date"
//       variant="outlined"
//       fullWidth
//       InputLabelProps={{ shrink: true }}
//       value={filters.date}
//       onChange={(e) =>
//        setFilters((prev) => ({ ...prev, date: e.target.value }))
//       }
//      />
//     </Grid>
//     <Grid item xs={12} sm={3}>
//      <Button
//       variant="contained"
//       color="primary"
//       onClick={handleExportToExcel}
//       sx={{ height: "100%" }}
//      >
//       Download Excel
//      </Button>
//     </Grid>
//    </Grid>

//    <div
//     style={{
//      height: isMobile ? "70vh" : 600,
//      width: "100%",
//      maxWidth: "100%",
//      overflowX: "auto",
//     }}
//    >
//     <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
//    </div>

//    <Modal open={open} onClose={handleClose}>
//     <Box
//      sx={{
//       position: "absolute",
//       top: "50%",
//       left: "50%",
//       transform: "translate(-50%, -50%)",
//       width: "90%",
//       maxWidth: isMobile ? "100%" : "600px",
//       maxHeight: "90vh",
//       bgcolor: "background.paper",
//       boxShadow: 24,
//       p: 4,
//       overflowY: "auto",
//       borderRadius: 2,
//      }}
//     >
//      {selectedDriver && (
//       <>
//        {isViewMode && (
//         <>
//          <Typography variant="h6" component="h2" gutterBottom>
//           View Driver Details
//          </Typography>
//          <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//            <Typography variant="body1">
//             Driver Name: {selectedDriver.driverName}
//            </Typography>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//            <Typography variant="body1">
//             Mobile: {selectedDriver.mobile}
//            </Typography>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//            <Typography variant="body1">
//             IFSC Code: {selectedDriver.ifscCode}
//            </Typography>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//            <Typography variant="body1">
//             Account Number: {selectedDriver.accountNumber}
//            </Typography>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//            <Typography variant="body1">
//             Car Name: {selectedDriver.carName}
//            </Typography>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//            <Typography variant="body1">
//             Registration Number: {selectedDriver.registrationNumber}
//            </Typography>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//            <Typography variant="body1">
//             Service Type: {selectedDriver.serviceType.join(", ")}
//            </Typography>
//           </Grid>
//           {[
//            { name: "carRcUrl", label: "Car RC" },
//            { name: "insuranceUrl", label: "Insurance" },
//            { name: "pollutionUrl", label: "Pollution" },
//            { name: "fitnessUrl", label: "Fitness" },
//            { name: "dlUrl", label: "Driver License" },
//            { name: "driverPhotoUrl", label: "Driver Photo" },
//            { name: "carFrontendUrl", label: "Car Frontend" },
//            { name: "aadhaarFrontUrl", label: "Aadhaar Front" },
//            { name: "aadhaarBackUrl", label: "Aadhaar Back" },
//            { name: "panCardUrl", label: "PAN Card" },
//            { name: "checkbookUrl", label: "Checkbook" },
//           ].map((field) => (
//            <Grid item xs={12} sm={6} key={field.name}>
//             <Typography variant="body1">
//              {field.label}:{" "}
//              <img
//               src={selectedDriver[field.name]}
//               alt={field.label}
//               style={{
//                width: "100px",
//                height: "auto",
//                cursor: "pointer",
//               }}
//               onClick={() => window.open(selectedDriver[field.name], "_blank")}
//              />
//             </Typography>
//            </Grid>
//           ))}
//          </Grid>
//          <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
//           Close
//          </Button>
//         </>
//        )}
//        {isEditMode && (
//         <DistributorForm
//          isEditMode={isEditMode}
//          initialData={selectedDriver}
//          onClose={handleClose}
//         />
//        )}
//       </>
//      )}
//     </Box>
//    </Modal>
//   </Box>
//  );
// };

// export default DataGridComponent;
