// src/redux/store.js
import {
 applyMiddleware,
 combineReducers,
 compose,
 legacy_createStore,
} from "redux";
import { thunk } from "redux-thunk";
import { driverReducer } from "./driver/reducer";
import { authReducer } from "./auth/reducer";
import { notificationReducer } from "./notification/reducer";
import { rideReducer } from "./rides/reducer";
import { reportReducer, salesReportReducer } from "./sales/reducer";

// Combine all reducers into the root reducer
const rootReducer = combineReducers({
 driver: driverReducer,
 auth: authReducer,
 notification: notificationReducer,
 rides:rideReducer,
 sales:reportReducer
});

// Setting up Redux DevTools and middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the Redux store with the rootReducer and middleware
const store = legacy_createStore(
 rootReducer,
 composeEnhancers(applyMiddleware(thunk))
);

export { store };
