// src/redux/reducers/authReducer.js
import * as types from "./actionTypes";

const initialState = {
 token: localStorage.getItem("token"),
 isAuthenticated: false,
 loading: false,
 user: null,
 error: null,
};

export const authReducer = (state = initialState, action) => {
 const { type, payload } = action;

 switch (type) {
  case types.LOGIN_REQUEST:
  case types.LOAD_USER_REQUEST:
   return {
    ...state,
    loading: true,
   };

  case types.LOGIN_SUCCESS:
   return {
    ...state,
    token: payload.token,
    isAuthenticated: true,
    loading: false,
   };

  case types.LOAD_USER_SUCCESS:
   return {
    ...state,
    isAuthenticated: true,
    loading: false,
    user: payload,
   };

  case types.LOGIN_FAILURE:
  case types.LOAD_USER_FAILURE:
   return {
    ...state,
    token: null,
    isAuthenticated: false,
    loading: false,
    error: payload,
   };

  case types.LOGOUT:
   return {
    ...state,
    token: null,
    isAuthenticated: false,
    user: null,
   };

  default:
   return state;
 }
};
