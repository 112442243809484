import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { store } from "./redux/store";

// Define the MUI theme
const theme = createTheme({
 palette: {
  primary: {
   main: "#1976d2", // Primary color
  },
  secondary: {
   main: "#dc004e", // Secondary color
  },
 },
});

// Log the initial state of the Redux store
console.log("store:", store.getState());

// Initialize the root element for React
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 // Wrap the application in ThemeProvider and Redux Provider
 <ThemeProvider theme={theme}>
  <CssBaseline />
  <Provider store={store}>
   <BrowserRouter>
    <App />
   </BrowserRouter>
  </Provider>
 </ThemeProvider>
);

// Measure performance of the app
reportWebVitals();
