import React from "react";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
const DistributorHelp = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f9f9f9",
        padding: "40px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap:4
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "row" }}
        borderRadius={"6px"}
        border={"1px solid grey"}
        width={"100%"}
        justifyContent={"start"}
        alignItems={"start"}
        py={4}
        px={3}
      >
        <Box>
          <Typography fontWeight={800} mb={2}>
            Welcome
          </Typography>
          <Typography mb={2}>
            This is our page, where you can access help and toolkit to kickstart
            your journey.
          </Typography>
          <Typography>
            Missed out on something?{" "}
            <Typography
              color={"#2B6CB0"}
              component="span"
              sx={{ cursor: "pointer" }}
            >
              Restart the app tour
            </Typography>
          </Typography>
        </Box>
      </Box>
 
      <Grid container border={"1px solid red"} justifyContent={"space-between"}>
        <Grid
          item
          xs={4}
          borderRadius={"6px"}
          border={"1px solid grey"}
          py={3}
          px={3}
          height={"30vh"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography fontWeight={600}>Upstox Toolkit</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "start",
              }}
              mt={2}
            >
              <Box>
                <FiberManualRecordIcon sx={{ fontSize: "0.6rem" }} />
              </Box>
              <Box>
                <Typography fontSize={"0.8rem"}>
                  Share the Upstox Brochure with your customers and help them
                  understand us better!{" "}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Button
            sx={{
              width: "30%",
              borderRadius: "50px",
              color: "white",
              background: "#2B6CB0",
              fontSize: "0.7rem",
              px: 2,
              py: 1,
              "&:hover": {
                background: "#1A4F8B", // Darker shade for hover effect
                transform: "scale(1.05)", // Slightly enlarge the button
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow on hover
              },
              transition: "background 0.3s, transform 0.3s, box-shadow 0.3s", // Smooth transition
            }}
          >
            Download
          </Button>
        </Grid>

        <Grid
          item
          xs={4}
          borderRadius={"6px"}
          border={"1px solid grey"}
          py={3}
          px={3}
          height={"30vh"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography fontWeight={600}>Need Help?</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "start",
              }}
              mt={2}
            >
              <Box>
                <FiberManualRecordIcon sx={{ fontSize: "0.6rem" }} />
              </Box>
              <Box>
                <Typography fontSize={"0.8rem"}>
                  Account Opening Resources
                </Typography>
              </Box>
            </Box>
          </Box>

          <Button
            sx={{
              width: "30%",
              borderRadius: "50px",
              color: "white",
              background: "#2B6CB0",
              fontSize: "0.7rem",
              px: 2,
              py: 1,
              "&:hover": {
                background: "#1A4F8B",
                transform: "scale(1.05)",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              },
              transition: "background 0.3s, transform 0.3s, box-shadow 0.3s",
            }}
          >
            CLICK HERE
          </Button>
        </Grid>

        <Grid
          item
          xs={4}
          borderRadius={"6px"}
          border={"1px solid grey"}
          py={3}
          px={3}
          height={"30vh"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography fontWeight={600}>Help</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 1,
              }}
              mt={1}
            >
              <Typography fontSize={"0.8rem"}>
                We're here to help you
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                  }}
                  mt={1}
                >
                  <Box>
                    <PhoneCallbackIcon sx={{ fontSize: "0.9rem" }} />
                  </Box>
                  <Box>
                    <Typography fontSize={"0.8rem"}>
                      022-41792999 (All divading Days - 8 am to 5 pm)
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                  }}
                  mt={1}
                >
                  <Box>
                    <LocationOnIcon sx={{ fontSize: "0.9rem" }} />
                  </Box>
                  <Box>
                    <Typography fontSize={"0.8rem"}>
                      022-41792999 (All divading Days - 8 am to 5 pm)
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                  }}
                  mt={1}
                >
                  <Box>
                    <EmailIcon sx={{ fontSize: "0.9rem" }} />
                  </Box>
                  <Box>
                    <Typography fontSize={"0.8rem"}>
                      022-41792999 (All divading Days - 8 am to 5 pm)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DistributorHelp;
