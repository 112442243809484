import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import {
 Search,
 Notifications,
 Settings,
 PersonAdd,
 Logout,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import {
 Menu,
 MenuItem,
 Tooltip,
 useMediaQuery,
 Drawer as MuiTemporaryDrawer,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { logoutUser } from "../redux/auth/action";

const drawerWidth = 240;

const openedMixin = (theme) => ({
 width: drawerWidth,
 transition: theme.transitions.create("width", {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.enteringScreen,
 }),
 overflowX: "hidden",
 backgroundColor: "#1A202C",
 color: "#ffffff",
});

const closedMixin = (theme) => ({
 transition: theme.transitions.create("width", {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
 }),
 overflowX: "hidden",
 width: `calc(${theme.spacing(7)} + 1px)`,
 [theme.breakpoints.up("sm")]: {
  width: `calc(${theme.spacing(8)} + 1px)`,
 },
 backgroundColor: "#1A202C",
 color: "#ffffff",
});

const DrawerHeader = styled("div")(({ theme }) => ({
 display: "flex",
 alignItems: "center",
 justifyContent: "flex-end",
 padding: theme.spacing(0, 1),
 ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
 shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
 zIndex: theme.zIndex.drawer + 1,
 backgroundColor: "#2D3748",
 boxShadow: "none",
 color: "#ffffff",
 transition: theme.transitions.create(["width", "margin"], {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
 }),
 ...(open && {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(["width", "margin"], {
   easing: theme.transitions.easing.sharp,
   duration: theme.transitions.duration.enteringScreen,
  }),
 }),
}));

const Drawer = styled(MuiDrawer, {
 shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
 width: drawerWidth,
 flexShrink: 0,
 whiteSpace: "nowrap",
 boxSizing: "border-box",
 ...(open && {
  ...openedMixin(theme),
  "& .MuiDrawer-paper": openedMixin(theme),
 }),
 ...(!open && {
  ...closedMixin(theme),
  "& .MuiDrawer-paper": closedMixin(theme),
 }),
}));

export default function MiniDrawer({ children, menuItems }) {
 const theme = useTheme();
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const location = useLocation();
 const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

 const [drawerOpen, setDrawerOpen] = useState(false);
 const [menuAnchorEl, setMenuAnchorEl] = useState(null);

 const handleDrawerToggle = () => {
  setDrawerOpen(!drawerOpen);
 };

 const handleDrawerClose = () => {
  setDrawerOpen(false);
 };

 const handleMenuClick = (event) => {
  setMenuAnchorEl(event.currentTarget);
 };

 const handleMenuClose = () => {
  setMenuAnchorEl(null);
 };

 const handleLogout = () => {
  dispatch(logoutUser());
 };

 const isMenuOpen = Boolean(menuAnchorEl);

 const handleProfileClick = () => {
  navigate("/admin/profile");
  handleMenuClose();
 };

 const renderDrawer = () => (
    <List>
      {menuItems?.map((item) => {
        const isActive = location.pathname === item.link;
        return (
          <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              component={Link}
              to={item.link}
              sx={{
                minHeight: 48,
                justifyContent: drawerOpen ? "initial" : "center",
                px: 2.5,
                backgroundColor: isActive ? "#2B6CB0" : "inherit",
                color: isActive ? "#ffffff" : "inherit",
                "&:hover": {
                  backgroundColor: "#4A5568",
                },
              }}
              onClick={handleDrawerClose} 
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: drawerOpen ? 3 : "auto",
                  justifyContent: "center",
                  color: "#ffffff",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} sx={{ opacity: drawerOpen ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
  

 return (
  <Box sx={{ display: "flex", width: "100%" }}>
   <CssBaseline />
   <AppBar position="fixed" open={drawerOpen}>
    <Toolbar>
     <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={handleDrawerToggle}
      edge="start"
      sx={{
       marginRight: 5,
      }}
     >
      <MenuIcon />
     </IconButton>
     <Typography variant="h6" noWrap component="div">
      JANTA TAXI
     </Typography>
     <Box sx={{ flexGrow: 1 }} />
     <Box
      sx={{
       position: "relative",
       borderRadius: "25px",
       backgroundColor: "#4A5568",
       marginRight: "16px",
       width: "200px",
      }}
     >
      <InputBase
       placeholder="Search…"
       sx={{
        paddingLeft: "10px",
        width: "100%",
        color: "white",
       }}
       inputProps={{ "aria-label": "search" }}
      />
      <IconButton
       type="submit"
       sx={{ position: "absolute", right: 0, top: 0, color: "white" }}
      >
       <Search />
      </IconButton>
     </Box>
     <IconButton color="inherit">
      <Notifications />
     </IconButton>
     <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
      <Tooltip title="Account settings">
       <IconButton
        onClick={handleMenuClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={isMenuOpen ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
       >
        <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
       </IconButton>
      </Tooltip>
     </Box>
     <Menu
      anchorEl={menuAnchorEl}
      id="account-menu"
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      PaperProps={{
       elevation: 0,
       sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "& .MuiAvatar-root": {
         width: 32,
         height: 32,
         ml: -0.5,
         mr: 1,
        },
        "&::before": {
         content: '""',
         display: "block",
         position: "absolute",
         top: 0,
         right: 14,
         width: 10,
         height: 10,
         bgcolor: "background.paper",
         transform: "translateY(-50%) rotate(45deg)",
         zIndex: 0,
        },
       },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
     >
      <MenuItem onClick={handleProfileClick}>
       <Avatar /> Profile
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
       <Avatar /> My account
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleMenuClose}>
       <ListItemIcon>
        <PersonAdd fontSize="small" />
       </ListItemIcon>
       Add another account
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
       <ListItemIcon>
        <Settings fontSize="small" />
       </ListItemIcon>
       Settings
      </MenuItem>
      <MenuItem
       onClick={() => {
        handleMenuClose();
        handleLogout();
       }}
      >
       <ListItemIcon>
        <Logout fontSize="small" />
       </ListItemIcon>
       Logout
      </MenuItem>
     </Menu>
    </Toolbar>
   </AppBar>

   {isMobile ? (
    <MuiTemporaryDrawer
     variant="temporary"
     open={drawerOpen}
     onClose={handleDrawerToggle}
     ModalProps={{
      keepMounted: true, // Better open performance on mobile.
     }}
     sx={{
      "& .MuiDrawer-paper": {
       boxSizing: "border-box",
       width: drawerWidth,
       backgroundColor: "#1A202C",
       color: "#ffffff",
      },
     }}
    >
     {renderDrawer()}
    </MuiTemporaryDrawer>
   ) : (
    <Drawer variant="permanent" open={drawerOpen}>
     <DrawerHeader>
      <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
       {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
     </DrawerHeader>
     <Divider sx={{ backgroundColor: "#4A5568" }} />
     {renderDrawer()}
     <Divider sx={{ backgroundColor: "#4A5568" }} />
    </Drawer>
   )}

   <Box
    component="main"
    sx={{ flexGrow: 1, p: 3, backgroundColor: "#EDF2F7", minHeight: "100vh" }}
   >
    <DrawerHeader />
    {children}
   </Box>
  </Box>
 );
}

// import React from "react";
// import { styled, useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import MuiDrawer from "@mui/material/Drawer";
// import MuiAppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import List from "@mui/material/List";
// import CssBaseline from "@mui/material/CssBaseline";
// import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import InputBase from "@mui/material/InputBase";
// import {
//  Search,
//  Notifications,
//  Settings,
//  PersonAdd,
//  Logout,
// } from "@mui/icons-material";
// import Avatar from "@mui/material/Avatar";
// import { Menu, MenuItem, Tooltip } from "@mui/material";
// import { useDispatch } from "react-redux";
// import { logoutUser } from "../redux/auth/action";

// const drawerWidth = 240;

// // Styled components and other styles
// const openedMixin = (theme) => ({
//  width: drawerWidth,
//  transition: theme.transitions.create("width", {
//   easing: theme.transitions.easing.sharp,
//   duration: theme.transitions.duration.enteringScreen,
//  }),
//  overflowX: "hidden",
//  backgroundColor: "#1A202C",
//  color: "#ffffff",
// });

// const closedMixin = (theme) => ({
//  transition: theme.transitions.create("width", {
//   easing: theme.transitions.easing.sharp,
//   duration: theme.transitions.duration.leavingScreen,
//  }),
//  overflowX: "hidden",
//  width: `calc(${theme.spacing(7)} + 1px)`,
//  [theme.breakpoints.up("sm")]: {
//   width: `calc(${theme.spacing(8)} + 1px)`,
//  },
//  backgroundColor: "#1A202C",
//  color: "#ffffff",
// });

// const DrawerHeader = styled("div")(({ theme }) => ({
//  display: "flex",
//  alignItems: "center",
//  justifyContent: "flex-end",
//  padding: theme.spacing(0, 1),
//  ...theme.mixins.toolbar,
// }));

// const AppBar = styled(MuiAppBar, {
//  shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//  zIndex: theme.zIndex.drawer + 1,
//  backgroundColor: "#2D3748",
//  boxShadow: "none",
//  color: "#ffffff",
//  transition: theme.transitions.create(["width", "margin"], {
//   easing: theme.transitions.easing.sharp,
//   duration: theme.transitions.duration.leavingScreen,
//  }),
//  ...(open && {
//   marginLeft: drawerWidth,
//   width: `calc(100% - ${drawerWidth}px)`,
//   transition: theme.transitions.create(["width", "margin"], {
//    easing: theme.transitions.easing.sharp,
//    duration: theme.transitions.duration.enteringScreen,
//   }),
//  }),
// }));

// const Drawer = styled(MuiDrawer, {
//  shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//  width: drawerWidth,
//  flexShrink: 0,
//  whiteSpace: "nowrap",
//  boxSizing: "border-box",
//  ...(open && {
//   ...openedMixin(theme),
//   "& .MuiDrawer-paper": openedMixin(theme),
//  }),
//  ...(!open && {
//   ...closedMixin(theme),
//   "& .MuiDrawer-paper": closedMixin(theme),
//  }),
// }));
// export default function MiniDrawer({ children, menuItems }) {
//  const theme = useTheme();
//  const dispatch = useDispatch();
//  const navigate = useNavigate();
//  const [drawerOpen, setDrawerOpen] = React.useState(false);
//  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
//  const location = useLocation();

//  const handleDrawerOpen = () => {
//   setDrawerOpen(true);
//  };

//  const handleDrawerClose = () => {
//   setDrawerOpen(false);
//  };

//  const handleMenuClick = (event) => {
//   setMenuAnchorEl(event.currentTarget);
//  };

//  const handleMenuClose = () => {
//   setMenuAnchorEl(null);
//  };

//  const handleLogout = () => {
//   dispatch(logoutUser());
//  };

//  const isMenuOpen = Boolean(menuAnchorEl);

//  const handleProfileClick = () => {
//   navigate("/admin/profile");
//   handleMenuClose();
//  };

//  return (
//   <Box sx={{ display: "flex" }}>
//    <CssBaseline />
//    <AppBar position="fixed" open={drawerOpen}>
//     <Toolbar>
//      <IconButton
//       color="inherit"
//       aria-label="open drawer"
//       onClick={handleDrawerOpen}
//       edge="start"
//       sx={{
//        marginRight: 5,
//        ...(drawerOpen && { display: "none" }),
//       }}
//      >
//       <MenuIcon />
//      </IconButton>
//      <Typography variant="h6" noWrap component="div">
//       JANTA TAXI
//      </Typography>
//      <Box sx={{ flexGrow: 1 }} />
//      <Box
//       sx={{
//        position: "relative",
//        borderRadius: "25px",
//        backgroundColor: "#4A5568",
//        marginRight: "16px",
//        width: "200px",
//       }}
//      >
//       <InputBase
//        placeholder="Search…"
//        sx={{
//         paddingLeft: "10px",
//         width: "100%",
//         color: "white",
//        }}
//        inputProps={{ "aria-label": "search" }}
//       />
//       <IconButton
//        type="submit"
//        sx={{ position: "absolute", right: 0, top: 0, color: "white" }}
//       >
//        <Search />
//       </IconButton>
//      </Box>
//      <IconButton color="inherit">
//       <Notifications />
//      </IconButton>
//      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
//       <Tooltip title="Account settings">
//        <IconButton
//         onClick={handleMenuClick}
//         size="small"
//         sx={{ ml: 2 }}
//         aria-controls={isMenuOpen ? "account-menu" : undefined}
//         aria-haspopup="true"
//         aria-expanded={isMenuOpen ? "true" : undefined}
//        >
//         <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
//        </IconButton>
//       </Tooltip>
//      </Box>
//      <Menu
//       anchorEl={menuAnchorEl}
//       id="account-menu"
//       open={isMenuOpen}
//       onClose={handleMenuClose}
//       onClick={handleMenuClose}
//       PaperProps={{
//        elevation: 0,
//        sx: {
//         overflow: "visible",
//         filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
//         mt: 1.5,
//         "& .MuiAvatar-root": {
//          width: 32,
//          height: 32,
//          ml: -0.5,
//          mr: 1,
//         },
//         "&::before": {
//          content: '""',
//          display: "block",
//          position: "absolute",
//          top: 0,
//          right: 14,
//          width: 10,
//          height: 10,
//          bgcolor: "background.paper",
//          transform: "translateY(-50%) rotate(45deg)",
//          zIndex: 0,
//         },
//        },
//       }}
//       transformOrigin={{ horizontal: "right", vertical: "top" }}
//       anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
//      >
//       <MenuItem onClick={handleProfileClick}>
//        <Avatar /> Profile
//       </MenuItem>
//       <MenuItem onClick={handleMenuClose}>
//        <Avatar /> My account
//       </MenuItem>
//       <Divider />
//       <MenuItem onClick={handleMenuClose}>
//        <ListItemIcon>
//         <PersonAdd fontSize="small" />
//        </ListItemIcon>
//        Add another account
//       </MenuItem>
//       <MenuItem onClick={handleMenuClose}>
//        <ListItemIcon>
//         <Settings fontSize="small" />
//        </ListItemIcon>
//        Settings
//       </MenuItem>
//       <MenuItem
//        onClick={() => {
//         handleMenuClose();
//         handleLogout();
//        }}
//       >
//        <ListItemIcon>
//         <Logout fontSize="small" />
//        </ListItemIcon>
//        Logout
//       </MenuItem>
//      </Menu>
//     </Toolbar>
//    </AppBar>
//    <Drawer variant="permanent" open={drawerOpen}>
//     <DrawerHeader>
//      <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
//       {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
//      </IconButton>
//     </DrawerHeader>
//     <Divider sx={{ backgroundColor: "#4A5568" }} />
//     <List>
//      {menuItems?.map((item, index) => {
//       const isActive = location.pathname === item.link;
//       return (
//        <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
//         <ListItemButton
//          component={Link}
//          to={item.link}
//          sx={{
//           minHeight: 48,
//           justifyContent: drawerOpen ? "initial" : "center",
//           px: 2.5,
//           backgroundColor: isActive ? "#2B6CB0" : "inherit",
//           color: isActive ? "#ffffff" : "inherit",
//           "&:hover": {
//            backgroundColor: "#4A5568",
//           },
//          }}
//         >
//          <ListItemIcon
//           sx={{
//            minWidth: 0,
//            mr: drawerOpen ? 3 : "auto",
//            justifyContent: "center",
//            color: "#ffffff",
//           }}
//          >
//           {item.icon}
//          </ListItemIcon>
//          <ListItemText
//           primary={item.text}
//           sx={{ opacity: drawerOpen ? 1 : 0 }}
//          />
//         </ListItemButton>
//        </ListItem>
//       );
//      })}
//     </List>
//     <Divider sx={{ backgroundColor: "#4A5568" }} />
//    </Drawer>
//    <Box
//     component="main"
//     sx={{ flexGrow: 1, p: 3, backgroundColor: "#EDF2F7", minHeight: "100vh" }}
//    >
//     <DrawerHeader />
//     {children}
//    </Box>
//   </Box>
//  );
// }

// // export default function MiniDrawer({ children, menuItems }) {
// //  const theme = useTheme();
// //  const dispatch = useDispatch();
// //  const navigate = useNavigate();
// //  const [drawerOpen, setDrawerOpen] = React.useState(false);
// //  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
// //  const location = useLocation();

// //  const handleDrawerOpen = () => {
// //   setDrawerOpen(true);
// //  };

// //  const handleDrawerClose = () => {
// //   setDrawerOpen(false);
// //  };

// //  const handleMenuClick = (event) => {
// //   setMenuAnchorEl(event.currentTarget);
// //  };

// //  const handleMenuClose = () => {
// //   setMenuAnchorEl(null);
// //  };

// //  const handleLogout = () => {
// //   dispatch(logoutUser());
// //  };

// //  const isMenuOpen = Boolean(menuAnchorEl);

// //  const handleProfileClick = () => {
// //   navigate("/admin/profile"); // Navigate to profile page
// //   handleMenuClose();
// //  };

// //  return (
// //   <Box sx={{ display: "flex" }}>
// //    <CssBaseline />
// //    <AppBar position="fixed" open={drawerOpen}>
// //     <Toolbar>
// //      <IconButton
// //       color="inherit"
// //       aria-label="open drawer"
// //       onClick={handleDrawerOpen}
// //       edge="start"
// //       sx={{
// //        marginRight: 5,
// //        ...(drawerOpen && { display: "none" }),
// //       }}
// //      >
// //       <MenuIcon />
// //      </IconButton>
// //      <Typography variant="h6" noWrap component="div">
// //       Taxi Rider
// //      </Typography>
// //      <Box sx={{ flexGrow: 1 }} />
// //      <Box
// //       sx={{
// //        position: "relative",
// //        borderRadius: "25px",
// //        backgroundColor: "#4A5568",
// //        marginRight: "16px",
// //        width: "200px",
// //       }}
// //      >
// //       <InputBase
// //        placeholder="Search…"
// //        sx={{
// //         paddingLeft: "10px",
// //         width: "100%",
// //         color: "white",
// //        }}
// //        inputProps={{ "aria-label": "search" }}
// //       />
// //       <IconButton
// //        type="submit"
// //        sx={{ position: "absolute", right: 0, top: 0, color: "white" }}
// //       >
// //        <Search />
// //       </IconButton>
// //      </Box>
// //      <IconButton color="inherit">
// //       <Notifications />
// //      </IconButton>
// //      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
// //       <Tooltip title="Account settings">
// //        <IconButton
// //         onClick={handleMenuClick}
// //         size="small"
// //         sx={{ ml: 2 }}
// //         aria-controls={isMenuOpen ? "account-menu" : undefined}
// //         aria-haspopup="true"
// //         aria-expanded={isMenuOpen ? "true" : undefined}
// //        >
// //         <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
// //        </IconButton>
// //       </Tooltip>
// //      </Box>
// //      <Menu
// //       anchorEl={menuAnchorEl}
// //       id="account-menu"
// //       open={isMenuOpen}
// //       onClose={handleMenuClose}
// //       onClick={handleMenuClose}
// //       PaperProps={{
// //        elevation: 0,
// //        sx: {
// //         overflow: "visible",
// //         filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
// //         mt: 1.5,
// //         "& .MuiAvatar-root": {
// //          width: 32,
// //          height: 32,
// //          ml: -0.5,
// //          mr: 1,
// //         },
// //         "&::before": {
// //          content: '""',
// //          display: "block",
// //          position: "absolute",
// //          top: 0,
// //          right: 14,
// //          width: 10,
// //          height: 10,
// //          bgcolor: "background.paper",
// //          transform: "translateY(-50%) rotate(45deg)",
// //          zIndex: 0,
// //         },
// //        },
// //       }}
// //       transformOrigin={{ horizontal: "right", vertical: "top" }}
// //       anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
// //      >
// //       <MenuItem onClick={handleProfileClick}>
// //        <Avatar /> Profile
// //       </MenuItem>
// //       <MenuItem onClick={handleMenuClose}>
// //        <Avatar /> My account
// //       </MenuItem>
// //       <Divider />
// //       <MenuItem onClick={handleMenuClose}>
// //        <ListItemIcon>
// //         <PersonAdd fontSize="small" />
// //        </ListItemIcon>
// //        Add another account
// //       </MenuItem>
// //       <MenuItem onClick={handleMenuClose}>
// //        <ListItemIcon>
// //         <Settings fontSize="small" />
// //        </ListItemIcon>
// //        Settings
// //       </MenuItem>
// //       <MenuItem
// //        onClick={() => {
// //         handleMenuClose();
// //         handleLogout();
// //        }}
// //       >
// //        <ListItemIcon>
// //         <Logout fontSize="small" />
// //        </ListItemIcon>
// //        Logout
// //       </MenuItem>
// //      </Menu>
// //     </Toolbar>
// //    </AppBar>
// //    <Drawer variant="permanent" open={drawerOpen}>
// //     <DrawerHeader>
// //      <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
// //       {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
// //      </IconButton>
// //     </DrawerHeader>
// //     <Divider sx={{ backgroundColor: "#4A5568" }} />
// //     <List>
// //      {menuItems?.map((item, index) => {
// //       const isActive = location.pathname === item.link;
// //       return (
// //        <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
// //         <ListItemButton
// //          component={Link}
// //          to={item.link}
// //          sx={{
// //           minHeight: 48,
// //           justifyContent: drawerOpen ? "initial" : "center",
// //           px: 2.5,
// //           backgroundColor: isActive ? "#2B6CB0" : "inherit", // Active tab background color
// //           color: isActive ? "#ffffff" : "inherit", // Active tab text color
// //           "&:hover": {
// //            backgroundColor: "#4A5568", // Sidebar item hover color
// //           },
// //          }}
// //         >
// //          <ListItemIcon
// //           sx={{
// //            minWidth: 0,
// //            mr: drawerOpen ? 3 : "auto",
// //            justifyContent: "center",
// //            color: "#ffffff", // Set icon color explicitly
// //           }}
// //          >
// //           {item.icon}
// //          </ListItemIcon>
// //          <ListItemText
// //           primary={item.text}
// //           sx={{ opacity: drawerOpen ? 1 : 0 }}
// //          />
// //         </ListItemButton>
// //        </ListItem>
// //       );
// //      })}
// //     </List>
// //     <Divider sx={{ backgroundColor: "#4A5568" }} />
// //    </Drawer>
// //    <Box
// //     component="main"
// //     sx={{ flexGrow: 1, p: 3, backgroundColor: "#EDF2F7", minHeight: "100vh" }}
// //    >
// //     <DrawerHeader />
// //     {children}
// //    </Box>
// //   </Box>
// //  );
// // }

// // import React from "react";
// // import { styled, useTheme } from "@mui/material/styles";
// // import Box from "@mui/material/Box";
// // import MuiDrawer from "@mui/material/Drawer";
// // import MuiAppBar from "@mui/material/AppBar";
// // import Toolbar from "@mui/material/Toolbar";
// // import List from "@mui/material/List";
// // import CssBaseline from "@mui/material/CssBaseline";
// // import Typography from "@mui/material/Typography";
// // import Divider from "@mui/material/Divider";
// // import IconButton from "@mui/material/IconButton";
// // import MenuIcon from "@mui/icons-material/Menu";
// // import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// // import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// // import ListItem from "@mui/material/ListItem";
// // import ListItemButton from "@mui/material/ListItemButton";
// // import ListItemIcon from "@mui/material/ListItemIcon";
// // import ListItemText from "@mui/material/ListItemText";
// // import { Link, useLocation } from "react-router-dom";
// // import InputBase from "@mui/material/InputBase";
// // import {
// //  Search,
// //  Notifications,
// //  Settings,
// //  PersonAdd,
// //  Logout,
// // } from "@mui/icons-material";
// // import Avatar from "@mui/material/Avatar";
// // import { Menu, MenuItem, Tooltip } from "@mui/material";
// // import { useDispatch } from "react-redux";
// // // import { logout } from "../redux/actions/authActions"; // Ensure this path is correct
// // import LogoutButton from "./auth/LogoutButton";
// // import { logoutUser } from "../redux/auth/action";

// // const drawerWidth = 240;

// // // ... (Styles and other code remains the same)

// // const openedMixin = (theme) => ({
// //  width: drawerWidth,
// //  transition: theme.transitions.create("width", {
// //   easing: theme.transitions.easing.sharp,
// //   duration: theme.transitions.duration.enteringScreen,
// //  }),
// //  overflowX: "hidden",
// //  backgroundColor: "#1A202C", // Sidebar background color
// //  color: "#ffffff", // Sidebar text color
// // });

// // const closedMixin = (theme) => ({
// //  transition: theme.transitions.create("width", {
// //   easing: theme.transitions.easing.sharp,
// //   duration: theme.transitions.duration.leavingScreen,
// //  }),
// //  overflowX: "hidden",
// //  width: `calc(${theme.spacing(7)} + 1px)`,
// //  [theme.breakpoints.up("sm")]: {
// //   width: `calc(${theme.spacing(8)} + 1px)`,
// //  },
// //  backgroundColor: "#1A202C", // Sidebar background color
// //  color: "#ffffff", // Sidebar text color
// // });

// // const DrawerHeader = styled("div")(({ theme }) => ({
// //  display: "flex",
// //  alignItems: "center",
// //  justifyContent: "flex-end",
// //  padding: theme.spacing(0, 1),
// //  ...theme.mixins.toolbar,
// // }));

// // const AppBar = styled(MuiAppBar, {
// //  shouldForwardProp: (prop) => prop !== "open",
// // })(({ theme, open }) => ({
// //  zIndex: theme.zIndex.drawer + 1,
// //  backgroundColor: "#2D3748", // Navbar background color
// //  boxShadow: "none",
// //  color: "#ffffff", // Navbar text color
// //  transition: theme.transitions.create(["width", "margin"], {
// //   easing: theme.transitions.easing.sharp,
// //   duration: theme.transitions.duration.leavingScreen,
// //  }),
// //  ...(open && {
// //   marginLeft: drawerWidth,
// //   width: `calc(100% - ${drawerWidth}px)`,
// //   transition: theme.transitions.create(["width", "margin"], {
// //    easing: theme.transitions.easing.sharp,
// //    duration: theme.transitions.duration.enteringScreen,
// //   }),
// //  }),
// // }));

// // const Drawer = styled(MuiDrawer, {
// //  shouldForwardProp: (prop) => prop !== "open",
// // })(({ theme, open }) => ({
// //  width: drawerWidth,
// //  flexShrink: 0,
// //  whiteSpace: "nowrap",
// //  boxSizing: "border-box",
// //  ...(open && {
// //   ...openedMixin(theme),
// //   "& .MuiDrawer-paper": openedMixin(theme),
// //  }),
// //  ...(!open && {
// //   ...closedMixin(theme),
// //   "& .MuiDrawer-paper": closedMixin(theme),
// //  }),
// // }));
// // export default function MiniDrawer({ children, menuItems }) {
// //  const theme = useTheme();
// //  const dispatch = useDispatch();
// //  const [drawerOpen, setDrawerOpen] = React.useState(false);
// //  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
// //  const location = useLocation();

// //  const handleDrawerOpen = () => {
// //   setDrawerOpen(true);
// //  };

// //  const handleDrawerClose = () => {
// //   setDrawerOpen(false);
// //  };

// //  const handleMenuClick = (event) => {
// //   setMenuAnchorEl(event.currentTarget);
// //  };

// //  const handleMenuClose = () => {
// //   setMenuAnchorEl(null);
// //  };

// //  const handleLogout = () => {
// //   dispatch(logoutUser()); // Dispatch the logout action
// //  };

// //  const isMenuOpen = Boolean(menuAnchorEl);

// //  return (
// //   <Box sx={{ display: "flex" }}>
// //    <CssBaseline />
// //    <AppBar position="fixed" open={drawerOpen}>
// //     <Toolbar>
// //      <IconButton
// //       color="inherit"
// //       aria-label="open drawer"
// //       onClick={handleDrawerOpen}
// //       edge="start"
// //       sx={{
// //        marginRight: 5,
// //        ...(drawerOpen && { display: "none" }),
// //       }}
// //      >
// //       <MenuIcon />
// //      </IconButton>
// //      <Typography variant="h6" noWrap component="div">
// //       Taxi Rider
// //      </Typography>
// //      <Box sx={{ flexGrow: 1 }} />
// //      <Box
// //       sx={{
// //        position: "relative",
// //        borderRadius: "25px",
// //        backgroundColor: "#4A5568",
// //        marginRight: "16px",
// //        width: "200px",
// //       }}
// //      >
// //       <InputBase
// //        placeholder="Search…"
// //        sx={{
// //         paddingLeft: "10px",
// //         width: "100%",
// //         color: "white",
// //        }}
// //        inputProps={{ "aria-label": "search" }}
// //       />
// //       <IconButton
// //        type="submit"
// //        sx={{ position: "absolute", right: 0, top: 0, color: "white" }}
// //       >
// //        <Search />
// //       </IconButton>
// //      </Box>
// //      <IconButton color="inherit">
// //       <Notifications />
// //      </IconButton>
// //      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
// //       <Tooltip title="Account settings">
// //        <IconButton
// //         onClick={handleMenuClick}
// //         size="small"
// //         sx={{ ml: 2 }}
// //         aria-controls={isMenuOpen ? "account-menu" : undefined}
// //         aria-haspopup="true"
// //         aria-expanded={isMenuOpen ? "true" : undefined}
// //        >
// //         <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
// //        </IconButton>
// //       </Tooltip>
// //      </Box>
// //      <Menu
// //       anchorEl={menuAnchorEl}
// //       id="account-menu"
// //       open={isMenuOpen}
// //       onClose={handleMenuClose}
// //       onClick={handleMenuClose}
// //       PaperProps={{
// //        elevation: 0,
// //        sx: {
// //         overflow: "visible",
// //         filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
// //         mt: 1.5,
// //         "& .MuiAvatar-root": {
// //          width: 32,
// //          height: 32,
// //          ml: -0.5,
// //          mr: 1,
// //         },
// //         "&::before": {
// //          content: '""',
// //          display: "block",
// //          position: "absolute",
// //          top: 0,
// //          right: 14,
// //          width: 10,
// //          height: 10,
// //          bgcolor: "background.paper",
// //          transform: "translateY(-50%) rotate(45deg)",
// //          zIndex: 0,
// //         },
// //        },
// //       }}
// //       transformOrigin={{ horizontal: "right", vertical: "top" }}
// //       anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
// //      >
// //       <MenuItem onClick={handleMenuClose}>
// //        <Avatar /> Profile
// //       </MenuItem>
// //       <MenuItem onClick={handleMenuClose}>
// //        <Avatar /> My account
// //       </MenuItem>
// //       <Divider />
// //       <MenuItem onClick={handleMenuClose}>
// //        <ListItemIcon>
// //         <PersonAdd fontSize="small" />
// //        </ListItemIcon>
// //        Add another account
// //       </MenuItem>
// //       <MenuItem onClick={handleMenuClose}>
// //        <ListItemIcon>
// //         <Settings fontSize="small" />
// //        </ListItemIcon>
// //        Settings
// //       </MenuItem>
// //       <MenuItem
// //        onClick={() => {
// //         handleMenuClose();
// //         handleLogout();
// //        }}
// //       >
// //        <ListItemIcon>
// //         <Logout fontSize="small" />
// //        </ListItemIcon>
// //        Logout
// //       </MenuItem>
// //      </Menu>
// //     </Toolbar>
// //    </AppBar>
// //    <Drawer variant="permanent" open={drawerOpen}>
// //     <DrawerHeader>
// //      <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
// //       {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
// //      </IconButton>
// //     </DrawerHeader>
// //     <Divider sx={{ backgroundColor: "#4A5568" }} />
// //     <List>
// //      {menuItems?.map((item, index) => {
// //       const isActive = location.pathname === item.link;
// //       return (
// //        <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
// //         <ListItemButton
// //          component={Link}
// //          to={item.link}
// //          sx={{
// //           minHeight: 48,
// //           justifyContent: drawerOpen ? "initial" : "center",
// //           px: 2.5,
// //           backgroundColor: isActive ? "#2B6CB0" : "inherit", // Active tab background color
// //           color: isActive ? "#ffffff" : "inherit", // Active tab text color
// //           "&:hover": {
// //            backgroundColor: "#4A5568", // Sidebar item hover color
// //           },
// //          }}
// //         >
// //          <ListItemIcon
// //           sx={{
// //            minWidth: 0,
// //            mr: drawerOpen ? 3 : "auto",
// //            justifyContent: "center",
// //            color: isActive ? "#ffffff" : "inherit", // Active icon color
// //           }}
// //          >
// //           {item.icon}
// //          </ListItemIcon>
// //          <ListItemText
// //           primary={item.text}
// //           sx={{ opacity: drawerOpen ? 1 : 0 }}
// //          />
// //         </ListItemButton>
// //        </ListItem>
// //       );
// //      })}
// //     </List>
// //     <Divider sx={{ backgroundColor: "#4A5568" }} />
// //     {/* Additional List Items can go here if needed */}
// //    </Drawer>
// //    <Box
// //     component="main"
// //     sx={{ flexGrow: 1, p: 3, backgroundColor: "#EDF2F7", minHeight: "100vh" }}
// //    >
// //     <DrawerHeader />
// //     {children}
// //    </Box>
// //   </Box>
// //  );
// // }
