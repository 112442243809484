// src/redux/actions/notificationActions.js
import axios from "axios";
import * as types from "./actionTypes";
// Fetch Notifications
export const fetchNotifications = () => async (dispatch) => {
 dispatch({ type: types.FETCH_NOTIFICATIONS_REQUEST });
 try {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("No authentication token found");

  const response = await axios.get("https://api.janatarides.com/api/notifications", {
   headers: {
    "x-auth-token": token,
   },
  });
  dispatch({
   type: types.FETCH_NOTIFICATIONS_SUCCESS,
   payload: response.data,
  });
 } catch (error) {
  dispatch({
   type: types.FETCH_NOTIFICATIONS_FAILURE,
   payload: error.message,
  });
  console.error("Error fetching notifications:", error.message);
 }
};

// Mark Notification as Read
export const markNotificationAsRead = (id) => async (dispatch) => {
 try {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("No authentication token found");

  await axios.put(
   `https://api.janatarides.com/api/notifications/${id}/mark-as-read`,
   {},
   {
    headers: {
     "x-auth-token": token,
    },
   }
  );
  dispatch({
   type: types.MARK_NOTIFICATION_AS_READ,
   payload: id,
  });
 } catch (error) {
  console.error("Error marking notification as read:", error.message);
 }
};

// Mark All Notifications as Read
export const markAllNotificationsAsRead = () => async (dispatch) => {
 try {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("No authentication token found");

  await axios.put(
   "https://api.janatarides.com/api/notifications/mark-all-as-read",
   {},
   {
    headers: {
     "x-auth-token": token,
    },
   }
  );
  dispatch({
   type: types.MARK_ALL_NOTIFICATIONS_AS_READ,
  });
 } catch (error) {
  console.error("Error marking all notifications as read:", error.message);
 }
};
// Fetch Notifications
// export const fetchNotifications = () => async (dispatch) => {
//  dispatch({ type: types.FETCH_NOTIFICATIONS_REQUEST });
//  try {
//   const response = await axios.get("https://api.janatarides.com/api/notifications", {
//    headers: {
//     "x-auth-token": localStorage.getItem("token"),
//    },
//   });
//   dispatch({
//    type: types.FETCH_NOTIFICATIONS_SUCCESS,
//    payload: response.data,
//   });
//  } catch (error) {
//   dispatch({
//    type: types.FETCH_NOTIFICATIONS_FAILURE,
//    payload: error.message,
//   });
//  }
// };

// // Mark Notification as Read
// export const markNotificationAsRead = (id) => async (dispatch) => {
//  try {
//   await axios.put(
//    `https://api.janatarides.com/api/notifications/${id}/mark-as-read`,
//    {},
//    {
//     headers: {
//      "x-auth-token": localStorage.getItem("token"),
//     },
//    }
//   );
//   dispatch({
//    type: types.MARK_NOTIFICATION_AS_READ,
//    payload: id,
//   });
//  } catch (error) {
//   console.error("Error marking notification as read", error);
//  }
// };

// // Mark All Notifications as Read
// export const markAllNotificationsAsRead = () => async (dispatch) => {
//  try {
//   await axios.put(
//    "https://api.janatarides.com/api/notifications/mark-all-as-read",
//    {},
//    {
//     headers: {
//      "x-auth-token": localStorage.getItem("token"),
//     },
//    }
//   );
//   dispatch({
//    type: types.MARK_ALL_NOTIFICATIONS_AS_READ,
//   });
//  } catch (error) {
//   console.error("Error marking all notifications as read", error);
//  }
// };
