import React from "react";
import { Box, Typography, useMediaQuery, useTheme, Paper } from "@mui/material";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";

const data = [
 { name: "Astha Rides", value: 400 },
 { name: "Self Rides", value: 300 },
 { name: "Sharing Rides", value: 300 },
 { name: "Intercity Service", value: 200 },
 { name: "Out of station Rides", value: 278 },
 { name: "Hills station Rides", value: 189 },
];

const COLORS = [
 "#0088FE",
 "#00C49F",
 "#FFBB28",
 "#FF8042",
 "#FF6384",
 "#36A2EB",
];

const PieChartComponent = () => {
 const theme = useTheme();
 const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small

 return (
  <Paper elevation={3} sx={{ borderRadius: "8px", width: "100%" }}>
   <Box
    display="flex"
    flexDirection={isMobile ? "column" : "row"} // Stack on mobile, side by side on larger screens
    alignItems={isMobile ? "center" : "flex-start"} // Center on mobile, start on larger screens
    textAlign={isMobile ? "center" : "left"} // Center text on mobile, left on larger screens
   >
    <Box
     flex={1}
     mb={isMobile ? 2 : 0}
     mr={isMobile ? 0 : 2}
     width={isMobile ? "100%" : "50%"}
    >
     <ResponsiveContainer width="100%" height={250}>
      <PieChart>
       <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={60}
        outerRadius={100}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
       >
        {data.map((entry, index) => (
         <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
       </Pie>
       <Tooltip />
      </PieChart>
     </ResponsiveContainer>
    </Box>
    <Box flex={1}>
     <Typography variant="h6" fontWeight="bold" color="#333" mb={2}>
      Services
     </Typography>
     <Box>
      {data.map((entry, index) => (
       <Box
        key={`legend-${index}`}
        display="flex"
        alignItems="center"
        mb={1}
        justifyContent="flex-start" // Always align to start
       >
        <Box
         sx={{
          width: 16,
          height: 16,
          backgroundColor: COLORS[index % COLORS.length],
          borderRadius: "50%",
          mr: 1,
         }}
        />
        <Typography variant="body2" color="textSecondary">
         {entry.name}
        </Typography>
       </Box>
      ))}
     </Box>
    </Box>
   </Box>
  </Paper>
 );
};

export default PieChartComponent;
