// // src/redux/actions/authActions.js
// import axios from "axios";
// import * as types from "./actionTypes";
// import { messaging, getToken } from "../../firebase"; // Import Firebase functions

// // Login User
// export const login = (email, password) => async (dispatch) => {
//  dispatch({ type: types.LOGIN_REQUEST });

//  try {
//   // Get FCM token
//   const fcmToken = await getToken(messaging, {
//    vapidKey: "YOUR_PUBLIC_VAPID_KEY",
//   });

//   const res = await axios.post("https://api.janatarides.com/api/auth/login", {
//    email,
//    password,
//    fcmToken, // Include the FCM token
//   });

//   const token = res.data.token;
//   localStorage.setItem("token", token);

//   // Load user data
//   dispatch(loadUser(token));
//  } catch (error) {
//   dispatch({ type: types.LOGIN_FAILURE, payload: error.message });
//  }
// };

// // Load User
// export const loadUser = (token) => async (dispatch) => {
//  dispatch({ type: types.LOAD_USER_REQUEST });
//  try {
//   const res = await axios.get("https://api.janatarides.com/api/users", {
//    headers: {
//     "x-auth-token": token,
//    },
//   });

//   dispatch({ type: types.LOAD_USER_SUCCESS, payload: res.data });
//  } catch (error) {
//   dispatch({ type: types.LOAD_USER_FAILURE, payload: error.message });
//  }
// };

// // Logout User
// export const logoutUser = () => (dispatch) => {
//  localStorage.removeItem("token");
//  dispatch({ type: types.LOGOUT });
//  window.location.href = "/login";
// };

// src/redux/actions/authActions.js
import axios from "axios";
import * as types from "./actionTypes";

// Login User
export const login = (email, password) => async (dispatch) => {
 dispatch({ type: types.LOGIN_REQUEST });
 try {
  const res = await axios.post("https://api.janatarides.com/api/auth/login", {
   email,
   password,
  });
  const token = res.data.token;
  localStorage.setItem("token", token);

  // Load user data
  dispatch(loadUser(token));
 } catch (error) {
  dispatch({ type: types.LOGIN_FAILURE, payload: error.message });
 }
};

// Load User
export const loadUser = (token) => async (dispatch) => {
 dispatch({ type: types.LOAD_USER_REQUEST });
 try {
  const res = await axios.get("https://api.janatarides.com/api/users", {
   headers: {
    "x-auth-token": token,
   },
  });

  dispatch({ type: types.LOAD_USER_SUCCESS, payload: res.data });
 } catch (error) {
  dispatch({ type: types.LOAD_USER_FAILURE, payload: error.message });
 }
};

// Logout User
export const logoutUser = () => (dispatch) => {
 localStorage.removeItem("token");
 dispatch({ type: types.LOGOUT });
 window.location.href = "/login";
};
