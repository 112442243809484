import axios from "axios";

// Action Types
export const FETCH_WEEKLY_SALES_SUCCESS = "FETCH_WEEKLY_SALES_SUCCESS";
export const FETCH_WEEKLY_SALES_FAILURE = "FETCH_WEEKLY_SALES_FAILURE";
export const FETCH_WEEKLY_ORDERS_SUCCESS = "FETCH_WEEKLY_ORDERS_SUCCESS";
export const FETCH_WEEKLY_ORDERS_FAILURE = "FETCH_WEEKLY_ORDERS_FAILURE";
export const FETCH_VISITORS_ONLINE_SUCCESS = "FETCH_VISITORS_ONLINE_SUCCESS";
export const FETCH_VISITORS_ONLINE_FAILURE = "FETCH_VISITORS_ONLINE_FAILURE";

// Fetch weekly sales report
export const fetchWeeklySales = () => async (dispatch) => {
 try {
  const response = await axios.get(
   "https://api.janatarides.com/api/sales-report/weekly-sales",
   {
    headers: { "x-auth-token": localStorage.getItem("token") },
   }
  );
  dispatch({ type: FETCH_WEEKLY_SALES_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: FETCH_WEEKLY_SALES_FAILURE, payload: error.message });
 }
};

// Fetch weekly orders
export const fetchWeeklyOrders = () => async (dispatch) => {
 try {
  const response = await axios.get(
   "https://api.janatarides.com/api/sales-report/weekly-orders",
   {
    headers: { "x-auth-token": localStorage.getItem("token") },
   }
  );
  dispatch({ type: FETCH_WEEKLY_ORDERS_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: FETCH_WEEKLY_ORDERS_FAILURE, payload: error.message });
 }
};

// Fetch visitors online
export const fetchVisitorsOnline = () => async (dispatch) => {
 try {
  const response = await axios.get(
   "https://api.janatarides.com/api/sales-report/visitors-online",
   {
    headers: { "x-auth-token": localStorage.getItem("token") },
   }
  );
  dispatch({ type: FETCH_VISITORS_ONLINE_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: FETCH_VISITORS_ONLINE_FAILURE, payload: error.message });
 }
};

// import axios from 'axios';

// // Action Types
// export const FETCH_SALES_SUCCESS = 'FETCH_SALES_SUCCESS';
// export const FETCH_SALES_FAILURE = 'FETCH_SALES_FAILURE';
// export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
// export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
// export const FETCH_VISITORS_SUCCESS = 'FETCH_VISITORS_SUCCESS';
// export const FETCH_VISITORS_FAILURE = 'FETCH_VISITORS_FAILURE';

// // Fetch weekly sales report
// export const fetchWeeklySales = () => async (dispatch) => {
//   try {
//     const response = await axios.get('/api/sales-report/weekly-sales', {
//       headers: { 'x-auth-token': localStorage.getItem('token') },
//     });
//     dispatch({ type: FETCH_SALES_SUCCESS, payload: response.data });
//   } catch (error) {
//     dispatch({ type: FETCH_SALES_FAILURE, payload: error.message });
//   }
// };

// // Fetch weekly orders report
// export const fetchWeeklyOrders = () => async (dispatch) => {
//   try {
//     const response = await axios.get('/api/sales-report/weekly-orders', {
//       headers: { 'x-auth-token': localStorage.getItem('token') },
//     });
//     dispatch({ type: FETCH_ORDERS_SUCCESS, payload: response.data });
//   } catch (error) {
//     dispatch({ type: FETCH_ORDERS_FAILURE, payload: error.message });
//   }
// };

// // Fetch visitors online report
// export const fetchVisitorsOnline = () => async (dispatch) => {
//   try {
//     const response = await axios.get('/api/sales-report/visitors-online', {
//       headers: { 'x-auth-token': localStorage.getItem('token') },
//     });
//     dispatch({ type: FETCH_VISITORS_SUCCESS, payload: response.data });
//   } catch (error) {
//     dispatch({ type: FETCH_VISITORS_FAILURE, payload: error.message });
//   }
// };
