import * as types from "./actionTypes";

const initialState = {
 drivers: [],
 loading: false,
 error: null,
};

export const driverReducer = (state = initialState, action) => {
 const { type, payload } = action;

 switch (type) {
  case types.ADD_DRIVER_REQUEST:
  case types.FETCH_DRIVERS_REQUEST:
  case types.DELETE_DRIVER_REQUEST:
  case types.UPDATE_DRIVER_REQUEST:
   return {
    ...state,
    loading: true,
    error: null,
   };

  case types.ADD_DRIVER_SUCCESS:
   return {
    ...state,
    loading: false,
    drivers: [...state.drivers, payload],
   };

  case types.FETCH_DRIVERS_SUCCESS:
   return {
    ...state,
    loading: false,
    drivers: payload,
   };

  case types.DELETE_DRIVER_SUCCESS:
   return {
    ...state,
    loading: false,
    drivers: state.drivers.filter((driver) => driver._id !== payload),
   };

  case types.UPDATE_DRIVER_SUCCESS:
   return {
    ...state,
    loading: false,
    drivers: state.drivers.map((driver) =>
     driver._id === payload._id ? payload : driver
    ),
   };

  case types.ADD_DRIVER_FAILURE:
  case types.FETCH_DRIVERS_FAILURE:
  case types.DELETE_DRIVER_FAILURE:
  case types.UPDATE_DRIVER_FAILURE:
   return {
    ...state,
    loading: false,
    error: payload,
   };

  default:
   return state;
 }
};
