import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Jan', earnings: 4000 },
  { name: 'Feb', earnings: 3000 },
  { name: 'Mar', earnings: 2000 },
  { name: 'Apr', earnings: 2780 },
  { name: 'May', earnings: 1890 },
  { name: 'Jun', earnings: 2390 },
  { name: 'Jul', earnings: 3490 },
  { name: 'Aug', earnings: 2000 },
  { name: 'Sep', earnings: 2780 },
  { name: 'Oct', earnings: 1890 },
  { name: 'Nov', earnings: 2390 },
  { name: 'Dec', earnings: 3490 },
];

// Define an array of colors to use for each month
const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8DD1E1', '#A4DE6C', '#D0ED57', '#FFC658'];

const BarChartComponent = () => {
  const [chartHeight, setChartHeight] = useState(400);

  // Update chart height based on window size
  const updateChartHeight = () => {
    const height = window.innerHeight * 0.34; // Adjust the 0.4 factor as needed
    setChartHeight(height);
  };

  useEffect(() => {
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar
          dataKey="earnings"
          fill={(entry, index) => colors[index % colors.length]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;



// // src/components/BarChartComponent.js
// import React from 'react';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// const data = [
//   { name: 'Jan', earnings: 4000 },
//   { name: 'Feb', earnings: 3000 },
//   { name: 'Mar', earnings: 2000 },
//   { name: 'Apr', earnings: 2780 },
//   { name: 'May', earnings: 1890 },
//   { name: 'Jun', earnings: 2390 },
//   { name: 'Jul', earnings: 3490 },
//   { name: 'Aug', earnings: 2000 },
//   { name: 'Sep', earnings: 2780 },
//   { name: 'Oct', earnings: 1890 },
//   { name: 'Nov', earnings: 2390 },
//   { name: 'Dec', earnings: 3490 },
// ];

// const BarChartComponent = () => {
//   return (
//     <ResponsiveContainer width="100%" height={400}>
//       <BarChart data={data}>
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="name" />
//         <YAxis />
//         <Tooltip />
//         <Bar dataKey="earnings" fill="red" />
//       </BarChart>
//     </ResponsiveContainer>
//   );
// };

// export default BarChartComponent;
