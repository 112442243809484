import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminPanel from "./components/AdminDashboard/AdminPanel";
import DistributorPanel from "./components/distributer/DistributorPanel"; // Make sure the import path is correct
import { loadUser } from "./redux/auth/action";
import PrivateRoute from "./components/auth/PrivateRoute";
import Login from "./components/auth/login";

function App() {
 const dispatch = useDispatch();
 const { user, isAuthenticated } = useSelector((state) => state.auth);

 useEffect(() => {
  const token = localStorage.getItem("token");
  if (token) {
   dispatch(loadUser(token));
  }
 }, [dispatch]);

 return (
  <Routes>
   <Route path="/login" element={<Login />} />

   <Route element={<PrivateRoute role="superadmin" />}>
    <Route path="/admin/*" element={<AdminPanel />} />
   </Route>

   <Route element={<PrivateRoute role="distributer" />}>
    <Route path="/distributer/*" element={<DistributorPanel />} />
   </Route>

   <Route
    path="/"
    element={
     isAuthenticated ? (
      user.role === "superadmin" ? (
       <Navigate to="/admin" />
      ) : (
       <Navigate to="/distributer" />
      )
     ) : (
      <Navigate to="/login" />
     )
    }
   />
  </Routes>
 );
}

export default App;
