import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVisitorsOnline,
  fetchWeeklyOrders,
  fetchWeeklySales,
} from "../../redux/sales/action";
import { Box, Button, TextField, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx"; // Ensure you import XLSX for Excel file creation

function AdminReports() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [filter, setFilter] = useState("");

  // Selectors for the report data
  const { weeklySales, weeklyOrders, visitorsOnline } = useSelector(
    (state) => state.sales
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reportType = queryParams.get("type");

    switch (reportType) {
      case "weekly-sales":
        setColumns([
          { field: "id", headerName: "ID", width: 100 },
          { field: "car", headerName: "Car Name", width: 200 },
          { field: "earnings", headerName: "Earnings", width: 200 },
          { field: "driverName", headerName: "Driver Name", width: 150 },
          {
            field: "registrationNumber",
            headerName: "Registration Number",
            width: 180,
          },
        ]);
        dispatch(fetchWeeklySales());
        break;

      case "weekly-orders":
        setColumns([
          { field: "id", headerName: "ID", width: 100 },
          { field: "driverName", headerName: "Driver Name", width: 150 },
          {
            field: "rides_completed",
            headerName: "Rides Completed",
            width: 200,
          },
          { field: "rides_canceled", headerName: "Rides Canceled", width: 200 },
          { field: "carName", headerName: "Car Name", width: 150 },
        ]);
        dispatch(fetchWeeklyOrders());
        break;

      case "visitors-online":
        setColumns([
          { field: "id", headerName: "ID", width: 100 },
          { field: "driverName", headerName: "Driver Name", width: 150 },
          { field: "carName", headerName: "Car Name", width: 150 },
          { field: "status", headerName: "Status", width: 150 },
          {
            field: "registrationNumber",
            headerName: "Registration Number",
            width: 180,
          },
        ]);
        dispatch(fetchVisitorsOnline());
        break;

      default:
        break;
    }
  }, [location.search, dispatch]);

  // Fake data for initial display
  const fakeData = {
    weeklySales: [
      {
        id: 1,
        car: "Car A",
        earnings: "$500",
        driverName: "Driver A",
        registrationNumber: "REG123",
      },
      {
        id: 2,
        car: "Car B",
        earnings: "$300",
        driverName: "Driver B",
        registrationNumber: "REG456",
      },
      {
        id: 3,
        car: "Car C",
        earnings: "$200",
        driverName: "Driver C",
        registrationNumber: "REG789",
      },
    ],
    weeklyOrders: [
      {
        id: 1,
        driverName: "Driver A",
        rides_completed: 10,
        rides_canceled: 2,
        carName: "Car A",
      },
      {
        id: 2,
        driverName: "Driver B",
        rides_completed: 7,
        rides_canceled: 1,
        carName: "Car B",
      },
      {
        id: 3,
        driverName: "Driver C",
        rides_completed: 5,
        rides_canceled: 3,
        carName: "Car C",
      },
    ],
    visitorsOnline: [
      {
        id: 1,
        driverName: "Driver A",
        carName: "Car A",
        status: "Online",
        registrationNumber: "REG123",
      },
      {
        id: 2,
        driverName: "Driver B",
        carName: "Car B",
        status: "Offline",
        registrationNumber: "REG456",
      },
      {
        id: 3,
        driverName: "Driver C",
        carName: "Car C",
        status: "Online",
        registrationNumber: "REG789",
      },
    ],
  };

  // Data preparation
  const data = (() => {
    switch (location.search.split("=")[1]) {
      case "weekly-sales":
        return weeklySales &&
          Array.isArray(weeklySales.cars) &&
          weeklySales.cars.length > 0
          ? weeklySales.cars
          : fakeData.weeklySales;

      case "weekly-orders":
        return weeklyOrders &&
          Array.isArray(weeklyOrders.orders) &&
          weeklyOrders.orders.length > 0
          ? weeklyOrders.orders
          : fakeData.weeklyOrders;

      case "visitors-online":
        return visitorsOnline &&
          Array.isArray(visitorsOnline.cars) &&
          visitorsOnline.cars.length > 0
          ? visitorsOnline.cars
          : fakeData.visitorsOnline;

      default:
        return [];
    }
  })();

  // Filter data based on input
  const filteredData = data.filter((item) => {
    const filterText = filter.toLowerCase();
    return (
      (item.car && item.car.toLowerCase().includes(filterText)) ||
      (item.driverName && item.driverName.toLowerCase().includes(filterText)) ||
      (item.registrationNumber &&
        item.registrationNumber.toLowerCase().includes(filterText)) ||
      (item.status && item.status.toLowerCase().includes(filterText)) ||
      (item.carName && item.carName.toLowerCase().includes(filterText)) ||
      (item.rides_completed &&
        item.rides_completed.toString().includes(filterText)) ||
      (item.rides_canceled &&
        item.rides_canceled.toString().includes(filterText))
    );
  });

  const handleDownloadDriver = () => {
    const reportType = new URLSearchParams(location.search).get("type");
    let exportData;

    switch (reportType) {
      case "weekly-sales":
        exportData = filteredData.map((item) => ({
          ID: item.id,
          "Car Name": item.car,
          Earnings: item.earnings,
          "Driver Name": item.driverName,
          "Registration Number": item.registrationNumber,
        }));
        break;

      case "weekly-orders":
        exportData = filteredData.map((item) => ({
          ID: item.id,
          "Driver Name": item.driverName,
          "Rides Completed": item.rides_completed,
          "Rides Canceled": item.rides_canceled,
          "Car Name": item.carName,
        }));
        break;

      case "visitors-online":
        exportData = filteredData.map((item) => ({
          ID: item.id,
          "Driver Name": item.driverName,
          "Car Name": item.carName,
          Status: item.status,
          "Registration Number": item.registrationNumber,
        }));
        break;

      default:
        exportData = [];
        break;
    }

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(dataBlob, `Report_${reportType}.xlsx`);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: 2,
          "@media (min-width:600px)": {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            mb: 2,
            "@media (min-width:600px)": {
              mb: 0,
            },
          }}
        >
          Admin Reports
        </Typography>
        <Box
          sx={{
            width: "38%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            "@media (min-width:600px)": {
              flexDirection: "row",
              alignItems: "center",
            },
          }}
        >
          <TextField
            label="Filter By Car Name, Driver Name, and Registration Number"
            variant="outlined"
            fullWidth
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{
              fontSize: "0.4rem",

              "@media (min-width:600px)": {
                flex: 1,
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              "@media (min-width:600px)": {
                mt: 0,
                ml: 2,
              },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadDriver}
              sx={{
                fontWeight: "bold",
                fontSize: "1rem",
                px: 3,
                py: 1.5,
              }}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Box>
      <div
        style={{
          height: 500,
          width: "100%",
          maxWidth: "100%",
          overflowX: "auto",
          background: "white",
          marginTop: 20,
          borderRadius: "12px",
        }}
      >
        <DataGrid rows={filteredData} columns={columns} pageSize={5} />
      </div>
    </div>
  );
}

export default AdminReports;

// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { DataGrid } from "@mui/x-data-grid";
// import { useDispatch, useSelector } from "react-redux";
// import {
//  fetchVisitorsOnline,
//  fetchWeeklyOrders,
//  fetchWeeklySales,
// } from "../../redux/sales/action";

// function AdminReports() {
//  const location = useLocation();
//  const dispatch = useDispatch();
//  const [columns, setColumns] = useState([]);

//  const { weeklySales, weeklyOrders, visitorsOnline } = useSelector(
//   (state) => state.sales
//  );

//  useEffect(() => {
//   const queryParams = new URLSearchParams(location.search);
//   const reportType = queryParams.get("type");

//   switch (reportType) {
//    case "weekly-sales":
//     setColumns([
//      { field: "id", headerName: "ID", width: 100 },
//      { field: "car", headerName: "Car", width: 200 },
//      { field: "earnings", headerName: "Earnings", width: 200 },
//     ]);
//     dispatch(fetchWeeklySales());
//     break;

//    case "weekly-orders":
//     setColumns([
//      { field: "id", headerName: "ID", width: 100 },
//      { field: "rides_completed", headerName: "Rides Completed", width: 200 },
//      { field: "rides_canceled", headerName: "Rides Canceled", width: 200 },
//     ]);
//     dispatch(fetchWeeklyOrders());
//     break;

//    case "visitors-online":
//     setColumns([
//      { field: "id", headerName: "ID", width: 100 },
//      { field: "car", headerName: "Car", width: 200 },
//      { field: "status", headerName: "Status", width: 150 },
//     ]);
//     dispatch(fetchVisitorsOnline());
//     break;

//    default:
//     break;
//   }
//  }, [location.search, dispatch]);

//  // Ensure data is always an array
//  const data = (() => {
//   switch (location.search.split("=")[1]) {
//    case "weekly-sales":
//     return weeklySales && Array.isArray(weeklySales.cars)
//      ? weeklySales.cars
//      : [];
//    case "weekly-orders":
//     return weeklyOrders && Array.isArray(weeklyOrders.orders)
//      ? weeklyOrders.orders
//      : [];
//    case "visitors-online":
//     return visitorsOnline && Array.isArray(visitorsOnline.cars)
//      ? visitorsOnline.cars
//      : [];
//    default:
//     return [];
//   }
//  })();

//  return (
//   <div>
//    <h2>Admin Reports</h2>
//    <div
//     style={{
//      height: 500,
//      width: "100%",
//      maxWidth: "100%",
//      overflowX: "auto",
//      background: "white",
//      marginTop: 20,
//      borderRadius: "12px",
//     }}
//    >
//     <DataGrid rows={data} columns={columns} pageSize={5} />
//    </div>
//   </div>
//  );
// }

// export default AdminReports;

// // // components/admin/AdminReports.js
// // import {
// //   FormControl,
// //   IconButton,
// //   Input,
// //   InputLabel,
// //   MenuItem,
// //   Select,
// //   Stack,
// //   Typography,
// // } from "@mui/material";
// // import React, { useEffect, useState } from "react";
// // import EditIcon from "@mui/icons-material/Edit";
// // import VisibilityIcon from "@mui/icons-material/Visibility";
// // import { DataGrid } from "@mui/x-data-grid";
// // import axios from "axios";
// // function AdminReports() {
// //   const [distributors, setDistributors] = useState([]);

// //   const fetchDistributors = async () => {
// //     try {
// //       const res = await axios.get(
// //         "https://api.janatarides.com/api/admin/distributors",
// //         {
// //           headers: {
// //             "x-auth-token": localStorage.getItem("token"),
// //           },
// //         }
// //       );
// //       console.log("distr", res.data);
// //       setDistributors(res.data);
// //     } catch (err) {
// //       console.error("Error fetching distributors:", err);
// //     }
// //   };

// //   // useEffect(() => fetchDistributors(), []);

// //   const columns = [
// //     { field: "id", headerName: "ID", width: 100 },
// //     { field: "name", headerName: "Name", width: 150 },
// //     { field: "email", headerName: "Email", width: 200 },
// //     { field: "role", headerName: "Role", width: 200 },
// //     {
// //       field: "action",
// //       headerName: "Action",
// //       width: 150,
// //       renderCell: (params) => (
// //         <>
// //           <IconButton color="primary">
// //             <VisibilityIcon />
// //           </IconButton>
// //           <IconButton color="primary">
// //             <EditIcon />
// //           </IconButton>
// //         </>
// //       ),
// //     },
// //   ];

// //   const rows = distributors.map((driver) => ({
// //     id: driver._id,
// //     ...driver,
// //   }));

// //   return (
// //     <div>
// //       <h2>Reports</h2>

// //       <Stack
// //         sx={{
// //           background: "white",
// //         }}
// //         borderRadius={"12px"}
// //         p={3}
// //         spacing={3}
// //         width={"100%"}
// //         direction={{ xs: "column", md: "row" }}
// //         justifyContent="space-between"
// //         alignItems="center"
// //         boxShadow="0 4px 12px rgba(0, 0, 0, 0.2)"
// //       >
// //         <Stack
// //           spacing={4}
// //           direction={{ xs: "column", md: "row" }}
// //           width={"20%"}
// //         >
// //           <FormControl fullWidth sx={{ background: "white" }}>
// //             <InputLabel id="demo-simple-select-label" sx={{ color: "#3f51b5" }}>
// //               Age
// //             </InputLabel>
// //             <Select
// //               labelId="demo-simple-select-label"
// //               id="demo-simple-select"
// //               label="Age"
// //             >
// //               <MenuItem value={10}>Ten</MenuItem>
// //               <MenuItem value={20}>Twenty</MenuItem>
// //               <MenuItem value={30}>Thirty</MenuItem>
// //             </Select>
// //           </FormControl>
// //           <FormControl fullWidth sx={{ background: "white" }}>
// //             <InputLabel id="demo-simple-select-label" sx={{ color: "#3f51b5" }}>
// //               Age
// //             </InputLabel>
// //             <Select
// //               labelId="demo-simple-select-label"
// //               id="demo-simple-select"
// //               label="Age"
// //             >
// //               <MenuItem value={10}>Ten</MenuItem>
// //               <MenuItem value={20}>Twenty</MenuItem>
// //               <MenuItem value={30}>Thirty</MenuItem>
// //             </Select>
// //           </FormControl>
// //         </Stack>

// //         <Stack
// //           spacing={4}
// //           direction={{ xs: "column", md: "row" }}
// //           width={"20%"}
// //         >
// //           <Input
// //             placeholder="Search"
// //             sx={{
// //               width: "100%",
// //               border: "2px solid #ddd",
// //               borderRadius: "8px",
// //               padding: "8px 12px",
// //               backgroundColor: "#fff",

// //               "&:hover": {
// //                 borderColor: "#aaa",
// //               },
// //               "&:focus": {
// //                 borderColor: "#3f51b5",

// //                 outline: "none",
// //               },
// //             }}
// //           />
// //         </Stack>
// //       </Stack>

// //       <div
// //         style={{
// //           height: 500,
// //           width: "100%",
// //           maxWidth: "100%",
// //           overflowX: "auto",
// //           background: "white",
// //           marginTop: 20,
// //           borderRadius: "12px",
// //         }}
// //       >
// //         <DataGrid rows={rows} columns={columns} pageSize={5} />
// //       </div>
// //     </div>
// //   );
// // }

// // export default AdminReports;
