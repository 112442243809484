import React from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import BarChartComponent from "../Charts/BarChartComponent";
import {
  Dashboard,
  ShowChart,
  Widgets,
  TableChart,
  SwapHoriz,
  Receipt,
  Tune,
  Edit,
  CalendarToday,
  Warning,
} from "@mui/icons-material";
 
const cardData = [
  { title: "Total Earnings: $123,456", icon: <Dashboard />, color: "blue" },
  { title: "Available Balance: $10,000", icon: <ShowChart />, color: "green" },
  { title: " Settled Amount: $110,000", icon: <Widgets />, color: "orange" },
  { title: "Unsettled Amount: $3,456", icon: <TableChart />, color: "red" },
];
const DistributerWithdrawal = () => {
  return (
    <Container
      maxWidth={false}
      sx={{ width: "100%", padding: 3, backgroundColor: "#f5f5f5" }}
    >
      <Card sx={{ mb: 3, boxShadow: 3 }}>
        <Typography variant="h6" sx={{ padding:"25px" }}>
          Withdrawal
        </Typography>
        <CardContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {cardData.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Card
                    sx={{
                      backgroundColor: card.color,
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h4" component="div">
                        {card.icon}
                      </Typography>
                      <Typography variant="h6">{card.title}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <BarChartComponent />
        </CardContent>
      </Card>
    </Container>
  );
};

export default DistributerWithdrawal;
