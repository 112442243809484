import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import ManageDistributors from "./ManageDistributors";
import AdminReports from "./AdminReports";
import AdminSettings from "./AdminSettings";
import AdminNotifications from "./AdminNotifications";
import AdminHelp from "./AdminHelp";
import MiniDrawer from "../MiniDrawer";
import AdminDriverList from "./AdminDriverList";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpIcon from "@mui/icons-material/Help";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminBannerUpload from "./AdminBannerUpload";
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
const adminMenuItems = [
 { text: "Dashboard", link: "/admin", icon: <DashboardIcon /> },
 {
  text: "Manage Distributors",
  link: "/admin/manage-distributors",
  icon: <PeopleIcon />,
 },
//  { text: "Reports", link: "/admin/reports", icon: <BarChartIcon /> },
 {
  text: "Notifications",
  link: "/admin/notifications",
  icon: <NotificationsIcon />,
 },
 { text: "Settings", link: "/admin/banner", icon: <SettingsIcon /> },
];

function AdminPanel() {
 return (
  <MiniDrawer menuItems={adminMenuItems}>
   <Routes>
    <Route path="/" element={<AdminDashboard />} />
    <Route path="/manage-distributors" element={<ManageDistributors />} />
    {/* <Route path="/manage-distributors" element={<AdminDriverList />} /> */}

    <Route path="/reports" element={<AdminReports />} />
    <Route path="/settings" element={<AdminSettings />} />
    <Route path="/notifications" element={<AdminNotifications />} />
    <Route path="/banner" element={<AdminBannerUpload />} />
    {/* <Route path="/help" element={<AdminHelp />} />
    <Route path="/profile" element={<h1>Page Not Found</h1>} /> */}
   </Routes>
  </MiniDrawer>
 );
}

export default AdminPanel;
