import axios from "axios";
import * as types from "./actionTypes";

// Add Driver
// export const addDriver = (driverData) => async (dispatch) => {
//  dispatch({ type: types.ADD_DRIVER_REQUEST });
//  try {
//   const response = await axios.post(
//    "https://api.janatarides.com/api/drivers/add",
//    driverData,
//    {
//     headers: {
//      "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
//     },
//    }
//   );
//   dispatch({ type: types.ADD_DRIVER_SUCCESS, payload: response.data.driver });
//  } catch (error) {
//   dispatch({ type: types.ADD_DRIVER_FAILURE, payload: error.message });
//  }
// };

// // Fetch Drivers
// export const fetchDrivers = () => async (dispatch) => {
//  dispatch({ type: types.FETCH_DRIVERS_REQUEST });
//  try {
//   const response = await axios.get("https://api.janatarides.com/api/drivers", {
//    headers: {
//     "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
//    },
//   });
//   dispatch({ type: types.FETCH_DRIVERS_SUCCESS, payload: response.data });
//  } catch (error) {
//   dispatch({ type: types.FETCH_DRIVERS_FAILURE, payload: error.message });
//  }
// };

// // Delete Driver
// export const deleteDriver = (id) => async (dispatch) => {
//  dispatch({ type: types.DELETE_DRIVER_REQUEST });
//  try {
//   await axios.delete(`https://api.janatarides.com/api/drivers/${id}`, {
//    headers: {
//     "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
//    },
//   });
//   dispatch({ type: types.DELETE_DRIVER_SUCCESS, payload: id });
//  } catch (error) {
//   dispatch({ type: types.DELETE_DRIVER_FAILURE, payload: error.message });
//  }
// };

// // Update Driver
// export const updateDriver = (id, driverData) => async (dispatch) => {
//  dispatch({ type: types.UPDATE_DRIVER_REQUEST });
//  try {
//   const response = await axios.put(
//    `https://api.janatarides.com/api/drivers/${id}`,
//    driverData,
//    {
//     headers: {
//      "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
//     },
//    }
//   );
//   dispatch({
//    type: types.UPDATE_DRIVER_SUCCESS,
//    payload: response.data.driver,
//   });
//  } catch (error) {
//   dispatch({ type: types.UPDATE_DRIVER_FAILURE, payload: error.message });
//  }
// };

// // Approve Driver
// export const approveDriver = (id) => async (dispatch) => {
//  try {
//   await axios.put(
//    `https://api.janatarides.com/api/admin/approve-driver/${id}`,
//    {},
//    {
//     headers: {
//      "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
//     },
//    }
//   );

//   // Optionally, you could dispatch another action or simply refetch the drivers list
//   dispatch(fetchDrivers());
//  } catch (err) {
//   console.error("Error approving driver:", err);
//  }
// };
export const addDriver = (driverData) => async (dispatch) => {
 dispatch({ type: types.ADD_DRIVER_REQUEST });
 try {
  const response = await axios.post(
   "https://api.janatarides.com/api/drivers/add",
   driverData,
   {
    headers: {
     "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
    },
   }
  );
  dispatch({ type: types.ADD_DRIVER_SUCCESS, payload: response.data.driver });
 } catch (error) {
  dispatch({ type: types.ADD_DRIVER_FAILURE, payload: error.message });
 }
};

// Fetch Drivers
export const fetchDrivers = () => async (dispatch) => {
 dispatch({ type: types.FETCH_DRIVERS_REQUEST });
 try {
  const response = await axios.get("https://api.janatarides.com/api/drivers", {
   headers: {
    "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
   },
  });
  dispatch({ type: types.FETCH_DRIVERS_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.FETCH_DRIVERS_FAILURE, payload: error.message });
 }
};

// Delete Driver
export const deleteDriver = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_DRIVER_REQUEST });
 try {
  await axios.delete(`https://api.janatarides.com/api/drivers/${id}`, {
   headers: {
    "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
   },
  });
  dispatch({ type: types.DELETE_DRIVER_SUCCESS, payload: id });
 } catch (error) {
  dispatch({ type: types.DELETE_DRIVER_FAILURE, payload: error.message });
 }
};

// Update Driver
export const updateDriver = (id, driverData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_DRIVER_REQUEST });
 try {
  const response = await axios.put(
   `https://api.janatarides.com/api/drivers/${id}`,
   driverData,
   {
    headers: {
     "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
    },
   }
  );
  dispatch({
   type: types.UPDATE_DRIVER_SUCCESS,
   payload: response.data.driver,
  });
 } catch (error) {
  dispatch({ type: types.UPDATE_DRIVER_FAILURE, payload: error.message });
 }
};

// Approve Driver
export const approveDriver = (id) => async (dispatch) => {
 try {
  await axios.put(
   `https://api.janatarides.com/api/admin/approve-driver/${id}`,
   {},
   {
    headers: {
     "x-auth-token": localStorage.getItem("token"), // Adding the auth token header
    },
   }
  );

  // Optionally, you could dispatch another action or simply refetch the drivers list
  dispatch(fetchDrivers());
 } catch (err) {
  console.error("Error approving driver:", err);
 }
};
